import { useNavigate, useParams } from "react-router-dom"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { ApiService } from "../../Components/Services/apiservices"
import React, { useState, useEffect, useRef, useContext } from "react"
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton"
import PropertyBox from "../../Components/Element/property_box"
import constant from "../../Components/Services/constant"
import { validEmail, validNumber } from "../../Components/Element/Regex"
import DataContext from "../../Components/Element/context"
import { BrowserView, MobileView } from "react-device-detect"


const AgentDetail = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [agentDetail, setAgentDetail] = useState({})
    const [agentProperty, setagentProperty] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, sethasMore] = useState(false)
    const [pageno, setpageno] = useState(1)
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [imgUrl, setimgUrl] = useState('')
    const [enquiryUserdetail, setenquiryUserdetail] = useState({
        enquired_user_name: "",
        enquired_user_email: "",
        enquired_user_mobile: "",
        enquired_message: '',
    })
    const didMountRef = useRef(true)

    useEffect(() => {
        if (didMountRef.current) {
            getAgentdata()

        }
        didMountRef.current = false
    }, [])
    const getAgentdata = () => {
        const dataString = {
            user_unique_id: id
        }
        setIsLoading(true)
        let pageNumber = pageno;
        ApiService.postData('agentDetails' + "?page=" + pageNumber, dataString).then((res) => {
            if (res.status == 'success') {
                setAgentDetail(res.agentData)
                setimgUrl(res.property_img_url)
                if (pageNumber >= res.properties.last_page) {
                    sethasMore(false);
                } else {
                    sethasMore(true);
                    setpageno(pageNumber + 1);
                }
                if (res.properties && res.properties.data.length > 0) {
                    const apires = res.properties.data;
                    const mergeData = [...agentProperty, ...apires];

                    setTimeout(() => {
                        setIsLoading(false)
                    }, 500)
                    setagentProperty(mergeData)

                }
                else {
                    setTimeout(() => {
                        setIsLoading(false)
                    }, 500)

                }

            }
            else if (res.status == 'session_expired' && res.message == 'Session expired') {
                localStorage.removeItem('USER_TOKEN')
                navigate('/')
                setIsLoading(false)
            }

        }).catch((error) => {

        })
    }

    const onInputChange = (e) => {
        setErrorMessage("");
        setSuccessMessage("");
        const { name, value } = e.target;
        setenquiryUserdetail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value !== "") {
            e.target.style.border = "";
        }
    };

    const submitEnquire = (e) => {
        e.preventDefault()
        let counter = 0;
        const myElements = document.getElementsByClassName("enquirerequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === "") {

                myElements[i].style.border = "1px solid red";
                counter++;
            } else {
                myElements[i].style.border = "";
            }
        }

        if (counter == 0) {
            if (!validNumber.test(enquiryUserdetail.enquired_user_mobile)) {
                setErrorMessage("Please enter valid Mobile Number");
                return false;
            }
            if (!validEmail.test(enquiryUserdetail.enquired_user_email)) {
                setErrorMessage("Please Enter Valid Email");
                return false;
            }
            // setisLoading(true)
            const dataString = {
                enquiry_name: enquiryUserdetail.enquired_user_name,
                enquiry_email: enquiryUserdetail.enquired_user_email,
                enquiry_mobile: enquiryUserdetail.enquired_user_mobile,
                enquiry_agent_id: agentDetail?.user_id,
                enquiry_message: enquiryUserdetail.enquired_message

            };
            ApiService.postData("agentEnquiryProcess", dataString).then((res) => {
                if (res.status == "success") {
                    setSuccessMessage(res.message);
                    setTimeout(() => {
                        setenquiryUserdetail({
                            enquired_user_name: "",
                            enquired_user_email: "",
                            enquired_user_mobile: "",
                            enquired_message: "",
                        })
                        setSuccessMessage('');
                    }, 1000)

                    // setisLoading(false)
                } else {
                    setErrorMessage(res.message);
                    // setisLoading(false)
                }
            }).catch(() => {
                // setisLoading(false)
            });
        }
    };
    const contextValues = useContext(DataContext)

    return (<>
        
        <BrowserView>
            <section className="flat-section-v4 agent-details">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="agentbox">
                                        <div className="agentboxBanner">
                                            <img src={agentDetail.user_banner_image ? agentDetail.user_banner_image : contextValues.settingData.agent_banner_image ? contextValues.settingData.agent_banner_image : constant.DEFAULT_IMAGE} alt="image-agent" />
                                            <div className="agentboxProfile">
                                                <div className="agentboxProfileInner">
                                                    <div className="agentboxProfileImg"> <img style={{ borderRadius: '100%' }} src={agentDetail.user_logo ? agentDetail.user_logo : '/images/user.png'} alt="image-agent" /></div>
                                                    <div className="agentboxProfileContent">
                                                        <h2 className="agentName">{agentDetail.user_name}</h2>
                                                        <p className="mb-0">{agentDetail.user_designation}</p>
                                                    </div>
                                                    <div className="agentSocial">
                                                        <ul>
                                                            {agentDetail.user_facebook ? <> <li ><a href={agentDetail.user_facebook} target="_blank"><span className="icon icon-facebook"></span></a></li></> : ''}
                                                            {agentDetail.user_linkedin ? <li ><a href={agentDetail.user_linkedin} target="_blank"><span className="icon icon-linkedin"></span></a></li> : ''}
                                                            {agentDetail.user_twitter ? <li ><a href={agentDetail.user_twitter} target="_blank"><span className="icon icon-twitter"></span></a></li> : ''}

                                                            {agentDetail.user_instagram ? <li ><a href={agentDetail.user_instagram} target="_blank"><i class="ri-instagram-line"></i></a></li> : ''}
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4">

                                    <div className="widget-box single-property-contact bg-surface mb-3">
                                        <div className="h7 title fw-7">Agent Information</div>
                                        <ul className="aprofileinfo">
                                            {isLoading ?
                                                <>
                                                    <li>
                                                        <Skeleton width={'300px'}></Skeleton>
                                                    </li>
                                                    <li>
                                                        <Skeleton width={'300px'}></Skeleton>
                                                    </li>
                                                    <li>
                                                        <Skeleton width={'300px'}></Skeleton>
                                                    </li>
                                                    <li>
                                                        <Skeleton width={'300px'}></Skeleton>
                                                    </li>
                                                </>
                                                : <>

                                                    {agentDetail.user_mobile && <li>
                                                        <a href={`tel:${agentDetail.user_mobile}`}>
                                                            <div className="box-icon w-40">
                                                                <i class="icon icon-phone2"></i>
                                                            </div>
                                                            <div className="content">
                                                                <span>{agentDetail.user_mobile}</span>
                                                            </div>
                                                        </a>
                                                    </li>}
                                                    {agentDetail.user_email && <li>
                                                        <a href={`mailto:${agentDetail.user_email}`}>
                                                            <div className="box-icon w-40">
                                                                <i class="icon icon-mail"></i>
                                                            </div>
                                                            <div className="content">
                                                                <span>{agentDetail.user_email}</span>
                                                            </div>
                                                        </a>
                                                    </li>}
                                                    {agentDetail.user_address && <li>
                                                        <a href="javascript:void(0)">
                                                            <div className="box-icon w-40">
                                                                <i class="icon icon-location"></i>
                                                            </div>
                                                            <div className="content">
                                                                <span>{agentDetail.user_address}</span>
                                                            </div>
                                                        </a>
                                                    </li>}
                                                </>}
                                        </ul>
                                    </div>
                                    {agentDetail.user_bio && <div className="widget-box single-property-contact bg-surface mb-3">
                                        <div className="h7 title fw-7">Agent Bio</div>
                                        <div>{agentDetail.user_bio}</div>

                                    </div>}

                                    <div className="widget-box single-property-contact bg-surface">
                                        <div className="h7 title fw-7">Contact Agent</div>
                                        <form className="contact-form">
                                            {errorMessage && (
                                                <div className="alert alert-danger" role="alert">
                                                    {errorMessage}
                                                </div>
                                            )}
                                            {successMessage && (
                                                <div className="alert alert-success" role="alert">
                                                    {successMessage}
                                                </div>
                                            )}
                                            <div className="ip-group">
                                                <label for="fullname">Full Name:</label>
                                                <input type="text" placeholder="Full Name" className="form-control enquirerequired" value={enquiryUserdetail?.enquired_user_name} name="enquired_user_name" onChange={(e) => { onInputChange(e) }} />
                                            </div>
                                            <div className="ip-group">
                                                <label for="phone">Phone Number:</label>
                                                <input type="number" placeholder="Phone Number" min={0} className="form-control enquirerequired" value={enquiryUserdetail?.enquired_user_mobile} name="enquired_user_mobile" onChange={(e) => { onInputChange(e) }} />
                                            </div>
                                            <div className="ip-group">
                                                <label for="email">Email Address:</label>
                                                <input type="text" placeholder="Email Id" className="form-control enquirerequired" name="enquired_user_email" value={enquiryUserdetail?.enquired_user_email} onChange={(e) => { onInputChange(e) }} />
                                            </div>
                                            <div className="ip-group">
                                                <label for="message">Your Message:</label>
                                                <textarea
                                                    id="comment-message"
                                                    name="message"
                                                    rows="4"
                                                    className="enquirerequired"
                                                    placeholder="Message"
                                                    aria-required="true"
                                                    value={enquiryUserdetail?.enquired_message || ''}
                                                    onChange={(e) => {
                                                        setenquiryUserdetail((prevState) => ({
                                                            ...prevState,
                                                            enquired_message: e.target.value,
                                                        }));
                                                    }}
                                                ></textarea>
                                            </div>
                                            <button className="tf-btn primary w-100" onClick={(e) => { submitEnquire(e) }}>Send Message</button>
                                        </form>
                                    </div>
                                </div>

                                <div className="col-lg-8">

                                    <InfiniteScroll
                                        dataLength={agentProperty.length}
                                        next={getAgentdata}
                                        hasMore={hasMore}
                                        // endMessage={<p>No more data to load.</p>}
                                        style={{ overflow: "hidden !important" }}
                                    >

                                        {isLoading ? <>
                                            <div className="row">
                                                {[...Array(6)].map((__, index) => {
                                                    return (<>

                                                        <div className="col-xl-6 col-md-6" key={index}>
                                                            <div className="homeya-box md" >
                                                                <div className="archive-top">
                                                                    <div className="images-group">
                                                                        <div className="images-style" >
                                                                            <Skeleton height={'250px'}></Skeleton>
                                                                        </div>
                                                                        <div className="top">
                                                                            <ul className="d-flex gap-8 flex-column">
                                                                                <li className=""><Skeleton width={'50px'}></Skeleton></li>
                                                                            </ul>
                                                                            <ul className="d-flex gap-4">
                                                                                <li className="box-icon w-32" >
                                                                                    <a href="javascript:void(0)" data-bs-toggle="modal"> <Skeleton></Skeleton></a>
                                                                                </li>
                                                                                <li className="box-icon w-32">
                                                                                    <Skeleton></Skeleton>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <div className="text-1 text-capitalize">
                                                                            <Skeleton width={'200px'}></Skeleton>
                                                                        </div>
                                                                        <p><Skeleton width={'250px'}></Skeleton></p>
                                                                        <div className="desc"><p><Skeleton width={'300px'}></Skeleton></p> </div>
                                                                        <ul className="meta-list">
                                                                            <li className="item">
                                                                                <span><Skeleton width={'300px'}></Skeleton></span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="archive-bottom d-flex justify-content-between align-items-center">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="h7 fw-7"><Skeleton width={'80px'}></Skeleton> </div>
                                                                        <div className="h7 fw-7 ms-2"><Skeleton width={'80px'}></Skeleton> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>)
                                                })}
                                            </div>
                                        </> : <>
                                            {agentProperty && agentProperty.length > 0 ? <>
                                                <div className="row">
                                                    {agentProperty.map((item, index) => {
                                                        return (<>
                                                            <div className="col-xl-6 col-md-6" key={index}>
                                                                <PropertyBox propertydetail={item} imgUrl={imgUrl}></PropertyBox>
                                                            </div>
                                                        </>)
                                                    })}
                                                </div>
                                            </> : <>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="d-flex justify-content-center">
                                                            <img src='/images/emptystate.gif' style={{ width: '500px' }}></img>
                                                        </div>
                                                        <h6 className="text-center">No Property Found for this agent</h6>
                                                    </div>
                                                </div>
                                            </>}
                                        </>}
                                    </InfiniteScroll>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </BrowserView>
        <MobileView>
            <div className="agentbox">
                <div className="agentboxBanner">
                    <img src={agentDetail.user_banner_image ? agentDetail.user_banner_image : contextValues.settingData.agent_banner_image ? contextValues.settingData.agent_banner_image : constant.DEFAULT_IMAGE} alt="image-agent" />
                    <div className="agentboxProfile">
                        <div className="agentboxProfileInner">
                            <div className="agentboxProfileImg"> <img style={{ borderRadius: '100%' }} src={agentDetail.user_logo ? agentDetail.user_logo : '/images/user.png'} alt="image-agent" /></div>
                            <div className="agentboxProfileContent">
                                <h2 className="agentName">{agentDetail.user_name}</h2>
                                <p className="mb-0">{agentDetail.user_designation}</p>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
            <div className="widget-box single-property-contact bg-surface mb-3 agprof">
                <div className="h7 title fw-7">Agent Information</div>
                <ul className="aprofileinfo">
                    {isLoading ?
                        <>
                            <li>
                                <Skeleton width={'300px'}></Skeleton>
                            </li>
                            <li>
                                <Skeleton width={'300px'}></Skeleton>
                            </li>
                            <li>
                                <Skeleton width={'300px'}></Skeleton>
                            </li>
                            <li>
                                <Skeleton width={'300px'}></Skeleton>
                            </li>
                        </>
                        : <>
                            {agentDetail.user_mobile && <li>
                                <a href={`tel:${agentDetail.user_mobile}`}>
                                    <div className="box-icon w-40">
                                        <i class="icon icon-phone2"></i>
                                    </div>
                                    <div className="content">
                                        <span>{agentDetail.user_mobile}</span>
                                    </div>
                                </a>
                            </li>}
                            {agentDetail.user_email && <li>
                                <a href={`mailto:${agentDetail.user_email}`}>
                                    <div className="box-icon w-40">
                                        <i class="icon icon-mail"></i>
                                    </div>
                                    <div className="content">
                                        <span>{agentDetail.user_email}</span>
                                    </div>
                                </a>
                            </li>}
                            {agentDetail.user_address && <li>
                                <a href="javascript:void(0)">
                                    <div className="box-icon w-40">
                                        <i class="icon icon-location"></i>
                                    </div>
                                    <div className="content">
                                        <span>{agentDetail.user_address}</span>
                                    </div>
                                </a>
                            </li>}
                        </>}
                </ul>
                <div className="agentSocial mt-20">
                    <ul>
                        {agentDetail.user_facebook ? <> <li ><a href={agentDetail.user_facebook} target="_blank"><span className="icon icon-facebook"></span></a></li></> : ''}
                        {agentDetail.user_linkedin ? <li ><a href={agentDetail.user_linkedin} target="_blank"><span className="icon icon-linkedin"></span></a></li> : ''}
                        {agentDetail.user_twitter ? <li ><a href={agentDetail.user_twitter} target="_blank"><span className="icon icon-twitter"></span></a></li> : ''}

                        {agentDetail.user_instagram ? <li ><a href={agentDetail.user_instagram} target="_blank"><i class="ri-instagram-line"></i></a></li> : ''}
                    </ul>
                </div>
            </div>
            {agentDetail.user_bio && <div className="widget-box single-property-contact bg-surface mb-3 agprof">
                <div className="h7 title fw-7">Agent Bio</div>
                <div>{agentDetail.user_bio}</div>

            </div>}
            <div className="widget-box single-property-contact bg-surface agprof mb-3">
                <div className="h7 title fw-7">Contact Agent</div>
                <form className="contact-form">
                    {errorMessage && (
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>
                    )}
                    {successMessage && (
                        <div className="alert alert-success" role="alert">
                            {successMessage}
                        </div>
                    )}
                    <div className="ip-group">
                        <label for="fullname">Full Name:</label>
                        <input type="text" placeholder="Full Name" className="form-control enquirerequired" value={enquiryUserdetail?.enquired_user_name} name="enquired_user_name" onChange={(e) => { onInputChange(e) }} />
                    </div>
                    <div className="ip-group">
                        <label for="phone">Phone Number:</label>
                        <input type="number" placeholder="Phone Number" min={0} className="form-control enquirerequired" value={enquiryUserdetail?.enquired_user_mobile} name="enquired_user_mobile" onChange={(e) => { onInputChange(e) }} />
                    </div>
                    <div className="ip-group">
                        <label for="email">Email Address:</label>
                        <input type="text" placeholder="Email Id" className="form-control enquirerequired" name="enquired_user_email" value={enquiryUserdetail?.enquired_user_email} onChange={(e) => { onInputChange(e) }} />
                    </div>
                    <div className="ip-group">
                        <label for="message">Your Message:</label>
                        <textarea
                            id="comment-message"
                            name="message"
                            rows="4"
                            className="enquirerequired"
                            placeholder="Message"
                            aria-required="true"
                            value={enquiryUserdetail?.enquired_message || ''}
                            onChange={(e) => {
                                setenquiryUserdetail((prevState) => ({
                                    ...prevState,
                                    enquired_message: e.target.value,
                                }));
                            }}
                        ></textarea>
                    </div>
                    <button className="tf-btn primary w-100" onClick={(e) => { submitEnquire(e) }}>Send Message</button>
                </form>
            </div>
            <div className="p-3">
                <InfiniteScroll
                    dataLength={agentProperty.length}
                    next={getAgentdata}
                    hasMore={hasMore}
                    // endMessage={<p>No more data to load.</p>}
                    style={{ overflow: "hidden !important" }}
                >

                    {isLoading ? <>
                        <div className="row">
                            {[...Array(6)].map((__, index) => {
                                return (<>

                                    <div className="col-xl-6 col-md-6" key={index}>
                                        <div className="homeya-box md" >
                                            <div className="archive-top">
                                                <div className="images-group">
                                                    <div className="images-style" >
                                                        <Skeleton height={'250px'}></Skeleton>
                                                    </div>
                                                    <div className="top">
                                                        <ul className="d-flex gap-8 flex-column">
                                                            <li className=""><Skeleton width={'50px'}></Skeleton></li>
                                                        </ul>
                                                        <ul className="d-flex gap-4">
                                                            <li className="box-icon w-32" >
                                                                <a href="javascript:void(0)" data-bs-toggle="modal"> <Skeleton></Skeleton></a>
                                                            </li>
                                                            <li className="box-icon w-32">
                                                                <Skeleton></Skeleton>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <div className="text-1 text-capitalize">
                                                        <Skeleton width={'200px'}></Skeleton>
                                                    </div>
                                                    <p><Skeleton width={'250px'}></Skeleton></p>
                                                    <div className="desc"><p><Skeleton width={'300px'}></Skeleton></p> </div>
                                                    <ul className="meta-list">
                                                        <li className="item">
                                                            <span><Skeleton width={'300px'}></Skeleton></span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="archive-bottom d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <div className="h7 fw-7"><Skeleton width={'80px'}></Skeleton> </div>
                                                    <div className="h7 fw-7 ms-2"><Skeleton width={'80px'}></Skeleton> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)
                            })}
                        </div>
                    </> : <>
                        {agentProperty && agentProperty.length > 0 ? <>
                            <div className="row">
                                {agentProperty.map((item, index) => {
                                    return (<>
                                        <div className="col-xl-6 col-md-6" key={index}>
                                            <PropertyBox propertydetail={item} imgUrl={imgUrl}></PropertyBox>
                                        </div>
                                    </>)
                                })}
                            </div>
                        </> : <>
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-center">
                                        <img src='/images/emptystate.gif' style={{ width: '500px' }}></img>
                                    </div>
                                    <h6 className="text-center">No Property Found for this agent</h6>
                                </div>
                            </div>
                        </>}
                    </>}
                </InfiniteScroll>
            </div>
        </MobileView>


       

    </>)
}


export default AgentDetail