import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import React, { useEffect, useRef, useState, useCallback, useContext } from 'react'
import { ApiService } from "../../Components/Services/apiservices"
import usePlacesAutocomplete, { getDetails, getGeocode, getLatLng } from "use-places-autocomplete";
import { useNavigate, useParams } from "react-router-dom";
import constant from "../../Components/Services/constant";


const PostProperty = () => {
    const didMountRef = useRef(true)
    const { id } = useParams()
    const [errorMessage, setErrorMessage] = useState("")
    const [multipleerrorMessage, setmultipleerrorMessage] = useState([])
    const [isDragging, setIsDragging] = useState(false); // State for drag-and-drop
    const [error, setError] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);
    const [uploadsingleImg, setuploadsingleImg] = useState('');
    const [floorPlanImg, setfloorPlanImg] = useState('');
    const [uploadpdf, setuploadpdf] = useState('');
    const [uploadvideo, setuploaduploadvideo] = useState('');
    const [step, setstep] = useState(1);
    const [successMessage, setSuccessMessage] = useState("")
    const [categoryData, setcategoryData] = useState([])
    const [amenities, setamenitiesData] = useState([])
    const [cityDropdown, setcityDropdown] = useState([])
    const [areaDropdown, setareaDropdown] = useState([])
    const [stateDropdown, setstateDropdown] = useState([])
    const [postPropertyData, setpostPropertyData] = useState({
        property_for: '',
        property_type: '',
        property_category: '',
        property_city: '',
        // property_id: '',
        property_area: '',
        property_state_id: '',
        property_country: '',
        property_name: '',
        property_land_mark: '',
        property_post_code: '',
        property_address: '',
        property_city_name: '',
        property_no_bedroom: 0,
        property_no_bathroom: 0,
        property_no_balconies: 0,
        property_carpet_area: '',
        property_carpet_unit: '',
        property_built_up_area: '',
        property_built_up_unit: '',
        pb_file: '',
        pb_title: '',
        property_super_built_up_area: '',
        property_super_built_up_unit: '',
        property_furnish: '',
        property_total_floors: '',
        property_on_floor: '',
        property_availabilty_status: '',
        property_expected_price: '',
        property_per_sqftprice: '',
        property_inclusive_price: 0,
        property_tax_charge_exclude: 0,
        property_price_negotiable: 0,
        property_desc: "",
        property_image: '',
        property_amenities: [],
        property_video: '',
        property_age: '',
        property_video_link: '',
        pg_image: [],
        property_no_of_washroom: '',
        property_age: '',
        property_personal_washroom: '',
        property_ownership: '',
        property_unq_id: id ? id : '',
        pfp_title: [],
        pfp_image: [],
        property_features_id: [],
        property_features_name: [],
        property_lat: '',
        property_long: ""

    })
    const [floorPlans, setFloorPlans] = useState([{ title: '', file: null }]);
    const [floorPlansupdate, setFloorPlansupdate] = useState([]);
    const navigate = useNavigate()
    const [selectedAmenities, setSelectedAmenities] = useState({});
    const [floorplantitle, setfloorplantitle] = useState([])
    const [floorplanid, setfloorplanid] = useState([])
    const [floorplanimage, setfloorplanimage] = useState([])
    const [showaddressdropdown, setshowaddressdropdown] = useState(false);
    const [multipleFiles, setshowmultipleImg] = useState([])
    useEffect(() => {
        if (didMountRef.current) {
            // getPropertycategory()
            getAmenitieslist()
            getstatedropdown()
            getcitydropdown()
            if (id) {
                getPropertydetail();
            }

        }
        didMountRef.current = false
    }, [])
    const handleRemoveImage = (index, id) => {
        if (id && id !== 'undefined') {
            const dataString = { pg_id: id }
            ApiService.postData('/removegalleryimage', dataString).then((res) => {
                if (res.status == 'success') {
                    getPropertydetail()
                }
                else {
                    setErrorMessage(res.message)
                }
            })
        }
    };
    const handleRemovefloorFloorplan = (index) => {
        const id = floorplanid[index]
        if (id && id !== 'undefined') {
            const dataString = { pfp_id: id }
            ApiService.postData('/removePropertyFloorPlan', dataString).then((res) => {
                if (res.status == 'success') {
                    getPropertydetail()
                }
                else {
                    setErrorMessage(res.message)
                }
            })
        }
    };
    const getPropertydetail = () => {
        const dataString = {
            property_unq_id: id
        }
        ApiService.postData('propertydetails', dataString).then((res) => {
            if (res.status == 'success') {
                setpostPropertyData({
                    property_unq_id: id,
                    property_for: res.data.property_for,
                    property_type: res.data.property_type,
                    property_category: res.data.property_category,
                    property_city: res.data.property_city,
                    property_name: res.data.property_name,
                    property_state_id: res.data.property_state_id,
                    property_area: res.data.property_area,
                    property_video_link: res.data.property_video_link,
                    pb_title: res.data.pb_title,
                    property_post_code: res.data.property_post_code,
                    property_address: res.data.property_address,
                    property_land_mark: res.data.property_land_mark,
                    property_carpet_area: res.data.property_carpet_area,
                    property_carpet_unit: res.data.property_carpet_unit,
                    property_built_up_area: res.data.property_built_up_area,
                    property_built_up_unit: res.data.property_built_up_unit,
                    property_super_built_up_area: res.data.property_super_built_up_area,
                    property_super_built_up_unit: res.data.property_super_built_up_unit,
                    property_furnish: res.data.property_furnish ? res.data.property_furnish : '1',
                    property_total_floors: res.data.property_total_floors,
                    property_on_floor: res.data.property_on_floor,
                    property_expected_price: res.data.property_expected_price,
                    property_per_sqftprice: res.data.property_per_sqftprice,
                    property_desc: res.data.property_desc,
                    property_inclusive_price: res.data.property_inclusive_price ? res.data.property_inclusive_price : 0,
                    property_tax_charge_exclude: res.data.property_tax_charge_exclude ? res.data.property_tax_charge_exclude : 0,
                    property_price_negotiable: res.data.property_price_negotiable ? res.data.property_price_negotiable : 0,
                    property_availabilty_status: res.data.property_availabilty_status ? res.data.property_availabilty_status : '1',
                    property_no_bedroom: res.data.property_type == 1 ? res.data.property_no_bedroom : null,
                    property_no_bathroom: res.data.property_type == 1 ? res.data.property_no_bathroom : null,
                    property_no_balconies: res.data.property_type == 1 ? res.data.property_no_balconies : null,
                    property_ownership: res.data.property_type == 2 ? res.data.property_ownership ? res.data.property_ownership : '1' : null,
                    property_no_of_washroom: res.data.property_type == 2 ? res.data.property_no_of_washroom : null,
                    property_age: res.data.property_type == 2 ? res.data.property_age : null,
                    property_personal_washroom: res.data.property_type == 2 ? res.data.property_personal_washroom : null,
                    property_lat: res.data.property_lat,
                    property_long: res.data.property_long
                })

                setshowmultipleImg(res.data.gallery)
                setuploadsingleImg(res.data.property_image)
                setuploadpdf(res.data.pb_file)
                setuploaduploadvideo(res.data.property_video)
                getPropertycategory(res.data.property_for)
                setfloorPlanImg(res.floorplan_img_url)
                getareadropdown(res.data.property_city)
                setValue(res.data.property_address)
                const updatedFloorPlans = res.data.floorplan.map(item => ({
                    title: item.pfp_title,
                    file: item.pfp_image,
                }));
                setFloorPlansupdate(updatedFloorPlans)
                if (updatedFloorPlans.length > 0) {
                    setFloorPlans([]);
                }


                setshowaddressdropdown(false)

                const featureObject = res.data.property_features_id.split(',').reduce((acc, feature) => {
                    acc[feature] = true; // Set each feature as true
                    return acc;
                }, {});
                setSelectedAmenities(featureObject)
                const updatedFloorPansTitle = res.data.floorplan.map(item => (item?.pfp_title));
                const updatedFloorPansImage = res.data.floorplan.map(item => (item?.pfp_image));
                const updatedFloorPansId = res.data.floorplan.map(item => (item?.pfp_id));
                setfloorplanimage(updatedFloorPansImage)
                setfloorplantitle(updatedFloorPansTitle)
                setfloorplanid(updatedFloorPansId)
            }
            else if (res.status == 'session_expired' && res.message == 'Session expired') {
                localStorage.removeItem('USER_TOKEN')
                // setisLoading(false)
                navigate('/')
            }
        })
    }

    const onpropertytypeChange = (e) => {
        setErrorMessage('')
        setSuccessMessage('')
        setpostPropertyData((prevState) => ({
            ...prevState,
            'property_category': '',
        }));
        setpostPropertyData((prevState) => ({
            ...prevState,
            'property_type': e.target.value,
        }));
        getPropertycategory(e.target.value)
    };
    const getPropertycategory = (propertytype) => {
        const dataString = {
            property_type: propertytype
        }
        ApiService.postData('propertywisetype ', dataString).then((res) => {
            if (res.status == 'success') {
                setcategoryData(res.propertyData)

            }

        })
    }
    const getAmenitieslist = () => {
        ApiService.fetchData('specifications ').then((res) => {
            if (res.status == 'success') {
                setamenitiesData(res.amenitiesData)
            }

        }).catch((error) => { })
    }
    const onInputChange = (e) => {
        setErrorMessage('')
        setSuccessMessage('')
        const { name, value } = e.target;
        setpostPropertyData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (name == 'property_state_id') {
            getcitydropdown(e.target.value)
        }
        if (name == 'property_city') {
            getareadropdown(e.target.value)
        }
    };
    const getstatedropdown = () => {
        ApiService.fetchData('getallStates').then((res) => {
            if (res.status == 'success') {
                setstateDropdown(res.data)
            }
        })
    }
    const getcitydropdown = (stateId) => {
        const dataString = {
            state_id: stateId
        }
        ApiService.postData('getCityByState', dataString).then((res) => {
            if (res) {
                setcityDropdown(res)
            }
        })
    }
    const getareadropdown = (cityId) => {

        const dataString = {
            pin_city_id: cityId
        }
        ApiService.postData('getAreaByCity', dataString).then((res) => {
            if (res) {
                setareaDropdown(res)
            }
        })
    }
    const noOfbedrooms = [
        { name: '1', value: 1 },
        { name: '2', value: 2 },
        { name: '3', value: 3 },
        { name: '4', value: 4 },
        { name: '5', value: 5 },
        { name: '6', value: 6 },
        { name: '7', value: 7 },
        { name: '8', value: 8 },
        { name: '9', value: 9 },
        { name: '10', value: 10 },
    ];
    const propertyareaunit = [
        { name: 'sq.ft.', value: 'sq.ft.' },
        { name: 'sq.yards.', value: 'sq.yards.' },
        { name: 'sq.m.', value: 'sq.m.' },
        { name: 'acres', value: 'acres' },
        { name: 'marla', value: 'marla' },
        { name: 'cents', value: 'cents' },
        { name: 'bigha', value: 'bigha' },
        { name: 'kottah', value: 'kottah' },
        { name: 'kanal', value: 'kanal' },
        { name: 'grounds', value: 'grounds' },
        { name: 'ares', value: 'ares' },
        { name: 'biswa', value: 'biswa' },
        { name: 'guntha', value: 'guntha' },
        { name: 'aankadam', value: 'aankadam' },
        { name: 'hectares', value: 'hectares' },
        { name: 'rood', value: 'rood' },
        { name: 'chataks', value: 'chataks' },
        { name: 'perch', value: 'perch' },
    ];
    const washwoormSelectOption = [
        { name: 'None', value: 0 },
        { name: 'Shared', value: 7 },
        { name: '1', value: 1 },
        { name: '2', value: 2 },
        { name: '3', value: 3 },
        { name: '4', value: 4 },
        { name: '5', value: 5 },
        { name: '5+', value: 6 },
    ]
    const PropertyAgeSelectOption = [
        { name: 'New Construction', value: 1 },
        { name: 'Less Than 5 Years', value: 2 },
        { name: '5-10 years', value: 3 },
        { name: '10-15 years', value: 4 },
        { name: '15-20 years', value: 5 },
        { name: 'above 20 years', value: 6 },
    ]
    const {
        suggestions: { status, data },
        setValue,
        value,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: 'IN' },
        },
        debounce: 10,
    });

    const handleInput = (e) => {
        setError('');
        setValue(e.target.value);
        setshowaddressdropdown(true)
    };

    const handleSelect = ({ place_id, description }) => async () => {

        setError('');
        setValue(description, false);
        //document.querySelector("ul.list").innerHTML = "";
        clearSuggestions();

        try {

            getGeocode({ address: description }).then((results) => {
                const { lat, lng } = getLatLng(results[0]);
                setpostPropertyData((prevState) => ({
                    ...prevState,
                    ['property_lat']: lat,
                    ['property_long']: lng,
                }));
            });


            const placeDetails = await getDetails({ placeId: place_id });
            const addressComponents = placeDetails.address_components;
            const formatedAddress = placeDetails.formatted_address
            const cityComponent = addressComponents.find(component =>
                component.types.includes("locality") ||
                component.types.includes("administrative_area_level_2")
            );
            const stateComponent = addressComponents.find(component =>
                component.types.includes("administrative_area_level_1")
            );
            const countryComponent = addressComponents.find(component =>
                component.types.includes("country")
            );
            const postalComponent = addressComponents.find(component =>
                component.types.includes("postal_code")
            );
            const areaComponent = addressComponents.find(component =>
                component.types.includes("sublocality_level_1")
            );

            const city = cityComponent ? cityComponent.long_name : '';
            const state = stateComponent ? stateComponent.long_name : '';
            const country = countryComponent ? countryComponent.long_name : '';
            const postalcode = postalComponent ? postalComponent.long_name : '';
            const area = areaComponent ? areaComponent.long_name : '';

            if (city || state || country || formatedAddress || postalcode || area) {
                const cityExistData = {
                    cities_name: city,
                    state_name: state,
                    country_name: country,
                    complete_address: description,


                };
                const filteredState = stateDropdown.find(state => state.state_name === cityExistData.state_name);
                const filteredCity = cityDropdown.find(city => city.cities_name === cityExistData.cities_name);
                const filteredArea = areaDropdown.find(area => area.PostOfficeName === area);
                setpostPropertyData((prevState) => ({
                    ...prevState,
                    ['property_state_id']: filteredState?.state_id,
                    ['property_city']: filteredCity?.cities_id,
                    ['property_address']: cityExistData?.complete_address,
                    ['property_post_code']: postalcode,
                    ['property_area']: filteredArea?.pin_id,
                }));

                if (filteredState?.state_id) {
                    getcitydropdown(filteredState?.state_id)
                }
                if (filteredCity?.cities_id) {
                    getareadropdown(filteredCity?.cities_id)
                }
            } else {

                setError('City not found in the selected place.');
            }
        } catch (error) {

            console.error('Error fetching place details:', error);
        }


    };
    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                description,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
                <li key={place_id} onClick={handleSelect(suggestion)} className="d-flex mt-0 border-bottom p-2" ><i className="ri-map-pin-line me-2 "></i><div>
                    <h6 style={{fontSize:'18px'}}>{main_text}</h6> <p className='mb-0 tx-12 tx-gray'>{secondary_text}</p></div></li>
            );
        });

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const handleFileChange = async (file, type) => {
        if (file) {
            // Check if the file size is greater than 2 MB (2 * 1024 * 1024 bytes)
            const maxSizeInMB = 2;
            const fileSizeInMB = file.size / (1024 * 1024);
          
    
            if (fileSizeInMB > maxSizeInMB) {
                setErrorMessage('File size should not exceed 2 MB');
                window.scrollTo({ top: 0, behavior: 'smooth' });
                return;
               
            }
    
            // Check for image, PDF, or video types
            if (
                (type === 'image' && file.type.startsWith('image/')) ||
                (type === 'pdf' && file.type === 'application/pdf') ||
                (type === 'video' && file.type.startsWith('video/'))
            ) {
                try {
                    const base64 = await convertToBase64(file);
                    
                    if (type === 'image') {
                        setpostPropertyData((prevState) => ({
                            ...prevState,
                            ['property_image']: base64,
                        }));
                        setuploadsingleImg('');
                    } else if (type === 'pdf') {
                        setpostPropertyData((prevState) => ({
                            ...prevState,
                            ['pb_file']: base64,
                        }));
                        setuploadpdf('');
                    } else if (type === 'video') {
                        setpostPropertyData((prevState) => ({
                            ...prevState,
                            ['property_video']: base64,
                        }));
                        setuploaduploadvideo('');
                    }
                } catch (error) {
                    console.error(`Error converting ${type} to Base64:`, error);
                }
            } else {
                setError(
                    `Please upload a valid ${type === 'image' ? 'image' : type === 'pdf' ? 'PDF' : 'video'} file`
                );
            }
        }
    };
 
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true); // Set dragging state
    };
    const handleDragLeave = (e) => {

        setIsDragging(false); // Reset dragging state
    };
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false); 

        const file = e.dataTransfer.files[0];
        handleFileChange(file, 'image'); 
    };
    const handleParentCheckboxChange = useCallback((parentId, parentName, children) => {
        setSelectedAmenities((prevState) => {
            const newState = { ...prevState };
            const isParentSelected = parentId in newState;

            if (isParentSelected) {
                delete newState[parentId];
                if (children && children.length > 0) {
                    children.forEach(child => {
                        delete newState[child.features_id];
                    });
                }
            } else {
                newState[parentId] = true;
                if (children && children.length > 0) {
                    children.forEach(child => {
                        newState[child.features_id] = true;
                    });
                }
            }

            return newState;
        });
    }, [setSelectedAmenities]);

    const handleChildCheckboxChange = useCallback((parentId, parentName, childId, childName, children) => {
        setSelectedAmenities((prevState) => {
            const newState = { ...prevState };

            if (childId in newState) {
                delete newState[childId];
            } else {
                newState[childId] = true;
            }
            const anyChildrenSelected = children.some(
                (child) => child.features_id in newState
            );

            if (anyChildrenSelected) {
                newState[parentId] = true;
            } else {
                delete newState[parentId];
            }

            return newState;
        });
    }, [setSelectedAmenities]);

    const handleFileChangeMultiple = (selectedFiles) => {
        const maxSizeInMB = 2;
        const errorMessages = [];
    
        const fileArray = Array.from(selectedFiles);
        const validFiles = fileArray.filter((file) => {
            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > maxSizeInMB) {
                errorMessages.push(`File ${file.name} is larger than 2 MB and will be skipped.`);
                return false; // Skip this file
            }
            return true; // Include this file
        });
    
        // If there are error messages, set them and scroll to the top
        if (errorMessages.length > 0) {
            setmultipleerrorMessage(errorMessage)
            // setErrorMessage(errorMessages.join(' '));
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    
        const base64Promises = validFiles.map((file) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(file);
            })
        );
    
        Promise.all(base64Promises)
            .then((base64Files) => {
                setpostPropertyData((prevState) => ({
                    ...prevState,
                    pg_image: [
                        ...(prevState?.pg_image || []),
                        ...base64Files,
                    ],
                }));
            })
            .catch((error) => {
                console.error('Error converting files to Base64:', error);
            });
    };

    const handleRemoveFile = (index) => {
        setpostPropertyData((prevState) => ({
            ...prevState,
            pg_image: prevState.pg_image.filter((_, i) => i !== index),
        }));
    };


    const onInputfloorplanChange = (e, index) => {
        const newTitles = [...(postPropertyData.pfp_title || [])]; // Fallback to an empty array if undefined
        newTitles[index] = e.target.value;
        setpostPropertyData((prevState) => ({
            ...prevState,
            pfp_title: newTitles,
        }));
    };

 
    const handleFileFloorplanChange = async (e, index) => {
        const file = e.target.files[0];
        const maxSizeInMB = 2;
    
        if (file) {
            // Check file size before uploading
            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > maxSizeInMB) {
                setErrorMessage('Image size should not exceed 2 MB.');
                window.scrollTo({ top: 0, behavior: 'smooth' });
                return;
               
            }
    
            const formData = new FormData();
            formData.append('Filedata', file);
    
            try {
                ApiService.postData('uploadfloorplanimage', formData).then((res) => {
                    if (res.status === 'success') {
                        const imageUrl = res?.url;
    
                        setpostPropertyData((prevState) => {
                            const newImages = [...(prevState.pfp_image || [])];
                            newImages[index] = imageUrl;
    
                            return {
                                ...prevState,
                                pfp_image: newImages,
                            };
                        });
                    } else {
                        console.error('Image upload failed');
                    }
                });
            } catch (error) {
                console.error('Error during file upload:', error);
            }
        }
    };
    const onInputfloorplanUpdateChange = (e, index) => {
        const newTitles = [...floorplantitle];
        newTitles[index] = e.target.value;
        setfloorplantitle(newTitles)
    };
  
    const handleFileFloorplanUpdateChange = async (e, index) => {
        const file = e.target.files[0];
        const maxSizeInMB = 2;
    
        if (file) {
            // Check file size before uploading
            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > maxSizeInMB) {
                setErrorMessage('Image size should not exceed 2 MB.');
                window.scrollTo({ top: 0, behavior: 'smooth' });
                return;
            }
    
            const formData = new FormData();
            formData.append('Filedata', file);
    
            try {
                ApiService.postData('uploadfloorplanimage', formData).then((res) => {
                    if (res.status === 'success') {
                        const imageUrl = res?.url;
                        
                        setfloorplanimage((prevState) => {
                            const newImages = [...(prevState || [])];
                            newImages[index] = imageUrl;
    
                            return newImages;
                        });
                    } else {
                        console.error('Image upload failed');
                    }
                });
            } catch (error) {
                console.error('Error during file upload:', error);
            }
        }
    };
    const addFieldset = () => {
        const newTitles = Array.isArray(postPropertyData.pfp_title) ? [...postPropertyData.pfp_title, ''] : [''];
        const newFiles = Array.isArray(postPropertyData.pfp_image) ? [...postPropertyData.pfp_image, null] : [null];
        setpostPropertyData((prevState) => ({
            ...prevState,
            pfp_title: newTitles,
            pfp_image: newFiles,
        }));

        setFloorPlans((prevFloorPlans) => [...prevFloorPlans, { title: '', file: null }]); // Update local state
    };
    const removeFieldset = (index) => {
        const newTitles = postPropertyData.pfp_title.filter((_, i) => i !== index);
        const newFiles = postPropertyData.pfp_image.filter((_, i) => i !== index);
        setpostPropertyData((prevState) => ({
            ...prevState,
            pfp_image: newFiles,
            pfp_title: newTitles,
        }));
        setFloorPlans(floorPlans.filter((_, i) => i !== index)); // Update local state
    };
    const validateStep3 = () => {
        if (!postPropertyData.property_name) {
            return 'Please enter the property name ';
        }
        if (!postPropertyData.property_for) {
            return 'Please enter the property for ';
        }
        if (!postPropertyData.property_type) {
            return 'Please enter the property type ';
        }
        if (!postPropertyData.property_category) {
            return 'Please enter the property category ';
        }
        if (!postPropertyData.property_address) {
            return 'Please enter the address ';
        }
        if (!postPropertyData.property_state_id) {
            return 'Please select the state ';
        }
        if (!postPropertyData.property_city) {
            return 'Please select the city ';
        }
        if (!postPropertyData.property_area) {
            return 'Please select the area/locality ';
        }
        if (!postPropertyData.property_post_code) {
            return 'Please enter the pinCode ';
        }
        if (!postPropertyData.property_land_mark) {
            return 'Please enter the landmark ';
        }

        // if (postPropertyData.property_type == 1 && !postPropertyData.property_no_bedroom) {
        //     return 'Please select number of bedrooms';
        // }
        // if (postPropertyData.property_type == 1 && !postPropertyData.property_no_bathroom) {
        //     return 'Please select number of bathrooms';
        // }
        // if (postPropertyData.property_type == 1 && !postPropertyData.property_no_balconies) {
        //     return 'Please enter number of balconies';
        // }
        // if (!postPropertyData.property_carpet_area) {
        //     return 'Please enter carpet area';
        // }
        // if (!postPropertyData.property_carpet_unit) {
        //     return 'Please select carpet area unit';
        // }
        // if (!postPropertyData.property_built_up_area) {
        //     return 'Please enter built up area';
        // }
        // if (!postPropertyData.property_built_up_unit) {
        //     return 'Please select built up area unit';
        // }
        // if (!postPropertyData.property_super_built_up_area) {
        //     return 'Please enter super built up area';
        // }
        // if (!postPropertyData.property_super_built_up_unit) {
        //     return 'Please select super built up area unit';
        // }
        // if (!postPropertyData.property_furnish) {
        //     return 'Please select furnish criteria';
        // }
        // if (!postPropertyData.property_total_floors) {
        //     return 'Please enter total number of floors';
        // }
        // if (!postPropertyData.property_on_floor) {
        //     return 'Please select the floor number';
        // }
        // if (!postPropertyData.property_availabilty_status) {
        //     return 'Please select the availability status';
        // }
        // if (postPropertyData.property_type == 2 && !postPropertyData.property_ownership) {
        //     return 'Please select the ownership criteria';
        // }

        // if (postPropertyData.property_type == 2 && !postPropertyData.property_no_of_washroom) {
        //     return 'Please select the number of washorooms';
        // }
        // if (postPropertyData.property_type == 2 && !postPropertyData.property_personal_washroom) {
        //     return 'Please select the personal washroom field';
        // }
        // if (postPropertyData.property_type == 2 && !postPropertyData.property_age) {
        //     return 'Please select the age of property';
        // }
        if (!postPropertyData.property_expected_price) {
            return 'Please enter the property expected price';
        }
        // if (!postPropertyData.property_per_sqftprice) {
        //     return 'Please enter property per sq.ft price';
        // }
        if (!postPropertyData.property_desc) {
            return 'Please enter property decsription';
        }
        if (postPropertyData.pb_title) {
            if (!postPropertyData.pb_file) {
                return 'Please upload brochure file';
            }

        }
        if (postPropertyData.pfp_title && Array.isArray(postPropertyData.pfp_title)) {
            if (!Array.isArray(postPropertyData.pfp_image)) {
                return 'Please upload images corresponding to the titles';
            }
            for (let i = 0; i < postPropertyData.pfp_title.length; i++) {
                if (!postPropertyData.pfp_image[i]) {
                    return `Please upload an image (${postPropertyData.pfp_title[i]})`;
                }
            }
        }

        return null;
    }

    const onSubitPropertydata = (e) => {

        e.preventDefault()
        setErrorMessage('')
        let errorMessage = null;
        errorMessage = validateStep3();
        if (errorMessage) {
            setErrorMessage(errorMessage);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }
        const keys = Object.keys(selectedAmenities);
        const mergedFloorTitledata = [
            ...(floorplantitle || []),
            ...(postPropertyData?.pfp_title || [])
        ];

        const mergedFloorImagedata = [
            ...(floorplanimage || []),
            ...(postPropertyData?.pfp_image || []),

        ];
        const updatedPostPropertyData = {
            ...postPropertyData,
            property_features_id: keys,
            pfp_title: mergedFloorTitledata,
            pfp_image: mergedFloorImagedata,

        };
        setButtonLoader(true)

        ApiService.postData('propertyprocess', updatedPostPropertyData).then((res) => {
            if (res.status == 'success') {
                setSuccessMessage(res.message)
                setButtonLoader(false)
                setstep(2)
                setTimeout(() => {
                    setSuccessMessage('')
                    
                    // window.location.href = '/dashboard'
                }, 2000)
            }
            else if (res.status == 'session_expired' && res.message == 'Session expired') {
                localStorage.removeItem('USER_TOKEN')
                setButtonLoader(false)
                navigate('/')
            }
            else {
                setErrorMessage(res.message)
                setButtonLoader(false)
            }
        }).catch(() => {
            setButtonLoader(false)
            setErrorMessage('')
        })
    }



    return (<>
        <div id="wrapper">
            <div id="page" className="clearfix">
                <div className="layout-wrap">
                    <Header></Header>

                    <div className="main-content">
                        <div className="main-content-inner">
                            
                            {step == 1 && <>
                                {errorMessage && (
                                    <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div>
                                )}
                                  {multipleerrorMessage &&multipleerrorMessage.length>0 && multipleerrorMessage.map((item)=>{
                                    return(
                                        <div className="alert alert-danger" role="alert">
                                           <p>{item}</p> 
                                        </div>
                                    )
                                  }) }
                                {successMessage && (
                                    <div className="alert alert-success" role="alert">
                                        {successMessage}
                                    </div>
                                )}
                                <div className="widget-box-2">
                                    <h6 className="title">Upload Media</h6>
                                    <div className="box-uploadfile text-center" onDragOver={(e) => { handleDragOver(e) }}
                                        onDragLeave={(e) => { handleDragLeave(e) }}
                                        onDrop={(e) => { handleDrop(e) }}>
                                        <label className="uploadfile">
                                            <span className="icon icon-img-2"></span>
                                            <div className="btn-upload">
                                                <a href="javascript:void(0)" className="tf-btn primary">Choose Image</a>
                                                <input type="file" className="ip-file" accept="image/*" onChange={(e) => handleFileChange(e.target.files[0], 'image')} />
                                            </div>
                                            <p className="file-name fw-5">Or drop image here to upload</p>
                                            <p className="file-name  text-danger" >file size must be less than 2 mb</p>
                                        </label>
                                        {postPropertyData.property_image &&
                                            <div style={{ width: '100px', height: "100px" }}>
                                                <img src={postPropertyData.property_image} style={{ width: '100%', height: "100%" }}></img>
                                            </div>
                                        }
                                        {uploadsingleImg !== '' &&
                                            <div style={{ width: '100px', height: "100px" }}>
                                                <img src={uploadsingleImg} style={{ width: '100%', height: "100%" }}></img>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="widget-box-2">
                                    <h6 className="title">Property</h6>
                                    <div className="box-info-property">
                                        <fieldset className="box box-fieldset">
                                            <label >
                                                Property Name:<span>*</span>
                                            </label>
                                            <input type="text" className="form-control style-1" value={postPropertyData.property_name} name="property_name" onChange={(e) => { onInputChange(e) }} />
                                        </fieldset>
                                        <div className="box grid-3 gap-30">
                                            <fieldset className="box box-fieldset">

                                                <label >
                                                    Property For:<span>*</span>
                                                </label>
                                                <select
                                                    className="nice-select"
                                                    name='property_for'
                                                    value={postPropertyData.property_for || ''}
                                                    onChange={(e) => { onInputChange(e) }}
                                                >
                                                    <option value="">Select Property For</option>
                                                    <option value="1">Sale</option>
                                                    <option value="2">Rent</option>

                                                </select>
                                            </fieldset>
                                            <fieldset className="box box-fieldset">

                                                <label >
                                                    Property :<span>*</span>
                                                </label>
                                                <select
                                                    className="nice-select"
                                                    name='property_type'
                                                    value={postPropertyData.property_type || ''}
                                                    onChange={(e) => { onpropertytypeChange(e) }}
                                                >
                                                    <option value="">Select Property </option>
                                                    <option value="1">Residental Property</option>
                                                    <option value="2">Commercial Property</option>

                                                </select>

                                            </fieldset>
                                            <fieldset className="box box-fieldset">

                                                <label >
                                                    Property Type :<span>*</span>
                                                </label>
                                                <select
                                                    className="nice-select"
                                                    name='property_category'
                                                    value={postPropertyData.property_category || ''}
                                                    onChange={(e) => { onInputChange(e) }}
                                                >
                                                    <option value="">Select Property Type</option>

                                                    {categoryData &&
                                                        categoryData.map((item, index) => (
                                                            <option value={item.types_id} key={index}>
                                                                {item.types_name}
                                                            </option>
                                                        ))}
                                                </select>

                                            </fieldset>
                                        </div>
                                        <div className="box grid-1 gap-30">
                                            <fieldset className="box-fieldset">
                                                <label >
                                                    Full Address:<span>*</span>
                                                </label>
                                                <input type="text" className="form-control style-1" placeholder="Enter property full address" name='property_address' value={value}
                                                    onChange={handleInput} autoComplete="new-password" />
                                                {status === "OK" && showaddressdropdown && <ul className="border mt-10 rounded">{renderSuggestions()}</ul>}
                                            </fieldset>
                                        </div>
                                        <div className="box grid-2 gap-30">
                                            <fieldset className="box-fieldset">
                                                <label >
                                                    Country:<span>*</span>
                                                </label>
                                                <select
                                                    className="nice-select"
                                                    name='property_country'
                                                    disabled={true}
                                                    value={postPropertyData.property_country || ''}
                                                    onChange={(e) => { onInputChange(e) }}
                                                >
                                                    <option value="91">India</option>


                                                </select>
                                            </fieldset>
                                            <fieldset className="box-fieldset">
                                                <label >
                                                    Province/State:<span>*</span>
                                                </label>
                                                <select
                                                    className="nice-select"
                                                    name='property_state_id'
                                                    value={postPropertyData.property_state_id || ''}
                                                    onChange={(e) => { onInputChange(e) }}
                                                >
                                                    <option value="">Select State</option>

                                                    {stateDropdown &&
                                                        stateDropdown.map((item, index) => (
                                                            <option value={item.state_id} key={index}>
                                                                {item.state_name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </fieldset>
                                        </div>


                                        <div className="box grid-2 gap-30">
                                            <fieldset className="box-fieldset">
                                                <label >
                                                    City:<span>*</span>
                                                </label>
                                                <select
                                                    className="nice-select"
                                                    name='property_city'
                                                    value={postPropertyData.property_city || ''}
                                                    onChange={(e) => { onInputChange(e) }}
                                                >
                                                    <option value="">Select City</option>

                                                    {cityDropdown &&
                                                        cityDropdown.map((item, index) => (
                                                            <option value={item.cities_id} key={index}>
                                                                {item.cities_name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </fieldset>
                                            <fieldset className="box-fieldset">
                                                <label >
                                                    Neighborhood/ Area:<span>*</span>
                                                </label>
                                                <select
                                                    className="nice-select"
                                                    name='property_area'
                                                    value={postPropertyData.property_area || ''}
                                                    onChange={(e) => { onInputChange(e) }}
                                                >
                                                    <option value="">Select Area</option>

                                                    {areaDropdown &&
                                                        areaDropdown.map((item, index) => (
                                                            <option value={item.pin_id} key={index}>
                                                                {item.PostOfficeName}
                                                            </option>
                                                        ))}
                                                </select>
                                            </fieldset>


                                        </div>
                                        <div className="box grid-2 gap-30">
                                            <fieldset className="box-fieldset">
                                                <label >
                                                    Zip Code:<span>*</span>
                                                </label>
                                                <input type="text" className="form-control style-1" name="property_post_code" placeholder="Enter property zip code" value={postPropertyData.property_post_code} onChange={(e) => { onInputChange(e) }} />
                                            </fieldset>
                                            <fieldset className="box-fieldset">
                                                <label >
                                                    LandMark:<span>*</span>
                                                </label>
                                                <input type="text" className="form-control style-1" placeholder="Enter property landmark" value={postPropertyData.property_land_mark} name='property_land_mark' onChange={(e) => { onInputChange(e) }} />
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>

                                <div className="widget-box-2">
                                    <h6 className="title">Tell us about your property</h6>
                                    {postPropertyData.property_type == 1 && <div className="box grid-3 gap-30">
                                        <fieldset className="box-fieldset">
                                            <label >
                                                Bedrooms:<span>*</span>
                                            </label>
                                            <select
                                                className="nice-select"
                                                name='property_no_bedroom'
                                                value={postPropertyData.property_no_bedroom || ''}
                                                onChange={(e) => { onInputChange(e) }}
                                            >
                                                <option value="">Select no of bedrooms</option>

                                                {noOfbedrooms &&
                                                    noOfbedrooms.map((item, index) => (
                                                        <option value={item.value} key={index}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                            </select>

                                        </fieldset>
                                        <fieldset className="box-fieldset">
                                            <label >
                                                Bathrooms:<span>*</span>
                                            </label>
                                            <select
                                                className="nice-select"
                                                name='property_no_bathroom'
                                                value={postPropertyData.property_no_bathroom || ''}
                                                onChange={(e) => { onInputChange(e) }}
                                            >
                                                <option value="">Select no of bathrooms</option>

                                                {noOfbedrooms &&
                                                    noOfbedrooms.map((item, index) => (
                                                        <option value={item.value} key={index}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                            </select>

                                        </fieldset>
                                        <fieldset className="box-fieldset">
                                            <label >
                                                Balconies:<span>*</span>
                                            </label>
                                            <select
                                                className="nice-select"
                                                name='property_no_balconies'
                                                value={postPropertyData.property_no_balconies || ''}
                                                onChange={(e) => { onInputChange(e) }}
                                            >
                                                <option value="">Select no of balconies</option>

                                                {noOfbedrooms &&
                                                    noOfbedrooms.map((item, index) => (
                                                        <option value={item.value} key={index}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                            </select>

                                        </fieldset>

                                    </div>}

                                    <div className="box grid-3 gap-30">
                                        <div className="box grid-2 gap-6 mb-0">
                                            <fieldset className="box-fieldset">
                                                <label >
                                                    CarpetArea:<span>*</span>
                                                </label>
                                                <input type="number" min={0} className="form-control style-1" name="property_carpet_area" placeholder="Enter Carpet Area" value={postPropertyData.property_carpet_area} onChange={(e) => onInputChange(e)} />
                                            </fieldset>
                                            <fieldset className="box-fieldset">
                                                <label >
                                                    &nbsp;
                                                </label>
                                                <select
                                                    className="nice-select"
                                                    name='property_carpet_unit'
                                                    value={postPropertyData.property_carpet_unit || ''}
                                                    onChange={(e) => { onInputChange(e) }}
                                                >
                                                    <option value="">Select Unit</option>

                                                    {propertyareaunit &&
                                                        propertyareaunit.map((item, index) => (
                                                            <option value={item.value} key={index}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                </select>

                                            </fieldset>
                                        </div>
                                        <div className="box grid-2 gap-6 mb-0">
                                            <fieldset className="box-fieldset">
                                                <label >
                                                    Built Up area:<span>*</span>
                                                </label>
                                                <input type="number" min={0} className="form-control style-1" placeholder="Enter Built-Up Area" name="property_built_up_area" value={postPropertyData.property_built_up_area} onChange={(e) => onInputChange(e)} />
                                            </fieldset>
                                            <fieldset className="box-fieldset">
                                                <label >
                                                    &nbsp;
                                                </label>
                                                <select
                                                    className="nice-select"
                                                    name='property_built_up_unit'
                                                    value={postPropertyData.property_built_up_unit || ''}
                                                    onChange={(e) => { onInputChange(e) }}
                                                >
                                                    <option value="">Select Unit</option>

                                                    {propertyareaunit &&
                                                        propertyareaunit.map((item, index) => (
                                                            <option value={item.value} key={index}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                </select>

                                            </fieldset>

                                        </div>
                                        <div className="box grid-2 gap-6 mb-0">
                                            <fieldset className="box-fieldset">
                                                <label >
                                                    Super Built Up area:<span>*</span>
                                                </label>
                                                <input type="number" min={0} name="property_super_built_up_area" className="form-control style-1" placeholder="Enter Super Built-Up Area" value={postPropertyData.property_super_built_up_area} onChange={(e) => onInputChange(e)} />
                                            </fieldset>
                                            <fieldset className="box-fieldset">
                                                <label >
                                                    &nbsp;
                                                </label>
                                                <select
                                                    className="nice-select"
                                                    name='property_super_built_up_unit'
                                                    value={postPropertyData.property_super_built_up_unit || ''}
                                                    onChange={(e) => { onInputChange(e) }}
                                                >
                                                    <option value="">Select Unit</option>

                                                    {propertyareaunit &&
                                                        propertyareaunit.map((item, index) => (
                                                            <option value={item.value} key={index}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                </select>

                                            </fieldset>
                                        </div>
                                    </div>

                                    <div className="box grid-3 gap-30">
                                        <fieldset className="box-fieldset">
                                            <label >
                                                Furnishing:<span>*</span>
                                            </label>
                                            <select
                                                className="nice-select"
                                                name='property_furnish'
                                                value={postPropertyData.property_furnish || ''}
                                                onChange={(e) => { onInputChange(e) }}
                                            >
                                                <option value="">Select Furnishing </option>
                                                <option value="1">Furnished</option>
                                                <option value="2">semi Furnished</option>
                                                <option value="3">Un Furnished</option>

                                            </select>

                                        </fieldset>
                                        <fieldset className="box-fieldset">
                                            <label >
                                                Total Floors:<span>*</span>
                                            </label>
                                            <input type="number" min={0} name="property_total_floors" className="form-control style-1" value={postPropertyData.property_total_floors} onChange={(e) => onInputChange(e)} />
                                        </fieldset>
                                        <fieldset className="box-fieldset">
                                            <label for="bathrooms">
                                                Property On Floor:<span>*</span>
                                            </label>
                                            <select
                                                name="property_on_floor"
                                                className="nice-select"
                                                value={postPropertyData.property_on_floor}
                                                onChange={(e) => onInputChange(e)}
                                            ><option value={''}>No of floors</option>
                                                <option value={'basement'}>Basement</option>
                                                <option value={'lowerground'}>Lower Ground</option>
                                                <option value={'ground'}>Ground</option>
                                                {postPropertyData.property_total_floors &&
                                                    [...Array(Number(postPropertyData.property_total_floors))].map((_, index) => (
                                                        <option key={index} value={index + 1}>
                                                            {index + 1}
                                                        </option>
                                                    ))
                                                }
                                            </select>


                                        </fieldset>
                                    </div>
                                    <div className="box grid-3 gap-30">
                                        <fieldset className="box-fieldset">
                                            <label >
                                                Availability Status:<span>*</span>
                                            </label>
                                            <select className="nice-select" name="property_availabilty_status" value={postPropertyData.property_availabilty_status} onChange={(e) => onInputChange(e)} >
                                                <option value="">Select Availability Status</option>
                                                <option value="1">Ready To Move</option>
                                                <option value="2">Under Construction</option>
                                            </select>
                                        </fieldset>

                                    </div>
                                    {postPropertyData.property_type == 2 && <div className="box grid-4 gap-30">
                                        <fieldset className="box-fieldset">
                                            <label >
                                                Ownership:<span>*</span>
                                            </label>
                                            <select
                                                className="nice-select"
                                                name='property_ownership'
                                                value={postPropertyData.property_ownership || ''}
                                                onChange={(e) => { onInputChange(e) }}
                                            >
                                                <option value="">Select ownership status</option>
                                                <option value={'1'}>Freehold</option>
                                                <option value={'2'}>Leasehold</option>
                                                <option value={'3'}>Co-Operative Society</option>
                                            </select>

                                        </fieldset>
                                        <fieldset className="box-fieldset">
                                            <label >
                                                Washrooms:<span>*</span>
                                            </label>
                                            <select
                                                className="nice-select"
                                                name="property_no_of_washroom" value={postPropertyData.property_no_of_washroom} onChange={(e) => onInputChange(e)}
                                            >
                                                {washwoormSelectOption && washwoormSelectOption.map((item, index) => {
                                                    return (<>
                                                        <option value={item.value} key={index}>{item.name}</option>
                                                    </>)
                                                })}
                                            </select>

                                        </fieldset>
                                        <fieldset className="box-fieldset">
                                            <label >
                                                Personal Washrooms:<span>*</span>
                                            </label>
                                            <select
                                                className="nice-select"
                                                name="property_personal_washroom" value={postPropertyData.property_personal_washroom} onChange={(e) => onInputChange(e)}
                                            >
                                                <option value="">Select no of bathrooms</option>

                                                <option value={''}>Choose One </option>
                                                <option value={1} >Yes</option>
                                                <option value={0} >No</option>
                                            </select>

                                        </fieldset>
                                        <fieldset className="box-fieldset">
                                            <label >
                                                Property Age:<span>*</span>
                                            </label>
                                            <select
                                                className="nice-select"
                                                name="property_age" value={postPropertyData.property_age} onChange={(e) => onInputChange(e)}
                                            >
                                                <option value="">Select property age</option>

                                                {PropertyAgeSelectOption && PropertyAgeSelectOption.map((item, index) => {
                                                    return (<>
                                                        <option value={item.value} key={index}>{item.name}</option>
                                                    </>)
                                                })}
                                            </select>

                                        </fieldset>

                                    </div>}
                                    <div className="box grid-2 gap-30">
                                        <fieldset className="box-fieldset">
                                            <label >
                                                Expected Price:<span>*</span>
                                            </label>
                                            <input name="property_expected_price" className="form-control style-1" type="number" min="0" value={postPropertyData.property_expected_price} placeholder="₹ Enter Expected Price" onChange={(e) => onInputChange(e)} />
                                        </fieldset>
                                        <fieldset className="box-fieldset">
                                            <label >
                                                Price per sq.ft:<span>*</span>
                                            </label>
                                            <input name="property_per_sqftprice" className="form-control style-1" value={postPropertyData.property_per_sqftprice} type="number" min="0" placeholder="₹ Enter Price per sq.ft" onChange={(e) => onInputChange(e)}></input>

                                        </fieldset>

                                    </div>
                                    <div className="box grid-3 gap-10">
                                        <fieldset className="box-fieldset">
                                            <div className='checkboxInput'>
                                                <input type="checkbox" name="property_inclusive_price" checked={postPropertyData.property_inclusive_price == 1 ? true : false} onChange={(e) => {
                                                    setpostPropertyData((prevState) => ({
                                                        ...prevState,
                                                        property_inclusive_price: e.target.checked ? 1 : 0,
                                                    }));
                                                }} />
                                                <span className="ml-5">All inclusive Price</span>
                                            </div>
                                        </fieldset>
                                        <fieldset className="box-fieldset">
                                            <div className='checkboxInput'>
                                                <input type="checkbox" name="property_tax_charge_exclude" checked={postPropertyData.property_tax_charge_exclude == 1 ? true : false} onChange={(e) => {
                                                    setpostPropertyData((prevState) => ({
                                                        ...prevState,
                                                        property_tax_charge_exclude: e.target.checked ? 1 : 0,
                                                    }));
                                                }} />
                                                <span className="ml-5">Tax & Govt. charges excluded</span>
                                            </div>
                                        </fieldset>
                                        <fieldset className="box-fieldset">
                                            <div className='checkboxInput'>
                                                <input type="checkbox" name="property_price_negotiable" checked={postPropertyData.property_price_negotiable == 1 ? true : false} onChange={(e) => {
                                                    setpostPropertyData((prevState) => ({
                                                        ...prevState,
                                                        property_price_negotiable: e.target.checked ? 1 : 0,
                                                    }));
                                                }} />
                                                <span className="ml-5">Price Negotiable</span>
                                            </div>
                                        </fieldset>
                                    </div>

                                </div>
                                {amenities && amenities.length > 0 && (
                                    <div className="widget-box-2">
                                        <h6 className="title">Specifications<span>*</span></h6>
                                        <div className="box-amenities-property">
                                            {amenities && amenities.map((item, index) => {
                                                return (<div className="box-amenities" key={index}>

                                                    <div className="title-amenities fw-7"><fieldset className="amenities-item">
                                                        <input type="checkbox" className="tf-checkbox style-1 primary" value={item.features_id} id={`parent-${item.features_id}`}
                                                            checked={!!selectedAmenities[item.features_id]}
                                                            onChange={() => handleParentCheckboxChange(item.features_id, item.features_name, item.children)} />
                                                        <label for="cb1" className="text-cb-amenities">{item.features_name}</label>
                                                    </fieldset></div>
                                                    {item.children && item.children.length > 0 ? <>
                                                        {item.children.map((child, childindex) => {
                                                            return (<>
                                                                <fieldset className="amenities-item" key={childindex}>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="tf-checkbox style-1 primary"
                                                                        id={`child-${child.features_id}`}
                                                                        checked={!!selectedAmenities[child.features_id]} // Controlled input
                                                                        onChange={() => handleChildCheckboxChange(item.features_id, item.features_name, child.features_id, child.features_name, item.children)} // Handle child checkbox change
                                                                    />
                                                                    <label
                                                                        htmlFor={`child-${child.features_id}`}
                                                                        className="text-cb-amenities"
                                                                    >
                                                                        {child.features_name}
                                                                    </label>
                                                                </fieldset>
                                                            </>)
                                                        })}

                                                    </> : ''}
                                                </div>)

                                            })}
                                        </div>
                                    </div>
                                )}
                                <div className="widget-box-2">
                                    <h6 className="title">About Property</h6>
                                    <fieldset className="box-fieldset">
                                        <textarea
                                            className="form-control style-1"
                                            rows={5}
                                            placeholder="Enter Property Description"
                                            value={postPropertyData.property_desc}
                                            onChangeCapture={(e) => {
                                                setpostPropertyData((prevState) => ({
                                                    ...prevState,
                                                    property_desc: e.target.value,
                                                }));
                                            }}
                                        />
                                    </fieldset>

                                </div>
                                <div className="widget-box-2">
                                    <h6 className="title">Property Gallery Image</h6>
                                    <div className="box-uploadfile text-center"
                                    >
                                        <label className="uploadfile style-1" for='multipleimg'>
                                            <span className="icon icon-img-2"></span>
                                            <div className="btn-upload">
                                                <a href="javascript:void(0)" className="tf-btn primary">Choose File</a>
                                                <input type="file" className="ip-file" id='multipleimg' multiple
                                                    accept="image/*"
                                                    onChange={(e) => handleFileChangeMultiple(e.target.files)} />
                                            </div>
                                            <p className="file-name fw-5">Upload Multiple Images</p>
                                            <p className="file-name  text-danger" >file size must be less than 2 mb</p>
                                        </label>

                                       
                                            {multipleFiles && multipleFiles?.length > 0 ? (
                                                <>
                                                 <div style={{ display: 'flex', flexWrap: 'wrap',  }} className="ms-3">
                                                    {multipleFiles?.map((item, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    position: 'relative',
                                                                    width: '100px',
                                                                    height: '100px',
                                                                    margin: '10px',
                                                                    borderRadius: '5px',
                                                                    overflow: 'hidden',
                                                                    flex: '0 0 auto' // Ensure the images are side-by-side
                                                                }}
                                                            >
                                                                <img
                                                                    src={item.pg_image}
                                                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }} // Ensure images cover the container
                                                                    alt={`Uploaded file ${index}`}
                                                                />
                                                                <button
                                                                    onClick={() => handleRemoveImage(index, item.pg_id)}
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '5px',
                                                                        right: '5px',
                                                                        background: 'rgba(255, 255, 255, 0.7)',
                                                                        border: 'none',
                                                                        cursor: 'pointer',
                                                                        padding: '2px',
                                                                        borderRadius: '50%',
                                                                        width: '20px',
                                                                        height: '20px',
                                                                        lineHeight: '10px',
                                                                    }}
                                                                >
                                                                    <i className="ri-close-line" style={{ color: 'red', fontSize: '16px' }}></i>
                                                                </button>
                                                            </div>
                                                        );
                                                    })}
                                                     </div>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                       

                                        {postPropertyData?.pg_image && postPropertyData?.pg_image?.length > 0 ? <>
                                            <div style={{ display: 'flex', flexWrap: 'wrap', }} className="ms-3">
                                            {postPropertyData?.pg_image?.map((item, index) => {
                                                return (<>
                                                    <div key={index}  style={{
                                                                    position: 'relative',
                                                                    width: '100px',
                                                                    height: '100px',
                                                                    margin: '10px',
                                                                    borderRadius: '5px',
                                                                    overflow: 'hidden',
                                                                    flex: '0 0 auto' // Ensure the images are side-by-side
                                                                }}>
                                                        <img src={item} style={{ width: '100%', height: '100%' }} alt={`Uploaded file ${index}`} />
                                                        <button
                                                            onClick={() => handleRemoveFile(index)}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '5px',
                                                                right: '5px',
                                                                background: 'rgba(255, 255, 255, 0.7)',
                                                                border: 'none',
                                                                cursor: 'pointer',
                                                                padding: '2px',
                                                                borderRadius: '50%',
                                                                width: '20px',
                                                                height: '20px',
                                                                lineHeight: '10px',
                                                            }}
                                                        >
                                                            <i className="ri-close-line" style={{ color: 'red', fontSize: '16px' }}></i>
                                                        </button>
                                                    </div>

                                                </>)
                                            })}
                                            </div>
                                        </> : ''}
                                    </div>
                                </div>
                                <div className="widget-box-2">
                                    <h6 className="title">Videos</h6>
                                    <div className="box grid-2 gap-10">
                                        <fieldset className="box-fieldset">
                                            <label >Video :</label>
                                            <input type="file" className="form-control style-1" accept="video/*" onChange={(e) => handleFileChange(e.target.files[0], 'video')} />
                                            <p className="file-name  text-danger" >file size must be less than 2 mb</p>
                                        </fieldset>


                                        <fieldset className="box-fieldset">
                                            <label >Video URL:</label>
                                            <input type="text" className="form-control style-1" placeholder="video url" value={postPropertyData.property_video_link} name="property_video_link" onChange={(e) => onInputChange(e)} />
                                        </fieldset>
                                        {postPropertyData?.property_video ? <>
                                            <div style={{ width: '100px', height: "100px" }}>
                                                <video src={postPropertyData?.property_video} style={{ width: '100%', height: "100%" }} controls></video>
                                            </div>
                                        </> : ''}
                                        {uploadvideo ? <>
                                            <div style={{ width: '100px', height: "100px" }}>
                                                <video src={uploadvideo} style={{ width: '100%', height: "100%" }} controls></video>
                                            </div>
                                        </> : ''}
                                    </div>
                                </div>
                                <div className="widget-box-2">
                                    <h6 className="title">Brochure (pdf.only):</h6>
                                    <div className="box grid-2 gap-10">

                                        <fieldset className="box-fieldset">
                                            <label >
                                                Title:
                                            </label>
                                            <input name="pb_title" className="form-control style-1" type="text" value={postPropertyData.pb_title} placeholder="Title" onChange={(e) => onInputChange(e)} />
                                            
                                        </fieldset>
                                        <fieldset className="box-fieldset">
                                            <label >choose pdf:</label>
                                            <input name="pb_file" className="form-control style-1" type="file" accept="application/pdf" onChange={(e) => handleFileChange(e.target.files[0], 'pdf')} />
                                            <p className="file-name  text-danger" >file size must be less than 2 mb</p>
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="widget-box-2">
                                    <h6 className="title">Floor Plan:</h6><button className="tf-btn primary" onClick={addFieldset}>Add</button>

                                    {floorPlansupdate && floorPlansupdate?.length > 0 ?
                                        <>
                                            {floorPlansupdate?.map((floorPlan, index) => (
                                                <div className="box grid-2 gap-5">
                                                    <fieldset className="box-fieldset" key={index}>
                                                        <label>
                                                            Title:<span>*</span>
                                                        </label>
                                                        <input
                                                            name="title"
                                                            className="form-control style-1"
                                                            type="text"
                                                            placeholder="Title"
                                                            value={floorplantitle[index] || ''}
                                                            onChange={(e) => onInputfloorplanUpdateChange(e, index)}
                                                        />
                                                    </fieldset>
                                                    <fieldset className="box-fieldset" key={index}>
                                                        <label>
                                                            Choose file:
                                                        </label>
                                                        <div className="d-flex gap-2">
                                                            {floorplanimage && floorplanimage?.length > 0 &&
                                                                <div style={{ width: '100px', height: "80px" }}>
                                                                    <img src={floorplanimage[index] ? floorplanimage[index] : constant.DEFAULT_IMAGE} style={{ width: '100%', height: "100%" }}></img>
                                                                </div>
                                                            }
                                                            <input
                                                                name="property_floor_plan"
                                                                className="form-control style-1 "
                                                                type="file"
                                                                accept="image/*"
                                                                style={{ height: '45px' }}

                                                                onChange={(e) => handleFileFloorplanUpdateChange(e, index)}
                                                            />
                                                             <p className="file-name  text-danger" >file size must be less than 2 mb</p>
                                                            <button className="tf-btn primary" type="button" style={{ height: '45px' }} onClick={() => handleRemovefloorFloorplan(index)}>   <i className="ri-close-line" style={{ color: 'white', fontSize: '16px' }}></i></button>
                                                        </div>



                                                    </fieldset>
                                                </div>
                                            ))}
                                        </> : null}

                                    {floorPlans.map((floorPlan, index) => (
                                        <div className="box grid-2 gap-5">
                                            <fieldset className="box-fieldset" key={index}>
                                                <label>
                                                    Title:<span>*</span>
                                                </label>
                                                <input
                                                    name="title"
                                                    className="form-control style-1"
                                                    type="text"
                                                    placeholder="Title"
                                                    value={postPropertyData?.pfp_title?.[index] || ''}
                                                    onChange={(e) => onInputfloorplanChange(e, index)}
                                                />
                                            </fieldset>
                                            <fieldset className="box-fieldset" key={index}>
                                                <label>
                                                    Choose file:
                                                </label>
                                                <div className="d-flex gap-2">
                                                    {postPropertyData?.pfp_image && postPropertyData?.pfp_image.length > 0 &&
                                                        <div style={{ width: '100px', height: "80px" }}>
                                                            <img src={postPropertyData?.pfp_image?.[index] ? postPropertyData?.pfp_image?.[index] : constant.DEFAULT_IMAGE} style={{ width: '100%', height: "100%" }}></img>
                                                        </div>
                                                    }
                                                    <input
                                                        name="property_floor_plan"
                                                        className="form-control style-1 "
                                                        type="file"
                                                        accept="image/*"
                                                        style={{ height: '45px' }}

                                                        onChange={(e) => handleFileFloorplanChange(e, index)}
                                                    />
                                                     <p className="file-name text-danger" >file size must be less than 2 mb</p>
                                                    {floorPlansupdate?.length > 0 && <button className="tf-btn primary  " type="button" style={{ height: '45px' }} onClick={() => removeFieldset(index)}><i className="ri-close-line" style={{ color: 'white', fontSize: '16px' }}></i></button>}
                                                    {floorPlansupdate?.length == 0 && index > 0 && <button className="tf-btn primary" type="button" style={{ height: '45px' }} onClick={() => removeFieldset(index)}><i className="ri-close-line" style={{ color: 'white', fontSize: '16px' }}></i></button>}
                                                </div>




                                            </fieldset>
                                        </div>
                                    ))}



                                </div>
                                <button  className="tf-btn primary"  disabled={buttonLoader} onClick={(e) => { onSubitPropertydata(e) }}> {buttonLoader ? (<img src="/images/loder01.gif" width="60px" height="11px" />) : ("Add Property")} </button>
                                <div className="footer-dashboard">
                                    <p className="text-variant-2">©2024 Proopify. All Rights Reserved.</p>
                                </div>
                            </>}

                            {step == 2 && <>
                                <div className='col-lg-12'>
                                    <div className=" mb-15 tx-center"><img src='/images/verified.gif' className="wd-200 mb-20" />
                                        <h2 style={{ textAlign: 'center' }}>Thank you for submitting </h2>
                                        <p style={{ textAlign: 'center' }}>Your Property has been posted, Our team will review it.</p>
                                    </div>
                                    <div className='d-flex justify-content-center '>
                                        <div className="flat-bt-top"><a className="tf-btn primary" href="/dashboard">  Go to dashboard <i className="ri-arrow-right-line"></i></a></div>

                                    </div>
                                </div>


                            </>}

                        </div>

                    </div>
                    <div className="overlay-dashboard"></div>
                    <Footer></Footer>
                </div>
            </div>


        </div>

    </>)
}

export default PostProperty