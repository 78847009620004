import React, { useContext } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import HomeSlider from './home_slider'
import PropertyType from './property_type'
import HomeTestimonials from './home_testimonials'
import HomeBlogs from './home_blogs'
import HomeCities from './home_cities'
import FeaturedProperties from './home_featured_properties'
import HomeAboutUs from './home_about_us'
import { useState, useEffect, useRef } from "react"
import { ApiService } from '../../Components/Services/apiservices'
import { useNavigate } from 'react-router-dom'
import PropertyBox from '../../Components/Element/property_box'
import FeaturedProjects from './home_featured_projects'
import FeaturedAgents from './home_featured_agents'
import HomeWhyChoose from './home_why_choose_us'
import FeaturedFaq from './home_featuured_faq'
import HomePropertyBlogs from './home_property_blogs'
import HomeFeaturedServices from './home_featured_services'
import HomeSecondaryFeaturedProperties from './home_featured_secondary_properties'
import SecondaryFeaturedProjects from './home_featured_secondry_projects'
import DataContext from '../../Components/Element/context'



function Home() {
    const contextValues= useContext(DataContext)
   
    return (
        <>
            <Header />
            <HomeSlider />
            <PropertyType />
            <FeaturedProjects />
            <SecondaryFeaturedProjects></SecondaryFeaturedProjects>
            <FeaturedProperties />
            <HomeSecondaryFeaturedProperties />
            <HomeAboutUs />
            <HomeWhyChoose></HomeWhyChoose>
            <HomeCities></HomeCities>
            <FeaturedAgents></FeaturedAgents>
            <HomeFeaturedServices></HomeFeaturedServices>
            <HomeTestimonials />
            <HomeBlogs />
            <HomePropertyBlogs />
            <FeaturedFaq></FeaturedFaq>
            <Footer />
            {contextValues.settingData.admin_support_whatsappno ? <>
      <div className="whatsapp" style={{ bottom: '15px', position: 'fixed', right: '15px', zIndex: 9 }}>
        <a href={`https://wa.me/${contextValues.settingData.admin_support_whatsappno}`} target="new">
          <div>
            <img src="/images/watsap.png" />
            {/* <i className="ri-whatsapp-line" style={{fontSize:'50px'}}></i> */}
            </div>
        </a>
      </div>
    </> : null}
    {contextValues.settingData.admin_support_mobile ? <>
      <div className="whatsapp" style={{ bottom: '85px', position: 'fixed', right: '15px', zIndex: 9  ,}}>
        <a href={`tel:${contextValues.settingData.admin_support_mobile}`} target="new">
          <div style={{width:'70px' }} >
          {/* <i className="ri-phone-line" style={{fontSize:'60px'}}></i> */}
            <img src="/images/cal.png" />
            
            </div>
        </a>
      </div>
    </> : null}

        </>

    )
}

export default Home