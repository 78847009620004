import constant from "../../../Components/Services/constant";

const ProjectSpecifications = ({ projectDetail, amenityimgurl }) => {

    return <>
        {projectDetail?.selected_features && projectDetail?.selected_features.length > 0 && (
            <div className="row g-3">
                {projectDetail.selected_features.map((item, index) => (
                    <div className="col-lg-4">
                    <div className="box-feature box-benefit style-1 mt-10" key={index}>
                        <div className="icon-box">                            
                            <img src={item?.image ? `${amenityimgurl}${item.image}` : constant.DEFAULT_IMAGE}
                                            alt={item?.name || 'Specification Image'}
                                        />
                        </div>
                        <div className="content">
                        <div className="fw-7">{item?.name}</div>
                        {item?.children?.length > 0 && (
                            <ul>
                                {item.children.map((childitem, childindex) => (
                                    <li className="feature-item" key={childindex}>
                                        <img
                                            src={childitem?.image ? `${amenityimgurl}${childitem.image}` : constant.DEFAULT_IMAGE}
                                            alt={childitem?.name || 'Specification Image'}
                                            style={{ width: '17px', height: '17px' }}
                                        />
                                        <span>{childitem?.name}</span>
                                    </li>
                                ))}
                            </ul>
                        )}
                        </div>
                    </div>
                    </div>
                ))}
            </div>
        )}
    </>
}

export default ProjectSpecifications;