import React, { createContext, useState, useEffect, useRef } from "react";
import { ApiService } from "../Services/apiservices";
const DataContext = createContext();

export const DataProvider = ({ children }) => {
 
  const [toggleEnquiryModal, setToggleEnquiryModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [settingData, setSettingData] = useState({});
  const [propertyType, setPropertyType] = useState([])
  const [propertyDetail, setpropertyDetail] = useState({});
  const [areaCitySlug, setareaCitySlug] = useState({area:null, city:null});


  const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current) {
      getSettingData()

    }
    didMountRef.current = false
})

  const getSettingData=()=>{
    ApiService.fetchData('settings').then((res)=>{
      if(res.status=='success'){
        setSettingData(res.sitesettings)
      }
    })

  }
  return (
    <DataContext.Provider
      value={{
        userData,
        setUserData,
        settingData,
        setSettingData,
        toggleEnquiryModal,
        setToggleEnquiryModal,
        propertyDetail,
        setpropertyDetail,
        propertyType,
        setPropertyType,
        areaCitySlug,
        setareaCitySlug
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
