import { useEffect, useRef, useState, useContext } from 'react';
import { ApiService } from '../../Services/apiservices';
import { validNumber, validEmail } from '../Regex';
import OTPInput from 'react-otp-input';

function LoginModal() {
    const [steps, setSteps] = useState(1);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [spinnerLoading, setspinnerLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [resendTimer, setResendTimer] = useState(30);
    const [otp, setOtp] = useState('');
    const [regDetails, setRegDetails] = useState({
        userType: '1',
        user_name: "",
        user_email: "",
    });
    const [userLoginDetails, setUserLoginDetails] = useState({
        user_mobile: "",
    });
    const userLogin = () => {

        if( userLoginDetails.user_mobile==''){
            const myElement = document.getElementsByName("user_mobile")[0]; 
        if (myElement) {
            myElement.style.border = '1px solid red';
        }
            return false;
        }
            if (!validNumber.test(userLoginDetails.user_mobile)) {
                setErrorMessage("Please enter valid Mobile Number");
                return false;
            }
            setIsLoading(true)
            ApiService.loginProccessPostData("user-login", userLoginDetails).then((res) => {
                if (res.status === "success") {
                    localStorage.setItem("TEMP_USER_TOKEN", res.user_token);
                    // setSuccessMessage(res.message)
                    setErrorMessage('')
                    setSteps(2);
                    setIsLoading(false)
                    setResendTimer(30);
                } else {
                    setErrorMessage(res.message);
                    setIsLoading(false)
                }
            }).catch(() => {
                setIsLoading(false)
            })
       

    };
    const onTodoChange = (e) => {
        setErrorMessage('')
        setSuccessMessage('')
        const { name, value } = e.target;
        setUserLoginDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value !== '') {
            e.target.style.border = '';
        }
    };

    const onChangeOtp = (otp) => {
        setErrorMessage("");
        setSuccessMessage('')
        setOtp(otp);
        if (otp.length === 4) {
            userLoginOtpProcess(otp);
        }
    };
    const userLoginOtpProcess = (otp) => {
        setspinnerLoading(true)
        const dataString = {
            user_otp: otp,
        };
        ApiService.loginProccessPostData("otpverify", dataString).then((res) => {
            if (res.status === "success") {
                if (res.user_status === 'new_register') {
                    setTimeout(() => {
                        localStorage.setItem("TEMP_USER_TOKEN", res.user_token);
                        setSteps(3)
                        setspinnerLoading(false)
                    }, 500);
                } else if (res.user_status == 'already_registered') {
                    setSuccessMessage(res.message)
                    setspinnerLoading(false)
                    setTimeout(() => {
                        setSuccessMessage('')
                        localStorage.setItem("USER_TOKEN", res.user_token);
                        window.location.reload();
                    }, 500);
                }
            }
            else if (res.status == 'error' && res.message == 'Session expired') {
                setErrorMessage(res.message)
                setspinnerLoading(false)
                setTimeout(() => {
                    setErrorMessage('')
                    localStorage.removeItem("TEMP_USER_TOKEN");
                    window.location.reload();
                }, 500);
            }
            else {
                setTimeout(() => {
                    setErrorMessage(res.message);
                    setspinnerLoading(false)
                }, 500);
            }
        }).catch(() => {
            setspinnerLoading(false)
        });

    };



    const goBack = () => {
        setErrorMessage("");
        setSuccessMessage('')
        setUserLoginDetails({
            user_mobile: "",
        })
        setRegDetails({
            userType: 1,
            user_name: "",
            user_email: "",
        })
        setOtp("");
        setSteps(1);
    }



    useEffect(() => {
        const timerId = setInterval(() => {
            if (resendTimer > 0) {
                setResendTimer(prev => prev - 1);
            }
        }, 1000);
        return () => {
            clearInterval(timerId);
        };
    }, [resendTimer]);


    const resendOTP = () => {
        setErrorMessage("");
        setResendTimer(30);
        setOtp("");
        setspinnerLoading(true)
        const dataString = {
            user_token: '',
        }
        ApiService.loginProccessPostData('resendotp', dataString).then((res) => {
            if (res.status === "success") {
                setTimeout(() => {
                    setspinnerLoading(false);
                }, 200);
            } else if (res.status == 'error' && res.message == 'Session expired') {
                setErrorMessage(res.message);
                setTimeout(() => {
                    setErrorMessage('')
                    localStorage.removeItem("TEMP_USER_TOKEN");
                    window.location.reload();
                }, 500);
            }
            else {
                setErrorMessage(res.message);
                setTimeout(() => {
                    setspinnerLoading(false);
                }, 500);
            }
        }).catch(() => {
            setspinnerLoading(false)
        });
    }

    const onSignUpInputChange = (e) => {

        setErrorMessage('')
        setSuccessMessage('')
        const { name, value } = e.target;
        setRegDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value !== '') {
            e.target.style.border = '';
        }
    };


    const userRegisterProcess = (e) => {
        if( regDetails.userType==''){
            const myElement = document.getElementsByName("userType")[0]; 
        if (myElement) {
            myElement.style.border = '1px solid red';
        }
            return false;}

        if( regDetails.user_name==''){
            const myElement = document.getElementsByName("user_name")[0]; 
        if (myElement) {
            myElement.style.border = '1px solid red';
        }
            return false;}
            if( regDetails.user_email==''){
                const myElement = document.getElementsByName("user_email")[0]; 
            if (myElement) {
                myElement.style.border = '1px solid red';
            }
                return false;}
          
            if (!validEmail.test(regDetails.user_email)) {
                setErrorMessage("Please Enter Valid Email");
                return false;
            }
            setIsLoading(true)
            ApiService.loginProccessPostData("register", regDetails).then((res) => {
                if (res.status === "success") {
                    setSuccessMessage(res.message)
                    localStorage.setItem("USER_TOKEN", res.user_token);
                    setTimeout(() => {
                        setSuccessMessage('')
                        window.location.reload();
                    }, 500);
                }
                else if (res.status == 'error' && res.message == 'Session expired') {
                    setErrorMessage(res.message);
                    setTimeout(() => {
                        localStorage.removeItem("TEMP_USER_TOKEN");
                        window.location.reload();
                    }, 500);
                }
                else {
                    setErrorMessage(res.message);
                    setTimeout(() => {
                        setIsLoading(false)
                    }, 500);
                }
            }).catch(() => {
                setIsLoading(false)
            });

    }


    return (

        <div className="modal fade" id="modalLogin">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    {steps == 1 && (
                        <>
                            <div className="flat-account bg-surface text-center">
                                <h6 className="title ">Log in or Sign up</h6>
                                <p>Browse through our list of thousand properties
                                    for buy, sale and rent</p>
                                <span className="close-modal icon-close2" data-bs-dismiss="modal"></span>
                                <div className='mt-10'>
                                    {errorMessage ? <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div> : ''}
                                    <div className='form-group country-input'>
                                        <input type="number" name="user_mobile" min='0' className="form-contact style-1 loginCheck" value={userLoginDetails.user_mobile} onChange={(e) => onTodoChange(e)} />
                                        <span className="country-code">+91</span>
                                    </div>
                                    <button type="submit" className="tf-btn primary w-100 mb-15" onClick={() => { userLogin() }}>
                                        {isLoading ? (<img src="/images/loder01.gif" width="60px" height="11px" />) : ("Login")}</button>
                                    <p className="text-center tx-12">By continuing, you agree to our <a href="/page/terms-of-services" className="tx-primary" target="new">Terms &amp; Conditions</a> &amp; <a href="/page/privacy-policy" className="tx-primary" target="new">Privacy policy</a></p>
                                </div>
                            </div>
                        </>
                    )}
                    {steps == 2 && (
                        <>
                            <div className="flat-account bg-surface text-center">
                                <span className="close-modal icon-close2" data-bs-dismiss="modal"></span>
                                <h6 className="title ">Verifying it's you</h6>
                                <p >
                                    We've sent an OTP to the mobile number +91-{userLoginDetails.user_mobile} Please enter it below and continue.<a href='javascript:;' className='tx-primary' onClick={(e) => goBack()}>Change</a></p>
                                <div className='mt-10'>
                                    {errorMessage && (
                                        <div className="alert alert-danger" role="alert">
                                            {errorMessage}
                                        </div>
                                    )}
                                    {successMessage && (
                                        <div className="alert alert-success" role="alert">
                                            {successMessage}
                                        </div>
                                    )}
                                    <div className='form-group mb-15  otp-input'>
                                        <OTPInput
                                            value={otp}
                                            onChange={onChangeOtp}
                                            numInputs={4}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                    </div>
                                    {resendTimer === 0 ? (
                                        <p className='tx-gray text-center'>
                                            Did not receive OTP? <a href='javascript:void(0);' className='tx-primary' onClick={(e) => resendOTP()}>Resend OTP</a>
                                        </p>
                                    ) : (
                                        <p className='tx-gray text-center'>Resend code in {resendTimer} sec</p>
                                    )}
                                    {spinnerLoading && (
                                        <div className="siteloader tx-center">
                                            <img src="/images/loader.webp" alt="Loading..." width="30" height="30" />
                                        </div>
                                    )}

                                </div>
                            </div>
                        </>
                    )}

                    {steps == 3 && (
                        <>
                            <div className="flat-account bg-surface ">
                                <span className="close-modal icon-close2" data-bs-dismiss="modal"></span>
                                <h6 className="title text-center">Create Account</h6>
                                <p className='text-center'>Start with your free account today with Proopify</p>
                                <div className='mt-10'>
                                    {errorMessage && (
                                        <div className="alert alert-danger" role="alert">
                                            {errorMessage}
                                        </div>
                                    )}
                                    {successMessage && (
                                        <div className="alert alert-success" role="alert">
                                            {successMessage}
                                        </div>
                                    )}
                                    <div className='form-group mb-15'>
                                        <label>Are you a</label>
                                        <div className='form-group-radio mt-10'>
                                            <div className='radioBox'>
                                                <input type="radio" id='radio-1' className='registerrequired' name="userType" checked={regDetails.userType == '1' ? true : false} value={'1'} onChange={(e) => { onSignUpInputChange(e) }} />
                                                <label className="radio-label" for="radio-1">Buyer/Owner</label>
                                            </div>
                                            <div className='radioBox'>
                                                <input type="radio" name="userType" id='radio-2' className='registerrequired' checked={regDetails.userType == '2' ? true : false} value={'2'} onChange={(e) => { onSignUpInputChange(e) }} />
                                                <label className="radio-label" for="radio-2">Agent</label>
                                            </div>
                                            <div className='radioBox'>
                                                <input type="radio" name="userType" id='radio-3' className='registerrequired' checked={regDetails.userType == '3' ? true : false} value={'3'} onChange={(e) => { onSignUpInputChange(e) }} />
                                                <label className="radio-label" for="radio-3">Builder</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group mb-15'>
                                        <input type='text' placeholder='Full Name' className='registerrequired' name="user_name" onChange={(e) => { onSignUpInputChange(e) }}></input>
                                    </div>
                                    <div className='form-group mb-15'>
                                        <input type='email' placeholder='Email Address' className='registerrequired' name='user_email' onChange={(e) => { onSignUpInputChange(e) }}></input>
                                    </div>
                                    <div className='mb-15'>
                                        <button className='tf-btn primary w-100 mb-15' type='Submit' onClick={userRegisterProcess} disabled={isLoading} > {isLoading ? (<img src="/images/loder01.gif" width="60px" height="11px" />) : ("Create Account")}</button>
                                    </div>
                                    <p className='text-center'>Already have Proopify account? <a className='tx-primary' href="javascript:void(0)" onClick={goBack} >Login Now</a></p>
                                </div>

                            </div>
                        </>
                    )}


                </div>
            </div>
        </div>

    )
}

export default LoginModal