import { useLocation, useParams } from "react-router-dom"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { useState, useEffect, useRef, useContext, useCallback } from "react"
import { ApiService } from "../../Components/Services/apiservices"
import PropertyBox from "../../Components/Element/property_box"
import Skeleton from "react-loading-skeleton"
import InfiniteScroll from "react-infinite-scroll-component";
import DataContext from "../../Components/Element/context"
import { isMobile } from "react-device-detect"



const PropertyList = ({ citySlug }) => {
  const contextValues = useContext(DataContext)
  const didMountRef = useRef(true);
  const [propertyList, setpropertyList] = useState([])
  const [totalLength, setTotalLength] = useState("");
  const [imgUrl, setimgUrl] = useState('')
  const [sortfield, setsort] = useState('')
  const [hasMore, sethasMore] = useState(false)
  const [pageno, setpageno] = useState(1)
  const [isNewSearch, seisNewsearch] = useState(false)
  const [selectedSlug, setSelectedSlug] = useState({ name: '', id: '' });
  const [currentPage, setcurrentPage] = useState('')
  const [isLoading, setisLoading] = useState(false);
  const [showSearch, setshowSearch] = useState(false)
  const [citiesData, setcitiesData] = useState([])
  const [searchquery, setsearchquery] = useState('');
  const [propertyType, setPropertyType] = useState([])
  const type = localStorage.getItem('type')
  const { slug } = useParams()
  const [searchfield, setsearchfield] = useState({
    property_for: type ? slug == 'buy' ? '1' : 'rent' ? '2' : '' : '1',
    search_keyword: '',
    location: '',
    property_type: '',
    no_of_bedrooms: '',
    property_availabilty_status: '',
    budget: '',
  })
  const location = useLocation();
  const areaCitySlug = JSON.parse(localStorage.getItem('areaCitySlug'))


  useEffect(() => {
    if (didMountRef.current) {
      getPropertyList(null)
      getPropertyData()
      if (areaCitySlug?.area && areaCitySlug?.city) {
        setsearchquery(areaCitySlug?.city)
      }
      else if (citySlug && citySlug !== undefined) {
        setsearchquery(citySlug)
      }


    }
    didMountRef.current = false;
  }, []);
  const getPropertyData = () => {

    ApiService.fetchData("getallpropertytype").then((res) => {
      if (res.status === "success") {
        setPropertyType(res.allTypes)
        contextValues.setPropertyType(res.allTypes)
        if (slug && !type) {
          const matchedType = res.allTypes.find(item => item.type_slug === slug);
          setsearchfield((prevState) => ({
            ...prevState,
            property_type: matchedType?.types_id,
          }));
          setSelectedSlug({ name: matchedType?.types_name, id: matchedType?.types_id })
        }

      }
    })
  }


  const getPropertyList = (sort = null) => {
    const type = localStorage.getItem('type');
    setisLoading(true);

    let pageNumber = pageno; // Use the current state value

    // If sorting is applied, reset the page number to 1
    if (sort) {
      setpageno(1);
      pageNumber = 1;
    }
    const dataString = {
      property_for: type && !citySlug && !areaCitySlug?.area && !areaCitySlug?.city ? slug : '',
      property_sort: sort ? sort : sortfield ? sortfield : '',
      type_slug: !type && !citySlug && !areaCitySlug?.area && !areaCitySlug?.city ? slug : '',
      property_city: !type && citySlug && !areaCitySlug?.city
        ? citySlug
        : !type && citySlug && areaCitySlug?.city
          ? areaCitySlug?.city
          : '',
      area_slug: !type && citySlug && areaCitySlug?.area ? areaCitySlug?.area : ''
    };

    ApiService.postData("propertiesFor" + "?page=" + pageNumber, dataString)
      .then((res) => {
        if (res.status === "success") {
          setcurrentPage(res.propertylist.current_page);
          setimgUrl(res.property_img_url);

          if (pageNumber >= res.propertylist.last_page) {
            sethasMore(false); // No more pages
          } else {
            sethasMore(true); // More pages available
            setpageno(pageNumber + 1); // Increment page number for next call
          }

          if (sort !== '' && sort !== null) {
            setpropertyList(res.propertylist.data); // Replace list on sort
          } else if (res.propertylist.data.length > 0) {
            const mergeData = [...propertyList, ...res.propertylist.data];
            setpropertyList(mergeData); // Append new data on infinite scroll
          }

          setTotalLength(res.propertylist.total);
        }

        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
      });
  };
  const onTodoChange = (name, value) => {

    setsearchfield((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name == 'property_for') {
      setsearchfield((prevState) => ({
        ...prevState,
        ['budget']: '',
      }));
    }

  };

  const onChangeCitySerachInput = (value) => {
    setsearchquery(value);
    if (value.length > 1) {
      const dataString = { cities_name: value }
      ApiService.postData('getSearchedCityData', dataString).then((res) => {
        if (res.status === 'success') {
          setcitiesData(res.data);
        }
      }).catch((error) => {
        console.error("Error fetching city data:", error);
      });
    } else {
      setcitiesData([]);
    }
  };
  const handleCitySelect = (e, selectedcity) => {
    e.preventDefault()
    onTodoChange('location', selectedcity.cities_id)
    setsearchquery(selectedcity.cities_name)
    setcitiesData([])

  }
  const onSearchbttnClick = (e) => {
    if(isMobile){
      const modalElement = document.getElementById('modalSearch');
      const modal = window.bootstrap.Modal.getInstance(modalElement); // Get existing instance
      if (modal) {
          modal.hide();
      } else {
          // If no instance found, create a new one
          new window.bootstrap.Modal(modalElement).hide();
      }
    }
    e.preventDefault()
    sethasMore(false);
    localStorage.setItem('searchpropertyfield', JSON.stringify(searchfield));
    getsearchPropertyList(null, true)
  };

  const getsearchPropertyList = (sort = null, isNewSearch = false) => {
    seisNewsearch(isNewSearch);  // Set if it is a new search
    setisLoading(true);

    const searchfield = JSON.parse(localStorage.getItem('searchpropertyfield'));
    const dataString = {
      property_name: searchfield.search_keyword,
      property_city: searchfield.location,
      property_for: searchfield.property_for,
      property_category: searchfield.property_type,
      property_no_bedroom: searchfield.no_of_bedrooms,
      property_availabilty_status: searchfield?.property_availabilty_status,
      property_budget: searchfield?.budget,
      property_sort: sort ? sort : sortfield ? sortfield : ''
    };

    let pageNumber = pageno;
    if (sort || isNewSearch) {
      setpageno(1);
      pageNumber = 1;
    }
    ApiService.postData("getSearchedPropertyData" + "?page=" + pageNumber, dataString)
      .then((res) => {
        if (res.status === "success") {
          setcurrentPage(res.data.current_page);
          setimgUrl(res.property_img_url);

          // Handle pagination
          if (pageNumber >= res.data.last_page) {
            sethasMore(false);
          } else {
            sethasMore(true);
            setpageno(pageNumber + 1);
          }
          if (sort !== '' && sort !== null) {
            setpropertyList(res.data.data);
          } else if (isNewSearch) {
            setpropertyList(res.data.data);
          } else if (res.data.data.length > 0) {
            const mergeData = [...propertyList, ...res.data.data];
            setpropertyList(mergeData);
          }

          setTotalLength(res.data.total);
        }

        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
      });
  };

  return (<>
    <Header></Header>

    {/* Search code start */}
    {isMobile ?
    <div className="modal fade" id="modalSearch">
    <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
      <section className="flat-filter-search-v2">
        
        <div className="flat-account flat-tab flat-tab-form" style={{ border: 'none'}}>
          <span className="close-modal icon-close2" data-bs-dismiss="modal"></span>
          <ul className="nav-tab-form style-3" role="tablist">

            <li className="nav-tab-item" role="presentation">
              <a href="javascript:void(0)" className={`nav-link-item ${type && slug == 'rent' ? 'active' : ''}`} data-bs-toggle="tab" onClick={() => { onTodoChange('property_for', '2') }}>For Rent</a>
            </li>
            <li className="nav-tab-item" role="presentation">
              <a href="javascript:void(0)" className={`nav-link-item ${type && slug == 'buy' ? 'active' : !type ? 'active' : ''}`} data-bs-toggle="tab" onClick={() => { onTodoChange('property_for', '1') }}>For Sale</a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade active show" role="tabpanel">
              <div className="form-sl">
                <form method="post">
                  <div className="wd-find-select"  style={{ padding: '20px'}}>
                    <div className="inner-group">
                      <div className="form-group-1 search-form form-style">
                        <label>Keyword</label>
                        <input type="text" className="form-control" placeholder="Search Keyword." name="seach_keyword" title="Search for" onChange={(e) => { onTodoChange('search_keyword', e.target.value) }} />
                      </div>
                      <div className="form-group-2 form-style">
                        <label>Location</label>
                        <div className="group-ip">
                          <input type="text" className="form-control" placeholder="Search Location" title="Search for" value={searchquery} onChange={(e) => { onChangeCitySerachInput(e.target.value) }} />
                          <a href="javascript:void(0)" className="icon icon-location"></a>
                          {citiesData && citiesData.length > 0 ? <>
                            <ul className='searchlocationlist' style={{ width: '100%' }}>
                              {citiesData.map((item, index) => {
                                return (<>
                                  <li key={index} onClick={(e) => { handleCitySelect(e, item) }}>{item.cities_name}</li>
                                </>)
                              })}
                            </ul>
                          </> : ''}
                        </div>
                      </div>
                      {contextValues.propertyType && contextValues.propertyType.length > 0 ? <>
                        <div className="form-group-3 form-style">
                          <label>Type</label>
                          <div className="group-select">
                            <div className="nice-select" tabindex="0"><span className="current">{selectedSlug.name ? selectedSlug.name : 'Select Type'}</span>
                              <ul className="list">
                                <li data-value className={`option ${selectedSlug.name == '' ? 'selected' : ''}`} onClick={() => { onTodoChange('property_type', '') }}>Select Type</li>
                                {contextValues.propertyType.map((item, index) => {
                                  return (<>
                                    <li data-value={item.types_id} className={`option ${selectedSlug.id == item.types_id ? 'selected' : ''}`} key={index} onClick={() => { onTodoChange('property_type', item.types_id) }}>{item.types_name}</li>
                                  </>)
                                })}

                              </ul>
                            </div>
                          </div>
                        </div>
                      </> : ''}
                      <div className="form-group-4 form-style">

                        <div className="group-select ">
                          <div className="box-select">
                            <label className="title-select text-variant-1">Unit</label>
                            <div className="nice-select" tabindex="0"><span className="current">Select no of rooms</span>
                              <ul className="list">
                                <li data-value className="option selected" onClick={() => { onTodoChange('no_of_bedrooms', '') }}>Select no of rooms</li>
                                {[...Array(10)].map((_, index) => {
                                  return (<>
                                    <li data-value={index + 1} className="option" onClick={() => { onTodoChange('no_of_bedrooms', index + 1) }}>{index + 1}</li>

                                  </>)
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div className="form-group-5 form-style">
                        <div className="group-select ">
                          <div className="box-select">
                            <label className="title-select text-variant-1">Property Status</label>
                            <div className="nice-select" tabindex="0"><span className="current">Select property status</span>
                              <ul className="list">
                                <li data-value className={`option selected`} onClick={() => { onTodoChange('property_availabilty_status', '') }}>Select Property Status</li>

                                <li data-value={'1'} className={`option ${searchfield?.property_availabilty_status == '1' ? 'selected' : ''}`} onClick={() => { onTodoChange('property_availabilty_status', '1') }}>Ready to shift</li>
                                <li data-value={'2'} className={`option ${searchfield?.property_availabilty_status == '2' ? 'selected' : ''}`} onClick={() => { onTodoChange('property_availabilty_status', '2') }}>Under Construction</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div className="form-group-6 form-style">
                        <div className="group-select ">
                          {searchfield.property_for == '1' ? <>
                            <div className="box-select">
                              <label className="title-select text-variant-1">Budget</label>
                              <div className="nice-select" tabindex="0"><span className="current">Select budget</span>
                                <ul className="list">
                                  <li data-value className={`option ${searchfield?.budget == '' ? 'selected' : ''}`} onClick={() => { onTodoChange('budget', '') }}>Select budget</li>
                                  <li data-value={'1'} className={`option ${searchfield?.budget == '1' ? 'selected' : ''}`} onClick={() => { onTodoChange('budget', '1') }}>0-40 lakh</li>
                                  <li data-value={'2'} className={`option ${searchfield?.budget == '2' ? 'selected' : ''}`} onClick={() => { onTodoChange('budget', '2') }}>40-80 lakh</li>
                                  <li data-value={'3'} className={`option ${searchfield?.budget == '3' ? 'selected' : ''}`} onClick={() => { onTodoChange('budget', '3') }}>Above 80 lakh</li>
                                </ul>
                              </div>
                            </div>


                          </> : searchfield.property_for == '2' ? <>
                            <div className="box-select">
                              <label className="title-select text-variant-1">Budget</label>
                              <div className="nice-select" tabindex="0"><span className="current">Select budget</span>
                                <ul className="list">
                                  <li data-value className={`option ${searchfield?.budget == '' ? 'selected' : ''}`} onClick={() => { onTodoChange('budget', '') }}>Select budget</li>
                                  <li data-value={'1'} className={`option ${searchfield?.budget == '1' ? 'selected' : ''}`} onClick={() => { onTodoChange('budget', '1') }}>Below 10 K</li>
                                  <li data-value={'2'} className={`option ${searchfield?.budget == '2' ? 'selected' : ''}`} onClick={() => { onTodoChange('budget', '2') }}>10-20 K</li>
                                  <li data-value={'3'} className={`option ${searchfield?.budget == '3' ? 'selected' : ''}`} onClick={() => { onTodoChange('budget', '3') }}>20-40 K</li>
                                  <li data-value={'4'} className={`option ${searchfield?.budget == '4' ? 'selected' : ''}`} onClick={() => { onTodoChange('budget', '4') }}>above 40 K</li>
                                </ul>
                              </div>
                            </div>
                          </> : ""}
                        </div>


                      </div>
                    </div>

                    <button type="submit" className="tf-btn primary" onClick={(e) => { onSearchbttnClick(e) }}>Find Properties</button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
      </div>
      </div> :
      <section className="flat-filter-search-v2">
        <div className="flat-tab flat-tab-form">
          <ul className="nav-tab-form style-3" role="tablist">
            <li className="nav-tab-item" role="presentation">
              <a href="javascript:void(0)" className={`nav-link-item ${type && slug == 'rent' ? 'active' : ''}`} data-bs-toggle="tab" onClick={() => { onTodoChange('property_for', '2') }}>For Rent</a>
            </li>
            <li className="nav-tab-item" role="presentation">
              <a href="javascript:void(0)" className={`nav-link-item ${type && slug == 'buy' ? 'active' : !type ? 'active' : ''}`} data-bs-toggle="tab" onClick={() => { onTodoChange('property_for', '1') }}>For Sale</a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade active show" role="tabpanel">
              <div className="form-sl">
                <form method="post">
                  <div className="wd-find-select">
                    <div className="inner-group">
                      <div className="form-group-1 search-form form-style">
                        <label>Keyword</label>
                        <input type="text" className="form-control" placeholder="Search Keyword." name="seach_keyword" title="Search for" onChange={(e) => { onTodoChange('search_keyword', e.target.value) }} />
                      </div>
                      <div className="form-group-2 form-style">
                        <label>Location</label>
                        <div className="group-ip">
                          <input type="text" className="form-control" placeholder="Search Location" title="Search for" value={searchquery} onChange={(e) => { onChangeCitySerachInput(e.target.value) }} />
                          <a href="javascript:void(0)" className="icon icon-location"></a>
                          {citiesData && citiesData.length > 0 ? <>
                            <ul className='searchlocationlist' style={{ width: '100%' }}>
                              {citiesData.map((item, index) => {
                                return (<>
                                  <li key={index} onClick={(e) => { handleCitySelect(e, item) }}>{item.cities_name}</li>
                                </>)
                              })}
                            </ul>
                          </> : ''}
                        </div>
                      </div>
                      {contextValues.propertyType && contextValues.propertyType.length > 0 ? <>
                        <div className="form-group-3 form-style">
                          <label>Type</label>
                          <div className="group-select">
                            <div className="nice-select" tabindex="0"><span className="current">{selectedSlug.name ? selectedSlug.name : 'Select Type'}</span>
                              <ul className="list">
                                <li data-value className={`option ${selectedSlug.name == '' ? 'selected' : ''}`} onClick={() => { onTodoChange('property_type', '') }}>Select Type</li>
                                {contextValues.propertyType.map((item, index) => {
                                  return (<>
                                    <li data-value={item.types_id} className={`option ${selectedSlug.id == item.types_id ? 'selected' : ''}`} key={index} onClick={() => { onTodoChange('property_type', item.types_id) }}>{item.types_name}</li>
                                  </>)
                                })}

                              </ul>
                            </div>
                          </div>
                        </div>
                      </> : ''}
                      <div className="form-group-4 box-filter">
                        <a className="filter-advanced pull-right" href="javascript:void(0)">
                          <span className="icon icon-faders"></span>
                          <span className="text-1">Advanced</span>
                        </a>
                      </div>
                    </div>
                    <button type="submit" className="tf-btn primary" onClick={(e) => { onSearchbttnClick(e) }}>Find Properties</button>
                  </div>
                  <div className="wd-search-form">
                    <div className="grid-1 group-box">
                      <div className="group-select grid-3">
                        <div className="box-select">
                          <label className="title-select text-variant-1">Unit</label>
                          <div className="nice-select" tabindex="0"><span className="current">Select no of rooms</span>
                            <ul className="list">
                              <li data-value className="option selected" onClick={() => { onTodoChange('no_of_bedrooms', '') }}>Select no of rooms</li>
                              {[...Array(10)].map((_, index) => {
                                return (<>
                                  <li data-value={index + 1} className="option" onClick={() => { onTodoChange('no_of_bedrooms', index + 1) }}>{index + 1}</li>

                                </>)
                              })}
                            </ul>
                          </div>
                        </div>
                        <div className="box-select">
                          <label className="title-select text-variant-1">Property Status</label>
                          <div className="nice-select" tabindex="0"><span className="current">Select property status</span>
                            <ul className="list">
                              <li data-value className={`option selected`} onClick={() => { onTodoChange('property_availabilty_status', '') }}>Select Property Status</li>

                              <li data-value={'1'} className={`option ${searchfield?.property_availabilty_status == '1' ? 'selected' : ''}`} onClick={() => { onTodoChange('property_availabilty_status', '1') }}>Ready to shift</li>
                              <li data-value={'2'} className={`option ${searchfield?.property_availabilty_status == '2' ? 'selected' : ''}`} onClick={() => { onTodoChange('property_availabilty_status', '2') }}>Under Construction</li>
                            </ul>
                          </div>
                        </div>

                        {searchfield.property_for == '1' ? <>
                          <div className="box-select">
                            <label className="title-select text-variant-1">Budget</label>
                            <div className="nice-select" tabindex="0"><span className="current">Select budget</span>
                              <ul className="list">
                                <li data-value className={`option ${searchfield?.budget == '' ? 'selected' : ''}`} onClick={() => { onTodoChange('budget', '') }}>Select budget</li>
                                <li data-value={'1'} className={`option ${searchfield?.budget == '1' ? 'selected' : ''}`} onClick={() => { onTodoChange('budget', '1') }}>0-40 lakh</li>
                                <li data-value={'2'} className={`option ${searchfield?.budget == '2' ? 'selected' : ''}`} onClick={() => { onTodoChange('budget', '2') }}>40-80 lakh</li>
                                <li data-value={'3'} className={`option ${searchfield?.budget == '3' ? 'selected' : ''}`} onClick={() => { onTodoChange('budget', '3') }}>Above 80 lakh</li>
                              </ul>
                            </div>
                          </div>


                        </> : searchfield.property_for == '2' ? <>
                          <div className="box-select">
                            <label className="title-select text-variant-1">Budget</label>
                            <div className="nice-select" tabindex="0"><span className="current">Select budget</span>
                              <ul className="list">
                                <li data-value className={`option ${searchfield?.budget == '' ? 'selected' : ''}`} onClick={() => { onTodoChange('budget', '') }}>Select budget</li>
                                <li data-value={'1'} className={`option ${searchfield?.budget == '1' ? 'selected' : ''}`} onClick={() => { onTodoChange('budget', '1') }}>Below 10 K</li>
                                <li data-value={'2'} className={`option ${searchfield?.budget == '2' ? 'selected' : ''}`} onClick={() => { onTodoChange('budget', '2') }}>10-20 K</li>
                                <li data-value={'3'} className={`option ${searchfield?.budget == '3' ? 'selected' : ''}`} onClick={() => { onTodoChange('budget', '3') }}>20-40 K</li>
                                <li data-value={'4'} className={`option ${searchfield?.budget == '4' ? 'selected' : ''}`} onClick={() => { onTodoChange('budget', '4') }}>above 40 K</li>
                              </ul>
                            </div>
                          </div>
                        </> : ""}
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>


    }
    {/* Listing Code Start */}
    <section className="wrapper-layout layout-2" data-wow-delay=".4s" data-wow-duration="2000ms">
      <div className="wrap-left">
        <div className="box-title-listing style-1">
          <h5>Property Listing</h5>
          <div className="box-filter-tab">
            <div className="filterbox" ><a href="#modalSearch" data-bs-toggle="modal">Filter <i class="ri-filter-line"></i></a></div>
            <div className="nice-select list-sort" tabindex="0"><span className="current">Sort by (Default)</span>
              <ul className="list">
                <li data-value="default" className="option selected" onClick={() => { setsort(''); sethasMore(false); getPropertyList('', isNewSearch); }}>Sort by (Default)</li>
                <li data-value="new" className="option" onClick={() => { setsort('new'); sethasMore(false); getPropertyList('new', isNewSearch); }}>Newest</li>
                <li data-value="old" className="option" onClick={() => { setsort('old'); sethasMore(false); getPropertyList('old', isNewSearch); }}>Oldest</li>
              </ul>
            </div>
          </div>
        </div>
        <InfiniteScroll
          dataLength={propertyList.length}
          next={isNewSearch ? getsearchPropertyList : getPropertyList}
          hasMore={hasMore}
          // endMessage={<p className="text-center">No more data to load.</p>}
          style={{ overflow: "hidden !important" }}
        >

          {isLoading ? <>
            <div className="row">
              {[...Array(8)].map((__, index) => {
                return (<>

                  <div className="col-xl-3 col-md-6" key={index}>
                    <div className="homeya-box md" >
                      <div className="archive-top">
                        <div className="images-group">
                          <div className="images-style" >
                            <Skeleton height={'250px'}></Skeleton>
                          </div>
                          <div className="top">
                            <ul className="d-flex gap-8 flex-column">
                              <li className=""><Skeleton width={'50px'}></Skeleton></li>

                            </ul>
                            <ul className="d-flex gap-4">
                              <li className="box-icon w-32" >
                                <a href="javascript:void(0)" data-bs-toggle="modal"> <Skeleton></Skeleton></a>

                              </li>
                              <li className="box-icon w-32">
                                <Skeleton></Skeleton>
                              </li>
                            </ul>
                          </div>
                        </div>

                      </div>
                      <div className="archive-bottom d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="h7 fw-7"><Skeleton width={'80px'}></Skeleton> </div>
                          <div className="h7 fw-7 ms-2"><Skeleton width={'80px'}></Skeleton> </div>
                          <div className="h7 fw-7 ms-2"><Skeleton width={'80px'}></Skeleton> </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>)
              })}
            </div>
          </> : <>
            {propertyList.length > 0 ? <>
              <div className="row">
                {propertyList.map((item, index) => {
                  return (<>
                    <div className="col-xl-3 col-md-6" key={index}>
                      <PropertyBox propertydetail={item} imgUrl={imgUrl}></PropertyBox>
                    </div>
                  </>)
                })}


              </div>
            </> : <div className="row">

              <div className="col-12">
                <div className="d-flex justify-content-center">
                  <img src='/images/emptystate.gif' style={{ width: '500px' }}></img>
                </div>
                <h6 className="text-center">{isNewSearch ? 'No result Found' : 'No Property Found'}</h6>
              </div>
            </div>}
          </>}
        </InfiniteScroll>
      </div>


    </section>
    <Footer></Footer>
  </>)
}

export default PropertyList