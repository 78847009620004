import React, { useRef, useState, useEffect, useContext } from 'react'
import { ApiService } from '../../Components/Services/apiservices'
import DataContext from '../../Components/Element/context'


function HomeSlider() {
    const contextValues = useContext(DataContext)
    const didMountRef = useRef(true)
    const [citiesData, setcitiesData] = useState([])
    const [searchquery, setsearchquery] = useState('');
    const [searchfield, setsearchfield] = useState({
        property_for: '1',
        search_keyword: '',
        location: {id:'' , name:''},
        property_type: '',
        no_of_bedrooms: '',
        property_availabilty_status: '',
        budget: '',
        
    })


    const onTodoChange = (name, value ) => {

        setsearchfield((prevState) => ({
            ...prevState,
            [name]: value,
        }));

    };

    const onChangeSerachInput = (value) => {
        setsearchquery(value);
        if (value.length > 1) {
            const dataString = { cities_name: value }
            ApiService.postData('getSearchedCityData', dataString).then((res) => {
                if (res.status === 'success') {
                    setcitiesData(res.data);
                }
            }).catch((error) => {
                console.error("Error fetching city data:", error);
            });
        } else {
            setcitiesData([]);
        }
    }
    const handleCitySelect = (e, selectedcity) => {
        e.preventDefault()
        setsearchfield((prevState) => ({
            ...prevState,
            ['location']: {id: selectedcity.cities_id , name:selectedcity.cities_name},
        }));
        setsearchquery(selectedcity.cities_name)
        setcitiesData([])

    }

    const handleClick = (route) => {
        localStorage.removeItem('type')
        window.location.href = route
    };


    const onSearchbttnClick = (e) => {
        e.preventDefault()
        localStorage.setItem('searchpropertyfield', JSON.stringify(searchfield));
        window.location.href = '/search-result';
    };

    return (
        <section className="flat-slider home-2">
            <div className="container relative">
                <div className="row">
                    <div className="col-xl-10">
                        <div className="slider-content">
                            <div className="heading">
                                <h2 className="title  fadeIn animationtext clip" data-wow-delay=".2s" data-wow-duration="2000ms">Find A Home That Fits
                                    <br />
                                    <span className="tf-text s1 cd-words-wrapper">
                                        <span className="item-text is-visible">Perfectly</span>
                                        <span className="item-text is-hidden">Dream Home</span>
                                    </span>
                                </h2>
                                <p className="subtitle body-1  fadeIn" data-wow-delay=".8s" data-wow-duration="2000ms">We are a real estate agency that will help you find the best residence you dream of.</p>
                            </div>
                            <div className="flat-tab flat-tab-form">
                                <ul className="nav-tab-form style-2" role="tablist">
                                    <li className="nav-tab-item" role="presentation">
                                        <a href="javascript:void(0)" className="nav-link-item active" data-bs-toggle="tab" onClick={() => { onTodoChange('property_for', '1') }}>For Sale</a>
                                    </li>
                                    <li className="nav-tab-item" role="presentation">
                                        <a href="javascript:void(0)" className="nav-link-item" data-bs-toggle="tab" onClick={() => { onTodoChange('property_for', '2') }}>For Rent</a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane fade active show" role="tabpanel">
                                        <div className="form-sl">
                                            <form method="post">
                                                <div className="wd-find-select style-2 shadow-st no-left-round">
                                                    <div className="inner-group">
                                                        <div className="form-group-1 search-form form-style">
                                                            <label>Keyword</label>
                                                            <input type="text" className="form-control" placeholder="Search Keyword." name="seach_keyword" title="Search for" onChange={(e) => { onTodoChange('search_keyword', e.target.value) }} />
                                                        </div>
                                                        <div className="form-group-2 form-style">
                                                            <label>Location</label>
                                                            <div className="group-ip">
                                                                <input type="text" className="form-control" placeholder="Search Location" title="Search for" value={searchquery} onChange={(e) => { onChangeSerachInput(e.target.value) }} />
                                                                <a href="javascript:void(0)" className="icon icon-location"></a>
                                                            </div>
                                                            {citiesData && citiesData.length > 0 ? <>
                                                                <ul className='searchlocationlist'>
                                                                    {citiesData.map((item, index) => {
                                                                        return (<>
                                                                            <li key={index} onClick={(e) => { handleCitySelect(e, item) }}>{item.cities_name}</li>
                                                                        </>)
                                                                    })}

                                                                </ul>


                                                            </> : ''}
                                                        </div>
                                                            <div className="form-group-3 form-style">
                                                                <label>Type</label>
                                                                <div className="group-select">
                                                                    <div className="nice-select" tabindex="0"><span className="current">Select Type</span>
                                                                    {contextValues.propertyType && contextValues.propertyType.length > 0 ? <>
                                                                        <ul className="list">
                                                                            <li data-value className="option selected" onClick={() => { onTodoChange('property_type', '') }}>Select Type</li>
                                                                            {contextValues.propertyType.map((item, index) => {
                                                                                return (<>
                                                                                    <li data-value={item.types_id} className="option" key={index} onClick={() => { onTodoChange('property_type', item.types_id) }}>{item.types_name}</li>
                                                                                </>)
                                                                            })}

                                                                        </ul>
                                                                        </> : ''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                       

                                                        <div className="form-group-4 box-filter">
                                                            <a className="filter-advanced pull-right">
                                                                <span className="icon icon-faders"></span>
                                                                <span className="text-advanced">Advanced</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <button className="tf-btn primary" onClick={(e) => { onSearchbttnClick(e) }}>Search</button>
                                                </div>
                                                <div className="wd-search-form">
                                                  
                                                    <div className="grid-1 group-box">
                                                        <div className="group-select grid-3">
                                                            <div className="box-select">
                                                                <label className="title-select text-variant-1">Unit</label>
                                                                <div className="nice-select" tabindex="0"><span className="current">Select no of rooms</span>
                                                                    <ul className="list">
                                                                        <li data-value className="option selected" onClick={() => { onTodoChange('no_of_bedrooms', '') }}>Select no of rooms</li>
                                                                        {[...Array(10)].map((_, index) => {
                                                                            return (<>
                                                                                <li data-value={index + 1} className="option" onClick={() => { onTodoChange('no_of_bedrooms', index + 1) }}>{index + 1}</li>

                                                                            </>)
                                                                        })}


                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="box-select">
                                                                <label className="title-select text-variant-1">Property Status</label>
                                                                <div className="nice-select" tabindex="0"><span className="current">Select property status</span>
                                                                    <ul className="list">
                                                                        <li data-value className="option selected" onClick={() => { onTodoChange('property_availabilty_status', '') }}>Select Property Status</li>
                                                                   
                                                                                <li data-value={'1'} className="option" onClick={() => { onTodoChange('property_availabilty_status', '1') }}>Ready to shift</li>
                                                                                <li data-value={'2'} className="option" onClick={() => { onTodoChange('property_availabilty_status', '2') }}>Under Construction</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            {searchfield.property_for=='1' ?<>
                                                                <div className="box-select">
                                                                <label className="title-select text-variant-1">Budget</label>
                                                                <div className="nice-select" tabindex="0"><span className="current">Select budget</span>
                                                                    <ul className="list">
                                                                        <li data-value className="option selected" onClick={() => { onTodoChange('budget', '') }}>Select budget</li>
                                                                        <li data-value={'1'} className="option" onClick={() => { onTodoChange('budget', '1') }}>0-40 lakh</li>
                                                                        <li data-value={'2'} className="option" onClick={() => { onTodoChange('budget', '2') }}>40-80 lakh</li>
                                                                        <li data-value={'3'} className="option" onClick={() => { onTodoChange('budget', '3') }}>Above 80 lakh</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                            </>:searchfield.property_for=='2'?<>
                                                            <div className="box-select">
                                                                <label className="title-select text-variant-1">Budget</label>
                                                                <div className="nice-select" tabindex="0"><span className="current">Select budget</span>
                                                                    <ul className="list">
                                                                        <li data-value className="option selected" onClick={() => { onTodoChange('budget', '') }}>Select budget</li>
                                                                        <li data-value={'1'} className="option" onClick={() => { onTodoChange('budget', '1') }}>Below 10 K</li>
                                                                        <li data-value={'2'} className="option" onClick={() => { onTodoChange('budget', '2') }}>10-20 K</li>
                                                                        <li data-value={'3'} className="option" onClick={() => { onTodoChange('budget', '3') }}>20-40 K</li>
                                                                        <li data-value={'4'} className="option" onClick={() => { onTodoChange('budget', '4') }}>above 40 K</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            </>:""}
                                                        </div>
                                                       
                                                    </div>

                                                </div>
                                            </form>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            {contextValues.propertyType && contextValues.propertyType.length > 0 ? (
                                <>
                                    <div className="wrap-search-link">
                                        <p className="body-1" style={{color:'#ddd'}}>What are you looking for:</p>
                                        <ul>
                                        {contextValues.propertyType.slice(0, 4).map((item, index) => {
                                            return (
                                            <li
                                            onClick={() => {
                                                handleClick(`/property/${item.type_slug}`);
                                            }}
                                            className={`${index === 0 ? 'active' : ''}`}
                                            > {item.types_name}</li>
                                        );
                                        })}
                                        </ul>
                                       </div>
                                </>
                            ) : (
                                ''
                            )}



                        </div>
                    </div>


                </div>

            </div>
            <div className="img-banner-left">
                <img src="/images/homebg.jpg" alt="img" />
            </div>

        </section>
    )
}

export default HomeSlider