import { useNavigate } from "react-router-dom"
import { ApiService } from "../../Components/Services/apiservices"
import React from 'react';
import { useState, useEffect, useRef } from "react"


const FeaturedFaq = () => {
    const [featuredfaq, setfeaturedFaq] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const didMountRef = useRef(true)
    const navigate = useNavigate()

    useEffect(() => {
        if (didMountRef.current) {
            getfeaturedFaq()
        }
        didMountRef.current = false
    }, [])

    const getfeaturedFaq = () => {
        setIsLoading(true)
        ApiService.fetchData('featuredFaq').then((res) => {
            if (res.status === 'success') {
                setfeaturedFaq(res.faqData)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500)
            } else {
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }

    return (
        <>
            {featuredfaq && featuredfaq.length > 0 ? (
                <section className="flat-section flat-recommended pt-0  fadeInUpSmall" data-wow-delay=".4s" data-wow-duration="2000ms">
                    <div className="container">
                        <div className="box-title style-2 text-center">
                            <div className="text-subtitle text-primary mt-3">Faq</div>
                            <h4 className="mt-4">Frequently Asked Questions</h4>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="tf-faq">
                                    <ul className="box-faq" id="wrapper-faq">
                                        {featuredfaq.map((item, index) => {
                                            const collapseId = `accordion-faq-${index}`;
                                            const isFirstItem = index === 0;
                                            return (
                                                <li className="faq-item" key={index}>
                                                    <a
                                                        href="javascript:void(0)"
                                                        className={`faq-header ${isFirstItem ? '' : 'collapsed'}`}
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#${collapseId}`}
                                                        aria-expanded={isFirstItem ? "true" : "false"}
                                                        aria-controls={collapseId}
                                                    >
                                                        {item.faq_title}
                                                    </a>
                                                    <div
                                                        id={collapseId}
                                                        className={`collapse ${isFirstItem ? 'show' : ''}`}
                                                        data-bs-parent="#wrapper-faq"
                                                    >
                                                        <p className="faq-body" dangerouslySetInnerHTML={{ __html: item.faq_description }}></p>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : null}
        </>
    )
}

export default FeaturedFaq
