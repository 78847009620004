import { useState } from "react";
import { validEmail, validNumber } from "../../../Components/Element/Regex";
import { useParams } from "react-router-dom";
import { ApiService } from "../../../Components/Services/apiservices";


const ProjectEBrochure = ({projectDetail, brochureurl}) => {
    const { slug } = useParams()
    const [errorMessage, setErrorMessage] = useState("");    
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const [enquiryUserdetail, setenquiryUserdetail] = useState({
        enquired_user_name: "",
        enquired_user_email: "",
        enquired_message: "",
    })

    const onInputChange = (e) => {
        setErrorMessage("");
        setSuccessMessage("");
        const { name, value } = e.target;
      
        setenquiryUserdetail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value !== "") {
            e.target.style.border = "";
        }
    };

    const submitEnquire = (e) => {
        e.preventDefault()
        let counter = 0;
        const myElements = document.getElementsByClassName("projectenquirerequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === "") {

                myElements[i].style.border = "1px solid red";
                counter++;
            } else {
                myElements[i].style.border = "";
            }
        }

        if (counter == 0) {

            if (!validEmail.test(enquiryUserdetail.enquired_user_email)) {
                setErrorMessage("Please Enter Valid Email");
                return false;
            }
            setisLoading(true)
            const dataString = {
                enquiry_name: enquiryUserdetail.enquired_user_name,
                enquiry_email: enquiryUserdetail.enquired_user_email,
                enquiry_project_id: slug,
                enquired_message: "E-Brochure Download"
            };
            
            ApiService.postData("projectenquiry", dataString).then((res) => {
                if (res.status == "success") {
                    setSuccessMessage("Thank you! Brochure is being downloading!");
                    document.getElementById("downlaodnow").click()
                    document.getElementById("closeModel").click();
                    setenquiryUserdetail({enquired_user_name: "",
                        enquired_user_email: "",})
                    setTimeout(() => {
                        setSuccessMessage("")
                    }, 5000);
                    setisLoading(false)
                } else {
                    setErrorMessage(res.message);
                    setisLoading(false)
                }
            }).catch(() => {
                setisLoading(false)
            });
        }
    };


    return <>
    {projectDetail?.project_e_brochure && projectDetail?.project_e_brochure.length > 0 && (
    <section className="flat-section mt-0 flat-property-detail">
            <a href="{`${brochureurl}${projectDetail?.project_e_brochure}`}" download={`Brochure.pdf`} id="downlaodnow" className="d-none">Download</a>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="single-property-element single-property-attachments">
                            <div className="row">
                                <div className="col-sm-6">
                                    <a href="#modalContact" data-bs-toggle="modal" className="attachments-item">
                                        <div className="box-icon w-60">
                                            <img src="/images/home/file-1.png" alt="file" />
                                        </div>
                                        <span>Brochure.pdf</span>
                                        <i className="icon icon-download"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modalContact" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    
                        <div className="row">
                            <div className="col-lg-12">
                                <form action="#" className="box-contact-v2 flat-account">
                                <span className="close-modal icon-close2" id="closeModel" data-bs-dismiss="modal"></span>
                                    {errorMessage && (
                                        <div className="alert alert-danger" role="alert">
                                            {errorMessage}
                                        </div>
                                    )}
                                    {successMessage && (
                                        <div className="alert alert-success" role="alert">
                                            {successMessage}
                                        </div>
                                    )}
                                    <div className="box">
                                        <label htmlFor="name" className="label">Name:</label>
                                        <input type="text" className="form-control projectenquirerequired" value={enquiryUserdetail?.enquired_user_name} name="enquired_user_name" onChange={(e) => { onInputChange(e) }} fdprocessedid="fn8tkp" />
                                    </div>
                                    <div className="box">
                                        <label htmlFor="email" className="label">Email:</label>
                                        <input type="email" className="form-control projectenquirerequired" name="enquired_user_email" value={enquiryUserdetail?.enquired_user_email} onChange={(e) => { onInputChange(e) }} fdprocessedid="igf8ik" />
                                    </div>
                                    <div className="box">
                                        <button className="tf-btn primary" fdprocessedid="hb4e7" onClick={(e) => { submitEnquire(e) }}>
                                        {isLoading ? (
                                                            <img src="/images/loder01.gif" width="60px" height="11px" />
                                                        ) : (
                                                            "Submit"
                                                        )}
                                            
                                            </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>)}
    </>
}

export default ProjectEBrochure;