import { useContext } from "react"
import DataContext from "../Element/context"
import { BrowserView, MobileView } from "react-device-detect"

const HeaderTop = () => {
    const contextValues = useContext(DataContext)

    return (<>
        <BrowserView>
            <div className="topheader">
                <div className="container-fluid">
                    <div className="topheader-left">
                        <ul className="leftHeaderlist">
                            {contextValues.settingData.admin_support_mobile ? <li><a href={`tel:${contextValues.settingData.admin_support_mobile}`}><i className="ri-phone-line ri-lg"></i> {contextValues.settingData.admin_support_mobile}</a></li> : ''}
                            {contextValues.settingData.admin_support_email ? <li><a href={`mailto:${contextValues.settingData.admin_support_email}`}><i className="ri-mail-line ri-lg"></i> {contextValues.settingData.admin_support_email}</a></li> : ''}
                        </ul>
                    </div>
                    <div className="topheader-right justify-content-end">
                        <ul className="rightHeaderlist">
                            {contextValues.settingData.facebook_url ? <li><a href={contextValues.settingData.facebook_url} target="new"><i className="ri-facebook-fill"></i></a></li> : ''}
                            {contextValues.settingData.linkedin_url ? <li><a href={contextValues.settingData.linkedin_url} target="new"><i className="ri-linkedin-fill"></i></a></li> : ''}
                            {contextValues.settingData.youtube_url && (
                                <li><a href={contextValues.settingData.youtube_url} target='_blank' ><i class="ri-youtube-line"></i></a></li>
                            )}
                            {contextValues.settingData.instagram_url ? <li> <a href={contextValues.settingData.instagram_url} target="new"> <i className="ri-instagram-fill"></i></a></li> : ''}
                        </ul>
                    </div>
                </div>
            </div>

        </BrowserView>
        <MobileView>
            <div className="topheader">
                <div className="container-fluid">
                    <div className="topheader-left">
                        <ul className="leftHeaderlist">
                            {contextValues.settingData.admin_support_mobile ? <li><a href={`tel:${contextValues.settingData.admin_support_mobile}`}><i className="ri-phone-line ri-lg"></i> {contextValues.settingData.admin_support_mobile}</a></li> : ''}
                            {contextValues.settingData.admin_support_email ? <li><a href={`mailto:${contextValues.settingData.admin_support_email}`}><i className="ri-mail-line ri-lg"></i> {contextValues.settingData.admin_support_email}</a></li> : ''}
                        </ul>
                    </div>
                    {/* <div className="topheader-right justify-content-end">
                    <ul className="rightHeaderlist">
                        {contextValues.settingData.facebook_url ? <li><a href={contextValues.settingData.facebook_url} target="new"><i className="ri-facebook-fill"></i></a></li> : ''}
                        {contextValues.settingData.linkedin_url ? <li><a href={contextValues.settingData.linkedin_url} target="new"><i className="ri-linkedin-fill"></i></a></li> : ''}
                        {contextValues.settingData.twitter_url ? <li><a href={contextValues.settingData.twitter_url} target="new"><i className="ri-twitter-x-line"></i></a></li> : ''}
                        {contextValues.settingData.instagram_url ? <li> <a href={contextValues.settingData.instagram_url} target="new"> <i className="ri-instagram-line"></i></a></li> : ''}
                    </ul>
                </div> */}
                </div>
            </div>
        </MobileView>



    </>)
}


export default HeaderTop