import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { ApiService } from "../../Components/Services/apiservices";
import InfiniteScroll from "react-infinite-scroll-component";


const Favourites=()=>{
    const didMountRef = useRef(true)  
    const navigate = useNavigate()
    const [pageno, setpageno] = useState(1)
    const [myproperties, setmyproperties] = useState([])
    const [currentPage, setcurrentPage] = useState('')
    const [property_img_url, setproperty_img_url] = useState(null);
    const [hasMore, setHasmore] = useState(false)
    useEffect(() => {
      if (didMountRef.current) {
  
        getMyPropertyData()
      }
      didMountRef.current = false
    })

    const addtofav = (property) => {    
        if (window.confirm("Are you sure?")) {    
            const dataString = {
                property_id: property.property_id,
            };
            ApiService.postData("addtofav", dataString).then((res) => {
                if (res.data.status == "success") {
                    let deleteRow = document.getElementsByClassName('row_' + property.property_id)[0];
                    if(deleteRow.parentNode)
                        deleteRow.parentNode.removeChild(deleteRow);
                } else {
                    
                }
            }).catch(() => {
                
            });
        }
    };
  

    const getMyPropertyData = (isNewSearch = false) => {
        let pageNumber = pageno;
        if (isNewSearch) {
            pageNumber = 1;
            setpageno(1)
            setmyproperties([]);
        }
    
        ApiService.postData('favpropertylist?page=' + pageNumber).then((res) => {
            if (res.status === 'success') {
                setproperty_img_url(res.property_img_url)
                if (res.favpropertyList.last_page > pageNumber) {
                    setHasmore(true);
                    setpageno(pageNumber + 1);
                } else {
                    setHasmore(false);
                }
                if (isNewSearch) {
                    setmyproperties(res.favpropertyList.data); 
                  }
            
                else if (res.favpropertyList.data.length > 0) {
                    setcurrentPage(res.favpropertyList.current_page);
                    const apires = res.favpropertyList.data;
                    const mergeData = isNewSearch ? apires : [...myproperties, ...apires];
                    setmyproperties(mergeData);
                }
            } else if (res.status === 'session_expired') {
                localStorage.removeItem('USER_TOKEN');
                navigate('/');
            }
        }).catch((error) => {
           
        });
    };


    return(<>
    <div className="layout-wrap">
        <Header></Header>
        <div className="main-content">
            <div className="main-content-inner">
                <div className="wrapper-content row">
                    <div className="col-xl-12">
                        <div className="widget-box-2 wd-listing">
                            <h6 className="title">My Favorite Properties</h6>
                            <div className="wrap-table">
                                <div className="table-responsive">
                                    <InfiniteScroll
                                    dataLength={myproperties.length}
                                    next={getMyPropertyData}
                                    hasMore={hasMore}
                                    style={{ overflow: "hidden !important" }}
                                    >
                                        
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Date Published</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                
                                                {myproperties.length > 0 ? (
                                                    <>
                                                    {myproperties.length > 0 && (
                                                            myproperties.map((data, key) => {
                                                                
                                                                let date1 =new Date(data.created_at).toDateString()
                
                                                                return <tr className={"row_"  + data.property_id}>
                                                                            <td>
                                                                                <div className="listing-box">
                                                                                    <div className="images">
                                                                                        <img src={property_img_url + data.property_image} alt="images" />
                                                                                    </div>
                                                                                    <div className="content">
                                                                                        <div className="title"><a href={"property/"+data.property_slug + "/" + data.property_url_id} className="link">{data.property_name}</a> </div>
                                                                                        <div className="text-date">{data.property_address}</div>
                                                                                        <div className="text-1 fw-7">{"₹"+data.property_expected_price}</div> 
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <span>{date1}</span>
                                                                            </td>
                                                                            <td>
                                                                                <ul className="list-action">
                                                                                    <li><a className="remove-file item" onClick={(e) => addtofav(data) }><i className="icon icon-trash"></i>Remove</a></li>
                                                                                </ul>
                                                                            </td>
                                                                        </tr>
                                                            })
                                                        )}
                                                    </>
                                                ):(
                                                    <><tr>
                                                        <td colSpan={3}>No Favorites Found!</td>    
                                                    </tr></>
                                                )}
                                                
                                            </tbody>
                                        </table>


                                    </InfiniteScroll>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
    </>)
}

export default Favourites