import Footer from "../../Components/Footer"
import Header from "../../Components/Header"

const Pricing=()=>{
    return(<>
    <Header></Header>
    <section className="flat-title-page style-2">
    <div className="container">
        <ul className="breadcrumb">
            <li><a href="index.html">Home</a></li>
            <li>/ Pages</li>
            <li>/ Our Pricing</li>
        </ul>
        <h2 className="text-center">Pricing Plans</h2>
    </div>
</section>

<section className="flat-section flat-pricing">
    <div className="container">
        <div className="box-title text-center">
            <div className="text-subtitle text-primary">Pricing</div>
            <h4 className="mt-4">Our Subscription</h4>
        </div>
        <div className="row">
            <div className="box col-lg-4 col-md-6">
                <div className="box-pricing">
                    <div className="price d-flex align-items-end">
                        <h4>$20</h4>
                        <span className="body-2 text-variant-1">/month</span>
                    </div>
                    <div className="box-title-price">
                        <h6 className="title">Intro</h6>
                        <p className="desc">Join us every month for a very reasonable price</p>
                    </div>
                    <ul className="list-price">
                        <li className="item">
                            <span className="check-icon icon-tick"></span>
                            Transportation for you
                        </li>
                        <li className="item">
                            <span className="check-icon icon-tick"></span>

                            A hotel or homestay.
                        </li>
                        <li className="item">
                            <span className="check-icon icon-tick"></span>

                            Free meals and drinks.
                        </li>
                        <li className="item">
                            <span className="check-icon icon-tick disable"></span>

                            24/7 Support
                        </li>
                        <li className="item">
                            <span className="check-icon icon-tick disable"></span>

                            Unlimited users
                        </li>
                    </ul>
                    <a href="/contact-us" className="tf-btn">Choose The Package</a>
                </div>
            </div>
            <div className="box col-lg-4 col-md-6">
                <div className="box-pricing">
                    <div className="price d-flex align-items-end">
                        <h4>$20</h4>
                        <span className="body-2 text-variant-1">/month</span>
                    </div>
                    <div className="box-title-price">
                        <h6 className="title">Intro</h6>
                        <p className="desc">Join us every month for a very reasonable price</p>
                    </div>
                    <ul className="list-price">
                        <li className="item">
                            <span className="check-icon icon-tick"></span>
                            Transportation for you
                        </li>
                        <li className="item">
                            <span className="check-icon icon-tick"></span>

                            A hotel or homestay.
                        </li>
                        <li className="item">
                            <span className="check-icon icon-tick"></span>

                            Free meals and drinks.
                        </li>
                        <li className="item">
                            <span className="check-icon icon-tick disable"></span>

                            24/7 Support
                        </li>
                        <li className="item">
                            <span className="check-icon icon-tick disable"></span>

                            Unlimited users
                        </li>
                    </ul>
                    <a href="/contact-us" className="tf-btn">Choose The Package</a>
                </div>
            </div>
            <div className="box col-lg-4 col-md-6">
                <div className="box-pricing active">
                    <span className="tag">MOST POPULAR</span>
                    <div className="price d-flex align-items-end">
                        <h4>$20</h4>
                        <span className="body-2 text-variant-1">/month</span>
                    </div>
                    <div className="box-title-price">
                        <h6 className="title">Intro</h6>
                        <p className="desc">Join us every month for a very reasonable price</p>
                    </div>
                    <ul className="list-price">
                        <li className="item">
                            <span className="check-icon icon-tick"></span>
                            Transportation for you
                        </li>
                        <li className="item">
                            <span className="check-icon icon-tick"></span>

                            A hotel or homestay.
                        </li>
                        <li className="item">
                            <span className="check-icon icon-tick"></span>

                            Free meals and drinks.
                        </li>
                        <li className="item">
                            <span className="check-icon icon-tick disable"></span>

                            24/7 Support
                        </li>
                        <li className="item">
                            <span className="check-icon icon-tick disable"></span>

                            Unlimited users
                        </li>
                    </ul>
                    <a href="/contact-us" className="tf-btn">Choose The Package</a>
                </div>
            </div>
           
        </div>
    </div>
</section>



    <Footer></Footer>
    </>)
}

export default Pricing