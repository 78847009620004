import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const User_Token = localStorage.getItem('USER_TOKEN');
  
  if (!User_Token) {
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectedRoute;
