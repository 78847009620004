
const ProjectOverview = ({ projectDetail }) => {

    return <div className="single-property-element single-property-overview"><ul className="info-box">
         {projectDetail?.project_rera && projectDetail?.project_rera!='' && (
            <li className="item">
            <a href="#" className="box-icon w-52"><i className="icon icon-house-line"></i></a>
            <div className="content">
           <span className="label">Rera Id:</span>
            <span>{projectDetail?.project_rera}</span>
             </div>
            </li>
             )}

             
          {projectDetail?.project_total_area && projectDetail?.project_total_area!='' && (
            <li className="item">
            <a href="#" className="box-icon w-52"><i className="icon icon-crop"></i></a>
            <div className="content">
           <span className="label">Project Area:</span>
            <span>{projectDetail?.project_total_area}</span>
             </div>
            </li>
             )}

              {projectDetail?.project_area_start && projectDetail?.project_area_start!='' && projectDetail?.project_area_end && projectDetail?.project_area_end!='' && (
            <li className="item">
            <a href="#" className="box-icon w-52"><i className="icon icon-ruler"></i></a>
            <div className="content">
           <span className="label">Size:</span>
            <span>{projectDetail?.project_area_start} - {projectDetail?.project_area_end} Sq.ft</span>
             </div>
            </li>
             )}
             {projectDetail?.project_price_start && projectDetail?.project_price_start!='' && projectDetail?.project_price_end && projectDetail?.project_price_end!='' && (
            <li className="item">
            <a href="#" className="box-icon w-52"><i className="icon ri-money-rupee-circle-line"></i></a>
            <div className="content">
           <span className="label">Price Range:</span>
            <span>₹{projectDetail?.project_price_start} - ₹{projectDetail?.project_price_end}</span>
             </div>
            </li>
             )}
            
            {projectDetail?.project_launch_date && projectDetail?.project_launch_date!='' && (
            <li className="item">
            <a href="#" className="box-icon w-52"><i className="icon ri-calendar-line"></i></a>
            <div className="content">
           <span className="label">Launch Date:</span>
            <span>{projectDetail?.project_launch_date}</span>
             </div>
            </li>
             )}

             
           {projectDetail?.project_possession_date && projectDetail?.project_possession_date!='' && (
            <li className="item">
            <a href="#" className="box-icon w-52"><i className="icon ri-building-4-line"></i></a>
            <div className="content">
           <span className="label">Possession Starts:</span>
            <span>{projectDetail?.project_possession_date}</span>
             </div>
            </li>
             )}

             
             
           {projectDetail?.project_availability && projectDetail?.project_availability!='' && (
            <li className="item">
            <a href="#" className="box-icon w-52"><i className="icon icon-bed"></i></a>
            <div className="content">
           <span className="label">Availability:</span>
            <span>{projectDetail?.project_availability}</span>
             </div>
            </li>
             )}

            </ul>
            </div>
}

export default ProjectOverview;