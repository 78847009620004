import { useParams } from "react-router-dom"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { useState, useEffect, useRef, useContext, useCallback } from "react"
import { ApiService } from "../../Components/Services/apiservices"
import ProjectBox from "../../Components/Element/project_box"
import Skeleton from 'react-loading-skeleton'
import InfiniteScroll from "react-infinite-scroll-component";


const PropertyList = () => {
    const didMountRef = useRef(true);
    const [projectList, setprojetctList] = useState([])
    const [pageno, setpageno] = useState(1)
    const [totalLength, setTotalLength] = useState("");
    const [currentPage, setcurrentPage] = useState('')
    const [imgUrl, setimgUrl] = useState('')
    const [isLoading, setisLoading] = useState(false);
    const [sortfield, setsort] = useState('')
    const [hasMore, sethasMore] = useState(false)
    
    const { slug } = useParams()
    useEffect(() => {
        if (didMountRef.current) {

            getProjectlist()

        }
        didMountRef.current = false;
    }, []);


    // const getProjectlist = () => {
    //     setisLoading(true)
    //     let pageNumber = Math.ceil(projectList.length / 12) + 1;
    //     ApiService.fetchData("allProjectList"  + "?page=" + pageNumber).then((res) => {
    //         if (res.status == "success") {
              
    //             setimgUrl(res.project_img_url)
           
    //             if (res.resProjectData.data.length > 0) {
    //                 const apires = res.resProjectData.data;
    //                 const mergeData = [...projectList, ...apires];
    //                 setprojetctList(mergeData);
    //                 setTotalLength(res.resProjectData.total)
    //                 setisLoading(false)
        
    //               } else {
    //                 setisLoading(false)
    //               }
    //         }
    //         else {
    //             setisLoading(false)
    //         }
    //     }).catch(() => {
    //         setisLoading(false)
    //     });
    // };

    const getProjectlist = (sort = null) => {
        setisLoading(true);
        
        let pageNumber = pageno; 
        if (sort) {
          setpageno(1);
          pageNumber = 1;
        } 
        const dataString = {
          project_sort: sort ? sort : sortfield ? sortfield : '',
        };
    
        ApiService.postData("allProjectList"  + "?page=" + pageNumber, dataString)
          .then((res) => {
            if (res.status === "success") {
              setcurrentPage(res.resProjectData.current_page);
              setimgUrl(res.project_img_url)
    
              if (pageNumber >= res.resProjectData.last_page) {
                sethasMore(false); // No more pages
              } else {
                sethasMore(true); // More pages available
                setpageno(pageNumber + 1); // Increment page number for next call
              }
    
              if (sort !== '' && sort !== null) {
                setprojetctList(res.resProjectData.data); // Replace list on sort
              } else if (res.resProjectData.data.length > 0) {
                const mergeData = [...projectList, ...res.resProjectData.data];
                setprojetctList(mergeData); // Append new data on infinite scroll
              }
              
              setTotalLength(res.resProjectData.total);
            }
    
            setisLoading(false);
          })
          .catch(() => {
            setisLoading(false);
          });
    };
    
    return (<>
        <Header></Header>
        <section className="wrapper-layout layout-2" data-wow-delay=".4s" data-wow-duration="2000ms">
        <div className="wrap-left">
                <div className="box-title-listing ">
                    <h5 className="mt-4">Project Listing</h5>
                    <div className="box-filter-tab">
                        <ul className="nav-tab-filter" role="tablist">
                            <li className="nav-tab-item" role="presentation">
                                <a href="javascript:void(0)" className="nav-link-item active" data-bs-toggle="tab" aria-selected="true" role="tab"><i className="icon icon-grid"></i></a>
                            </li>
                            <li className="nav-tab-item" role="presentation">
                                <a href="javascript:void(0)" className="nav-link-item" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab"><i className="icon icon-list"></i></a>
                            </li>
                        </ul>
                      
                        <div className="nice-select list-sort" tabindex="0"><span className="current">Sort by (Default)</span>
                            <ul className="list">
                            <li data-value="default" className="option selected">Sort by (Default)</li>
                <li data-value="new" className="option" onClick={() => {setsort('new') ; setpageno(1); sethasMore(false); getProjectlist('new') ;  }}>Newest</li>
                <li data-value="old" className="option" onClick={() => { setsort('old'); setpageno(1); sethasMore(false); getProjectlist('old'); }}>Oldest</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <InfiniteScroll
                    dataLength={projectList.length}
                    next={getProjectlist}
                    hasMore={hasMore}
                    // endMessage={<p>No more data to load.</p>}
                    style={{ overflow: "hidden !important" }}
                  >

                    {isLoading ? <>
                    <div className="row">
                        {[...Array(6)].map((__, index) => {
                            return (<>

                                <div className="col-xl-4 col-md-6" key={index}>
                                    <div className="homeya-box md" >
                                        <div className="archive-top">
                                            <div className="images-group">
                                                <div className="images-style" >
                                                    <Skeleton height={'250px'}></Skeleton>
                                                </div>
                                                <div className="top">
                                                    <ul className="d-flex gap-8 flex-column">
                                                        <li className=""><Skeleton width={'50px'}></Skeleton></li>

                                                    </ul>
                                                    <ul className="d-flex gap-4">
                                                        <li className="box-icon w-32" >
                                                            <a href="javascript:void(0)" data-bs-toggle="modal"> <Skeleton></Skeleton></a>

                                                        </li>
                                                        <li className="box-icon w-32">
                                                            <Skeleton></Skeleton>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                            <div className="content">
                                                <div className="text-1 text-capitalize">
                                                    <Skeleton width={'200px'}></Skeleton>
                                                </div>
                                                <p><Skeleton width={'250px'}></Skeleton></p>
                                                <div className="desc"><p><Skeleton  width={'300px'}></Skeleton></p> </div>


                                                <ul className="meta-list">
                                                    <li className="item">
                                                       
                                                        <span><Skeleton  width={'300px'}></Skeleton></span>
                                                    </li>
                                                </ul>


                                            </div>
                                        </div>
                                        <div className="archive-bottom d-flex justify-content-between align-items-center">


                                            <div className="d-flex align-items-center">
                                                <div className="h7 fw-7"><Skeleton width={'80px'}></Skeleton> </div>
                                                <div className="h7 fw-7 ms-2"><Skeleton width={'80px'}></Skeleton> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>)
                        })}
                    </div>
                </> : <>
                    {projectList && projectList.length > 0 ? <>
                        <div className="row">
                            {projectList.map((item, index) => {
                                return (<>

                                    <div className="col-xl-4 col-md-6" key={index}>
                                        <ProjectBox propertydetail={item} imgUrl={imgUrl}></ProjectBox>

                                    </div>

                                </>)
                            })}
                        </div>
                    </> : <>
                    <div className="row">

                        <div className="col-12">
                            <div  className="d-flex justify-content-center">
                            <img src='/images/emptystate.gif' style={{width:'500px'}}></img>
                            </div>
                            <h6 className="text-center">No Project Found</h6>
                        </div>
                    </div>
                    </>}
                </>}
                    </InfiniteScroll>
            </div>
        </section>
        <Footer></Footer>
    </>)
}

export default PropertyList