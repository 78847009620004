import { ApiService } from "../../Components/Services/apiservices"
import React from 'react';
import { useState, useEffect, useRef, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import PropertyBox from "../../Components/Element/property_box";
import ProjectBox from "../../Components/Element/project_box";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {isMobile} from 'react-device-detect';
import Skeleton from "react-loading-skeleton";

const SecondaryFeaturedProjects = () => {
    const [featuredprojectsdata, setfeaturedProjectsdata] = useState([])
    const [imgUrl, setimgUrl] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            getfeaturedprojects()
        }
        didMountRef.current = false
    }, [])

    const getfeaturedprojects = () => {
        setIsLoading(true)
        ApiService.fetchData('secondaryfeaturedprojects ').then((res) => {
            if (res.status == 'success') {
                setimgUrl(res.project_img_url)
                setfeaturedProjectsdata(res.resProjectData)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500)
            }
            else {
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }
    const slidercityRef = useRef(null);
    const handlePrev = useCallback(() => {
        if (!slidercityRef.current) return;
        slidercityRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!slidercityRef.current) return;
        slidercityRef.current.swiper.slideNext();
    }, [])
    return (<>
        {featuredprojectsdata && featuredprojectsdata.length > 0 ? <>

            <section className="flat-section flat-recommended pt-0  fadeInUpSmall" data-wow-delay=".4s" data-wow-duration="2000ms">
                <div className="container">
                    <div className="box-title style-2 text-center">
                        <div className="text-subtitle text-primary">Featured Projects</div>
                        <h4 className="mt-4">Discover Proopify's Finest Projects for Your Dream Home</h4>
                    </div>
                    <div className="row">
                    {isLoading ? <Swiper
                            spaceBetween={15}

                            ref={slidercityRef}
                            className="swiper tf-sw-location "
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                },
                                480: {
                                    slidesPerView: 1,
                                },
                                640: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                1024: {
                                    slidesPerView: 4,
                                },
                                1280: {
                                    slidesPerView: 4,
                                },
                            }}
                        >

                            {([...Array(6)]).map((item, index) => {
                                return (
                                    <SwiperSlide>
                                        <div className="homeya-box md">
                                            <div className="archive-top">
                                                <div className="images-group">
                                                    <div className="images-style">
                                                        <Skeleton height={180} />
                                                    </div>
                                                    <div className="top">
                                                        <ul className="d-flex gap-8 flex-column">
                                                            <Skeleton width={80} height={20} />
                                                        </ul>
                                                        <ul className="d-flex gap-4">
                                                            <Skeleton circle={true} height={32} width={32} />
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className="content">
                                                    <div className="text-1 text-capitalize" style={{ fontWeight: 'bold' }}>
                                                        <Skeleton width={150} />
                                                    </div>
                                                    <p><Skeleton width={100} /></p>
                                                    <div className="desc">
                                                      
                                                        <Skeleton width={200} />
                                                    </div>
                                                    <ul className="meta-list">
                                                        <li className="item">
                                                          
                                                            <Skeleton width={100} />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="archive-bottom d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <Skeleton width={100} height={25} />
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            }
                            )}
                        </Swiper>:
                        <Swiper
                            spaceBetween={15}
                           
                            ref={slidercityRef}
                            className="swiper tf-sw-location "
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                },
                                480: {
                                    slidesPerView: 1,
                                },
                                640: {
                                    slidesPerView: 2,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                1024: {
                                    slidesPerView: 4,
                                },
                                1280: {
                                    slidesPerView: 4,
                                },
                            }}
                        >
                            {featuredprojectsdata && featuredprojectsdata.map((item, index) => {
                                return (
                                    <SwiperSlide>
                                        <ProjectBox propertydetail={item} imgUrl={imgUrl}></ProjectBox>
                                    </SwiperSlide>
                                )
                            }
                            )}
                        </Swiper>}
                        {isMobile && !isLoading && featuredprojectsdata.length>2 && (
                            <div className="box-navigation">
                                <div className="navigation swiper-nav-next nav-next-location" onClick={handlePrev}><span className="icon icon-arr-l"></span></div>
                                <div className="navigation swiper-nav-prev nav-prev-location" onClick={handleNext}><span className="icon icon-arr-r"></span></div>
                            </div>
                        )}
                         {!isMobile && isLoading && featuredprojectsdata.length>4 && (
                            <div className="box-navigation">
                                <div className="navigation swiper-nav-next nav-next-location" onClick={handlePrev}><span className="icon icon-arr-l"></span></div>
                                <div className="navigation swiper-nav-prev nav-prev-location" onClick={handleNext}><span className="icon icon-arr-r"></span></div>
                            </div>
                        )}
                        
                    </div>
                    <div className="text-center">
                        <a href={`/projects`} className="tf-btn primary size-1">View All Projects</a>
                    </div>
                </div>
            </section>
        </> : ''}


    </>)
}

export default SecondaryFeaturedProjects