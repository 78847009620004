import { useNavigate } from "react-router-dom"
import constant from "../Services/constant"
import LoginModal from "./Modals/login_modal"
import { ApiService } from "../Services/apiservices"
import React, { useState } from "react"
import Loader from "react-js-loader";



const PropertyBox = ({ propertydetail, imgUrl }) => {
    const session_token = localStorage.getItem('USER_TOKEN')
    const navigate = useNavigate()
    const [spinnerloading, setspinnerloading] = useState('')

    const addtofav = (propertyId) => {
        setspinnerloading(propertyId.property_id)
        const dataString = {
            property_id: propertyId.property_id,
        };
        ApiService.postData("addtofav", dataString).then((res) => {
            if (res.data.status == "success") {
                setspinnerloading('')
                setTimeout(() => {
                    var element = document.getElementById("wishlisticon" + propertyId.property_id);
                    element.classList.remove("icon-heart-fill", "icon-heart");
                    if (res.data.notification === "ri-heart-fil") {
                        element.classList.add('icon-heart-fill');
                    } else {
                        element.classList.add('icon-heart');
                    }
                }, 100)
            } else {
                setspinnerloading('')
            }
        }).catch(() => {
            setspinnerloading('')
        });
    };
    return (<>
        <div className="homeya-box md style-2 mb-3">
            <div className="archive-top">
                <div className="images-group">
                <div className="images-style" onClick={() => { navigate(`/property/${propertydetail.property_slug}/${propertydetail.property_url_id}`) }}>
                    <img src={propertydetail?.property_image ? imgUrl + propertydetail?.property_image : constant.DEFAULT_IMAGE} alt={propertydetail?.property_title ? propertydetail?.property_title : propertydetail?.property_name} />
                </div>
                <div className="top">
                    <ul className="d-flex gap-8 flex-column">
                        {propertydetail?.property_featured == 1 && (<li className="flag-tag success"><li className="flag-tag success">Featured</li></li>)}
                        {propertydetail.property_for && (<li className="flag-tag style-1">{propertydetail.property_for == 1 ? "For Sale" : propertydetail.property_for == 2 ? "For Rent" : ''}</li>)}
                    </ul>
                    <ul className="d-flex gap-4">

                        {session_token && session_token !== null ? <>
                            {propertydetail.ufp_id > 0 ? (
                                <li className="box-icon w-32"
                                    onClick={(e) =>
                                        addtofav(propertydetail)
                                    }
                                >
                                    {spinnerloading == propertydetail.property_id ? <Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={20} /> :
                                        <span className="icon icon-heart-fill" id={
                                            "wishlisticon" +
                                            propertydetail.property_id
                                        }></span>}
                                </li>


                            ) : (<li className="box-icon w-32"

                                onClick={(e) =>
                                    addtofav(propertydetail)
                                }
                            >
                                {spinnerloading == propertydetail.property_id ? <Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={20} /> :
                                    <span className="icon icon-heart" id={
                                        "wishlisticon" +
                                        propertydetail.property_id
                                    }></span>}
                            </li>)}

                        </> : <> <li className="box-icon w-32" >
                            <a href="#modalLogin" data-bs-toggle="modal"> <span className="icon icon-heart"></span></a>

                        </li></>}

                    </ul>
                </div>
                {propertydetail.property_category_name ? <><div className="bottom">
                    <span className="flag-tag style-2">{propertydetail.property_category_name}</span>
                </div></> : ''}

                </div>
                <div className="content">
                    <div className="text-1 text-capitalize" style={{fontWeight:'bold'}}><a href={`/property/${propertydetail.property_slug}/${propertydetail.property_url_id}`} className="link">{propertydetail?.property_title ? propertydetail?.property_title : propertydetail?.property_name}</a></div>
                    {propertydetail.property_address ? <div className="desc">
                        <i className="fs-16 icon icon-mapPin"></i>
                        <p>{propertydetail.property_address}</p>
                    </div> : ''}

                    <ul className="meta-list">
                        {propertydetail?.property_no_bedroom ? <>
                            <li className="item">
                                <i className="icon icon-bed"></i>
                                <span>{propertydetail?.property_no_bedroom}</span>
                            </li>
                        </> : ''}
                        {propertydetail?.property_no_bathroom ? <>
                            <li className="item">
                                <i className="icon icon-bathtub"></i>
                                <span>{propertydetail?.property_no_bathroom}</span>
                            </li>
                        </> : ''}
                        {propertydetail?.property_super_built_up_area ? <>
                            <li className="item">
                                <i className="icon icon-ruler"></i>
                                <span>{propertydetail?.property_super_built_up_area} {propertydetail.property_super_built_up_unit}</span>
                            </li>
                        </> : ''}
                    </ul>
                </div>
            </div>
            <div className="archive-bottom d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center ">
                   {propertydetail.property_expected_price && <div className="h7 fw-7">₹{propertydetail.property_expected_price}</div>} 
                    {propertydetail.property_for == 2 ? <span className="">/month</span> : ''}
                </div>
            </div>
        </div>
        <LoginModal></LoginModal>
    </>)
}

export default PropertyBox