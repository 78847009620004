import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { useState, useEffect, useRef, useCallback } from "react"
import { useNavigate } from 'react-router-dom'
import { ApiService } from '../../Components/Services/apiservices'
import InfiniteScroll from "react-infinite-scroll-component";
import constant from '../../Components/Services/constant'
import Skeleton from "react-loading-skeleton"

function Services() {
    const [allServices, setallServices] = useState([])
    const [totalLength, setTotalLength] = useState("");
    const [imgUrl, setimgUrl] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const didMountRef = useRef(true)
    const [hasMore, sethasMore] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            getallServices()
        }
        didMountRef.current = false
    }, [])




    const getallServices = () => {
        setIsLoading(true)
        let pageNumber = Math.ceil(allServices.length / 12) + 1;
        ApiService.fetchData('servicesList' + "?page=" + pageNumber).then((res) => {
            if (res.status == 'success') {
                setimgUrl(res.serviceImagePath)
                if (pageNumber >= res.serviceData.last_page) {
                    sethasMore(false);
                } else {
                    sethasMore(true);
                }
                if (res.serviceData.data.length > 0) {
                    const apires = res.serviceData.data;
                    const mergeData = [...allServices, ...apires];
                    setallServices(mergeData);
                    setTotalLength(res.serviceData.total)
                    setIsLoading(false)
                }
            }
            else {
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }

    const onhandleClick=(e , item )=>{
        e.preventDefault()
        navigate(`/service/${item.service_slug}`)
    }

    return (
        <>
            <Header />
            <section className="flat-title-page">
                <div className="container">
                    <h2 className="text-center">Our Services</h2>

                    <ul className="breadcrumb">
                        <li><a href="/">Home</a></li>
                        <li>/ Services</li>
                    </ul>
                </div>
            </section>
            <InfiniteScroll
                dataLength={allServices.length}
                next={getallServices}
                hasMore={hasMore}
                // endMessage={<p className="text-center">No more data to load.</p>}
                style={{ overflow: "hidden !important" }}
            >

                {isLoading ? <>
                <div className='container'>
                <div className="row">
                        {[...Array(8)].map((__, index) => {
                            return (<>
                                <div className="box col-lg-3 col-md-6" key={index}>
                                    <div className="box-service style-2">
                                        <div className="icon-box">
                                            <Skeleton  height={200} width={200} />
                                        </div>
                                        <div className="content">
                                            <h6 className="title">
                                                <Skeleton width={100} />
                                            </h6>
                                            <p className="description">
                                                <Skeleton count={2} width={200} />
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </>)
                        })}
                    </div>


                </div>
                    
                </> : <>

                    <section className="flat-section-v6 flat-recommended flat-sidebar" data-wow-delay=".4s" data-wow-duration="2000ms">
                        {allServices && allServices.length > 0 ?
                            <div className='container'>
                                <div className="row">
                                    {allServices && allServices.map((item, index) => {
                                        return (<>
                                            <div className="box col-lg-4 col-md-6" key={index} onClick={(e)=>{onhandleClick(e, item)}}>
                                                <div className="box-service style-2">
                                                    <div className="icon-box">
                                                        <span className="icon " style={{width:'60px'}}><img src={item.service_icon ? imgUrl + item.service_icon : constant.DEFAULT_IMAGE} alt={item.service_name} style={{width:'100%'}}></img></span>
                                                    </div>
                                                    <div className="content">
                                                        <h6 className="title">{item.service_name}</h6>
                                                        {item.service_short_desc ? <p className="description">{item.service_short_desc}</p> : ''}
                                                        <a href={'javascript:void(0)'} onClick={(e) => { onhandleClick(e, item) }} className="tf-btn size-1">View More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </>)
                                    })}
                                </div>
                            </div>
                            :
                            <div className='container'>
                                <div className="row">

                                    <div className="col-12">
                                        <div className="d-flex justify-content-center">
                                            <img src='/images/emptystate.gif' style={{ width: '500px' }}></img>
                                        </div>
                                        <h6 className="text-center">No Services Found</h6>
                                    </div>
                                </div></div>}
                    </section>

                </>}
            </InfiniteScroll>
            <Footer />

        </>
    )
}

export default Services