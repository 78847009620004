import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { ApiService } from "../../Components/Services/apiservices";
import InfiniteScroll from "react-infinite-scroll-component";
import SweetAlert from 'react-bootstrap-sweetalert';


const Dashboard = () => {
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    const [deleteItem, setdeleteItem] = useState()
    const [solditem, setsolditem] = useState()
    const [pageno, setpageno] = useState(1)
    const [currentPage, setcurrentPage] = useState('')
    const [errorMessage, setErrorMessage] = useState()
    const [propertyFilter, setPropertyFilter] = useState({ property_name: "", property_status: '1', })
    const [myproperties, setmyproperties] = useState([])
    const [dashBoardCount, setdashBoardCount] = useState({ propertycount: 0, userleads: 0, userfavpropcount: 0, userfavprojcount: 0 })
    const [property_img_url, setproperty_img_url] = useState(null);
    const [hasMore, setHasmore] = useState(false)
    const [showsweetalert, setsweetalert] = useState(false)
    const [showsweetalertSold, setsweetalertSold] = useState(false)
    useEffect(() => {
        if (didMountRef.current) {

            getMyPropertyData()
        }
        didMountRef.current = false
    })

    const onTodoChange = (e) => {
        setErrorMessage('')

        const { name, value } = e.target;
        setPropertyFilter((prevState) => ({
            ...prevState,
            [name]: value,
        }));

    };
    const getMyPropertyData = (isNewSearch = false) => {
        let pageNumber = pageno;
        if (isNewSearch) {
            pageNumber = 1;
            setpageno(1)
            setmyproperties([]);
        }

        const dataString = {
            property_status: propertyFilter.property_status,
            property_name: propertyFilter.property_name,
        };

        ApiService.postData('userProperties?page=' + pageNumber, dataString).then((res) => {
            if (res.status === 'success') {
                setproperty_img_url(res.property_img_url);
                setdashBoardCount({
                    userfavprojcount: res.favProjectCount,
                    userfavpropcount: res.favPropertyCount,
                    userleads: res.leadsEnquiryCount,
                    propertycount: res.userPropertyCount,
                });
                if (res.data.last_page > pageNumber) {
                    setHasmore(true);
                    setpageno(pageNumber + 1);
                } else {
                    setHasmore(false);
                }
                if (isNewSearch) {
                    setmyproperties(res.data.data); 
                  }
            
                else if (res.data.data.length > 0) {
                    setcurrentPage(res.data.current_page);
                    const apires = res.data.data;
                    const mergeData = isNewSearch ? apires : [...myproperties, ...apires];
                    setmyproperties(mergeData);
                }
            } else if (res.status === 'session_expired') {
                localStorage.removeItem('USER_TOKEN');
                navigate('/');
            }
        }).catch((error) => {
           
        });
    };



    const onSearchClick = () => {

        getMyPropertyData(true)
    }
    const deleteProperty = (e, item) => {
        e.preventDefault()
        setsweetalert(true)
        setdeleteItem(item)
    }
    const soldProperty = (e, item) => {
        e.preventDefault()
        setsweetalertSold(true)
        setsolditem(item)
    }

    const handleCancel = () => {
        setsweetalert(false)
    }
    const handleConfirm = () => {
        const dataString = {
            property_unq_id: deleteItem.property_url_id
        }
        ApiService.postData('deleteProperty', dataString).then((res) => {
            if (res.status == 'success') {
                getMyPropertyData(true)
                setsweetalert(false)
            }
            else {
                setErrorMessage(res.message)
                setsweetalert(false)
            }
        })
    }

    const handleeditProperty = (e, property) => {
        e.preventDefault()
        navigate(`/post-property/${property.property_url_id}`)
    }


    const handleCancelSold = () => {
        setsweetalertSold(false)
    }
    const handleConfirmSold = () => {
        const dataString = {
            property_unq_id: solditem.property_url_id
        }
        ApiService.postData('soldProperty', dataString).then((res) => {
            if (res.status == 'success') {
                getMyPropertyData(true)
                setsweetalertSold(false)
            }
            else {
                setErrorMessage(res.message)
                setsweetalertSold(false)
            }
        })
    }
    const handlenavigation = (property) => {
        navigate(`/property/${property.property_slug}/${property.property_url_id}`, {
            state: { propertyData: property } // Passing the data in state
        });
    };

    return (<>

        <div id="wrapper">
            <div id="page" className="clearfix">
                <div className="layout-wrap">
                    <Header></Header>

                    <div className="main-content">
                        <div className="main-content-inner">

                            <div className="flat-counter-v2 tf-counter">
                                <div className="counter-box">
                                    <div className="box-icon w-68 round">
                                        <span className="icon icon-list-dashes"></span>
                                    </div>
                                    <div className="content-box">
                                        <div className="title-count">your Listing</div>
                                        <div className="d-flex align-items-end">
                                            <h6 className="number" data-speed="2000" data-to="17" data-inviewport="yes">{dashBoardCount.propertycount}</h6>
                                            {/* <span className="fw-7 text-variant-2">{dashBoardCount.propertycount}</span> */}
                                        </div>

                                    </div>
                                </div>
                                <div className="counter-box" onClick={() => { navigate('/leads') }}>
                                    <div className="box-icon w-68 round">
                                        <span className="icon icon-clock-countdown"></span>
                                    </div>
                                    <div className="content-box">
                                        <div className="title-count">Leads/Enquiries</div>
                                        <div className="d-flex align-items-end">
                                            <h6 className="number" data-speed="2000" data-to="0" data-inviewport="yes">{dashBoardCount.userleads}</h6>
                                        </div>

                                    </div>
                                </div>
                                <div className="counter-box" onClick={() => { navigate('/favourite-properties') }}>
                                    <div className="box-icon w-68 round">
                                        <span className="icon icon-bookmark"></span>
                                    </div>
                                    <div className="content-box">
                                        <div className="title-count">Favorite Properties</div>
                                        <div className="d-flex align-items-end">
                                            <h6 className="number" data-speed="2000" data-to="1" data-inviewport="yes">{dashBoardCount.userfavpropcount}</h6>
                                        </div>

                                    </div>
                                </div>
                                <div className="counter-box" onClick={() => { navigate('/favourite-projects') }}>
                                    <div className="box-icon w-68 round">
                                        <span className="icon icon-review"></span>
                                    </div>
                                    <div className="content-box">
                                        <div className="title-count">Favorite Projects</div>
                                        <div className="d-flex align-items-end">
                                            <h6 className="number" data-speed="2000" data-to="17" data-inviewport="yes">{dashBoardCount.userfavprojcount}</h6>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <InfiniteScroll
                                dataLength={myproperties.length}
                                next={getMyPropertyData}
                                hasMore={hasMore}
                                style={{ overflow: "hidden !important" }}
                            >
                                <div className="wrapper-content row">
                                    <div className="col-xl-12">
                                        <div className="widget-box-2 wd-listing">
                                            <h6 className="title">New Listing</h6>
                                            <div className="wd-filter">
                                                <div className="ip-group">
                                                    <input type="text" name="property_name" onChange={(e) => { onTodoChange(e) }} value={propertyFilter.property_name} placeholder="Search" />
                                                </div>
                                                <div className="ip-group">
                                                    <select
                                                        className="nice-select"
                                                        name="property_status" value={propertyFilter.property_status} onChange={(e) => { onTodoChange(e) }}
                                                    >
                                                        <option value="">Select status</option>

                                                        <option value='0' >Under Review</option>
                                                        <option value='1' >Published</option>
                                                        <option value='2' >Sold</option>
                                                    </select>

                                                </div>
                                                <div className="ip-group"><button className="tf-btn primary" onClick={(e) => {  onSearchClick(e) }}>Search</button></div>
                                            </div>
                                            <div className="d-flex gap-4"><span className="text-primary fw-7">{myproperties.length}</span><span className="text-variant-1">Results found</span></div>
                                            {errorMessage ? <div className="alert alert-danger" role="alert">
                                                {errorMessage}
                                            </div> : ''}

                                            {/* <InfiniteScroll
                                                    dataLength={myproperties.length}
                                                    next={getMyPropertyData}
                                                    hasMore={hasMore}
                                                    loader={<h4>Loading...</h4>}
                                                    style={{ overflow: "hidden !important" }}
                                                > */}
                                            <div className="wrap-table">
                                                <div className="table-responsive">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Title</th>
                                                                <th>Date Published</th>
                                                                <th>Status</th>
                                                                <th>Feature</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {myproperties.length > 0 && (
                                                                myproperties.map((property, key) => {
                                                                    let date1 = new Date(property.created_at).toDateString()

                                                                    return <tr className="file-delete" key={key}>
                                                                        <td>
                                                                            <div className="listing-box">
                                                                                <div className="images">
                                                                                    <img src={property_img_url + property.property_image} alt="images" />
                                                                                </div>
                                                                                <div className="content">
                                                                                    <div className="title" onClick={() => { handlenavigation(property) }}><a href={'javascript:void(0)'} className="link">{property.property_name}</a> </div>
                                                                                    <div className="text-date">{property.property_address}</div>
                                                                                    <div className="text-1 fw-7">{"₹" + property.property_expected_price}</div>
                                                                                    {/* `property/${property.property_slug}/${property.property_url_id}` */}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <span>{date1}</span>
                                                                        </td>
                                                                        <td>
                                                                            <div className="status-wrap">
                                                                                {(property.property_status == 1) ? <a href="javascript:void(0)" className="btn-status">Published</a> : property.property_status == 0 ? <a href="javascript:void(0)" className="btn-status">Under Review</a> : property.property_status == 2 ? <a href="javascript:void(0)" className="btn-status">Sold</a> : ''}

                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <span>{(property.property_featured == 1) ? "Yes" : "No"}</span>
                                                                        </td>
                                                                        <td>
                                                                            <ul className="list-action">
                                                                                {property.property_status!==2 && <li onClick={(e) => { handleeditProperty(e, property) }}><a className="item" ><i className="icon icon-edit"></i>Edit</a></li>}
                                                                                {property.property_status!==2 && property.property_status !== 0 && <li onClick={(e) => { soldProperty(e, property) }}><a className="item"><i className="icon icon-sold" ></i>Sold</a></li>}

                                                                                <li onClick={(e) => { deleteProperty(e, property) }}><a className="remove-file item"><i className="icon icon-trash"></i>Delete</a></li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            )}
                                                            {myproperties.length == 0 && <>
                                                                {propertyFilter.property_status == '1' ? <p className="text-center">No Published Property Found</p> : <p className="text-center">No Property Found</p>}
                                                            </>}


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {/* </InfiniteScroll> */}


                                        </div>

                                    </div>

                                </div>
                            </InfiniteScroll>
                        </div>

                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </div>
        {showsweetalert && (
            <SweetAlert
                title="Are you sure?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                confirmBtnText="Yes"
                cancelBtnText="No"
                showCancel
                focusCancelBtn
            >
                You Want To Delete This Property !
            </SweetAlert>
        )}
        {showsweetalertSold && (
            <SweetAlert
                title="Are you sure?"
                onConfirm={handleConfirmSold}
                onCancel={handleCancelSold}
                confirmBtnText="Yes"
                cancelBtnText="No"
                showCancel
                focusCancelBtn
            >
                You Want To Sold This Property !
            </SweetAlert>
        )}
    </>)
}

export default Dashboard