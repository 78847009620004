import React, { useState, useEffect, useRef } from 'react'
import { ApiService } from '../../Components/Services/apiservices';
import constant from '../../Components/Services/constant';

function HomeAboutUs() {
    const [videoContent , setVideoContent] = useState({})
    const [imgPath , setimgPath] = useState('')
    const [isLoading , setisLoading] = useState(false)
    const didMountRef = useRef(true)
    
  useEffect(() => {
    if (didMountRef.current) {
        getVideoData();
    }
    didMountRef.current = false;
  }, []);


  const getVideoData=()=>{
    ApiService.fetchData('youtubeVideo').then((res)=>{
        if(res.status=='success'){
            setVideoContent(res.data) 
            setisLoading(false) 
            setimgPath(res.image_path)
        }
        else{
            setisLoading(false)
        }
    }).catch(()=>{
        setisLoading(false)
    })
  }
    
  return (
    <>
        <section className="flat-section flat-banner-about">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <h3>Welcome To The <br/> Proopify</h3>
                        </div>
                        <div className="col-md-7 hover-btn-view">
                            <p className="body-2 text-variant-1">Welcome to Proopify, where we turn houses into homes and dreams into reality. At Proopify, we understand that a home is more than just a physical space; it's a place where memories are created, families grow, and life unfolds. </p>
                            <a href="/about-us" className="btn-view style-1"><span className="text">Learn More</span> <span className="icon icon-arrow-right2"></span> </a>

                        </div>

                    </div>
                    {videoContent?.hs_video_link &&  <div className="banner-video">
                        <img src={videoContent.hs_video_thumbnil?imgPath+videoContent.hs_video_thumbnil:constant.DEFAULT_IMAGE } alt="img-video"  style={{width:'100%' }}/>
                        <a href={videoContent?.hs_video_link} data-fancybox="gallery2" className="btn-video" style={{  top:'60%'}}> <span className="icon icon-play" style={{fontSize:'70px'}}></span></a>
                    </div>}
                   
                </div>
            </section>
    </>
  )
}

export default HomeAboutUs