import { ApiService } from "../../Components/Services/apiservices"
import React from 'react';
import { useState, useEffect, useRef, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import constant from "../../Components/Services/constant";
import { isMobile } from 'react-device-detect';
import Skeleton from "react-loading-skeleton";



const HomeFeaturedServices = () => {
    const [featuredservicesdata, setfeaturedservicesdata] = useState([])
    const [imgUrl, setimgUrl] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            getfeaturedservices()
        }
        didMountRef.current = false
    }, [])

    const getfeaturedservices = () => {
        setIsLoading(true)
        ApiService.fetchData('featuredServices ').then((res) => {
            if (res.status == 'success') {
                setimgUrl(res.serviceImagePath)
                setfeaturedservicesdata(res.serviceData)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500)
            }
            else {
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }
    const slidercityRef = useRef(null);
    const handlePrev = useCallback(() => {
        if (!slidercityRef.current) return;
        slidercityRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!slidercityRef.current) return;
        slidercityRef.current.swiper.slideNext();
    }, [])
    const onhandleClick = (e, item) => {
        e.preventDefault()
        navigate(`/service/${item.service_slug}`)
    }
    return (<>
        {isLoading ? <section className="flat-section flat-recommended pt-0  fadeInUpSmall" data-wow-delay=".4s" data-wow-duration="2000ms">
            <div className="container">
                <div className="box-title style-2 text-center">
                    <div className="text-subtitle text-primary"><Skeleton></Skeleton></div>
                    <h4 className="mt-4"><Skeleton></Skeleton></h4>
                </div>
                <div className="row">
                    <Swiper
                        spaceBetween={15}

                        ref={slidercityRef}
                        className="swiper tf-sw-location "
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            480: {
                                slidesPerView: 1,
                            },
                            640: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 3,
                            },
                            1280: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {([...Array(6)]).map((item, index) => {
                            return (
                                <SwiperSlide>
                                    <div className="box-service style-2">
                                        <div className="icon-box">
                                            <span className="icon" style={{ width: '60px' }}>
                                                <Skeleton circle={true} height={60} width={60} />
                                            </span>
                                        </div>
                                        <div className="content">
                                            <h6 className="title">
                                                <Skeleton width={120} />
                                            </h6>
                                            <p className="description">
                                                <Skeleton count={2} width={200} />
                                            </p>
                                            <Skeleton width={80} height={30} />
                                        </div>
                                    </div>

                                </SwiperSlide>
                            )
                        }
                        )}
                    </Swiper>
                </div>
                <div className="text-center mt-5">
                    <a href={`/services`} className="tf-btn primary size-1">View All services</a>
                </div>
            </div>
        </section> :
            featuredservicesdata && featuredservicesdata.length > 0 ? (

                <section className="flat-section flat-recommended pt-0  fadeInUpSmall" data-wow-delay=".4s" data-wow-duration="2000ms">
                    <div className="container">
                        <div className="box-title style-2 text-center">
                            <div className="text-subtitle text-primary">Featured Services</div>
                            <h4 className="mt-4">Explored the proopify featured services</h4>
                        </div>
                        <div className="row">
                            <Swiper
                                spaceBetween={15}

                                ref={slidercityRef}
                                className="swiper tf-sw-location "
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                    },
                                    480: {
                                        slidesPerView: 1,
                                    },
                                    640: {
                                        slidesPerView: 2,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                    },
                                    1280: {
                                        slidesPerView: 3,
                                    },
                                }}
                            >
                                {featuredservicesdata && featuredservicesdata.map((item, index) => {
                                    return (
                                        <SwiperSlide>

                                            <div className="box-service style-2" onClick={(e) => { onhandleClick(e, item) }}>
                                                <div className="icon-box" >
                                                    <span className="icon " style={{ width: '60px' }}><img src={item.service_icon ? imgUrl + item.service_icon : constant.DEFAULT_IMAGE} alt={item.service_name} style={{ width: '100%' }}></img></span>
                                                </div>
                                                <div className="content">
                                                    <h6 className="title">{item.service_name}</h6>
                                                    {item.service_short_desc ? <p className="description">{item.service_short_desc.split(' ').slice(0, 10).join(' ')}...</p> : ''}
                                                    <a href={'javascript:void(0)'} onClick={(e) => { onhandleClick(e, item) }} className="tf-btn size-1">View More</a>
                                                </div>
                                            </div>

                                        </SwiperSlide>
                                    )
                                }
                                )}
                            </Swiper>
                            {isMobile && featuredservicesdata && featuredservicesdata.length > 2 && (<div className="box-navigation">
                                <div className="navigation swiper-nav-next nav-next-location" onClick={handlePrev}><span className="icon icon-arr-l"></span></div>
                                <div className="navigation swiper-nav-prev nav-prev-location" onClick={handleNext}><span className="icon icon-arr-r"></span></div>
                            </div>)}
                            {!isMobile && featuredservicesdata && featuredservicesdata.length > 4 && (<div className="box-navigation">
                                <div className="navigation swiper-nav-next nav-next-location" onClick={handlePrev}><span className="icon icon-arr-l"></span></div>
                                <div className="navigation swiper-nav-prev nav-prev-location" onClick={handleNext}><span className="icon icon-arr-r"></span></div>
                            </div>)}

                        </div>
                        <div className="text-center mt-5">
                            <a href={`/services`} className="tf-btn primary size-1">View All services</a>
                        </div>
                    </div>
                </section>
            ) : null
        }

    </>)
}


export default HomeFeaturedServices