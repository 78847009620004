import { useEffect, useRef, useState, useContext } from 'react';
import constant from '../../Services/constant';



function PopUpModal({imgurl,popupbannerdata }) {
    
    return (

        <div className="modal fade" id="popupmodal">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                           
                            <div className="flat-account bg-surface text-center" style={{padding:'0px'}}>
                                <span className="close-modal icon-close2" style={{zIndex:9}} data-bs-dismiss="modal"></span>
                                <a href={popupbannerdata.popup_link}>
                                <div >
                                   <img src={popupbannerdata.popup_image? imgurl+popupbannerdata.popup_image:constant.DEFAULT_IMAGE}></img>
                                </div>
                                </a> 
                            </div>
                              
                </div>
            </div>
        </div>

    )
}

export default PopUpModal