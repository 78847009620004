import { ApiService } from "../../Components/Services/apiservices"
import React from 'react';
import { useState, useEffect, useRef, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import PropertyBox from "../../Components/Element/property_box";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { isMobile } from 'react-device-detect';
import Skeleton from "react-loading-skeleton";

const FeaturedProperties = () => {
    const [featuredpropertiesdata, setfeaturedPropertiesdata] = useState([])
    const [imgUrl, setimgUrl] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            getfeaturedproperty()
        }
        didMountRef.current = false
    }, [])

    const getfeaturedproperty = () => {
        setIsLoading(true)
        ApiService.fetchData('featuredpropertylist ').then((res) => {
            if (res.status == 'success') {
                setimgUrl(res.property_img_url)
                setfeaturedPropertiesdata(res.resPropertyData)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500)
            }
            else {
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }
    const sliderfeaturedRef = useRef(null);
    const handlePrev = useCallback(() => {
        if (!sliderfeaturedRef.current) return;
        sliderfeaturedRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderfeaturedRef.current) return;
        sliderfeaturedRef.current.swiper.slideNext();
    }, [])
    const handleClick = (route) => {
        localStorage.removeItem('type')
        window.location.href = route
    };

    return (<>
        {isLoading ? <section className="flat-section flat-recommended pt-0 wow fadeInUpSmall" data-wow-delay=".4s" data-wow-duration="2000ms">
            <div className="container">
                <div className="box-title style-2 text-center">
                    <div className="text-subtitle text-primary"><Skeleton></Skeleton></div>
                    <h4 className="mt-4"><Skeleton></Skeleton></h4>
                </div>
                <div>
                    <Swiper
                        spaceBetween={15}
                        slidesPerView={4}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            480: {
                                slidesPerView: 1,
                            },
                            640: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 1,
                            },
                            1024: {
                                slidesPerView: 4,
                            },
                            1280: {
                                slidesPerView: 4,
                            },
                        }}
                        modules={[Autoplay, Pagination, Navigation]}
                        ref={sliderfeaturedRef}
                    >
                        {([...Array(6)])?.map((item, index) => {
                            return (<>

                                <SwiperSlide key={index}>
                                    <div className="homeya-box md style-2">
                                        <div className="archive-top">
                                            <div className="images-group">
                                                <div className="images-style">
                                                    <Skeleton height={180} />
                                                </div>
                                                <div className="top">
                                                    <ul className="d-flex gap-8 flex-column">
                                                        <Skeleton width={80} height={20} />
                                                        <Skeleton width={80} height={20} />
                                                    </ul>
                                                    <ul className="d-flex gap-4">
                                                        <Skeleton circle={true} height={32} width={32} />
                                                    </ul>
                                                </div>
                                                <div className="bottom">
                                                    <Skeleton width={120} height={20} />
                                                </div>
                                            </div>

                                            <div className="content">
                                                <div className="text-1 text-capitalize" style={{ fontWeight: 'bold' }}>
                                                    <Skeleton width={150} />
                                                </div>
                                                <div className="desc">

                                                    <Skeleton width={200} />
                                                </div>
                                                <ul className="meta-list">
                                                    <li className="item">

                                                        <Skeleton width={50} />
                                                    </li>
                                                    <li className="item">

                                                        <Skeleton width={50} />
                                                    </li>
                                                    <li className="item">

                                                        <Skeleton width={100} />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="archive-bottom d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <Skeleton width={100} height={25} />
                                                <Skeleton width={50} height={20} />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </>)
                        })}
                    </Swiper>
                    {isMobile && !isLoading && featuredpropertiesdata.length > 2 && (
                        <div className="box-navigation">
                            <div className="navigation style-1 swiper-nav-next nav-next-category" onClick={handlePrev}><span className="icon icon-arr-l"></span></div>
                            <div className="navigation style-1 swiper-nav-prev nav-prev-category" onClick={handleNext} ><span className="icon icon-arr-r"></span></div>
                        </div>
                    )}
                    {!isMobile && !isLoading && featuredpropertiesdata.length > 4 && (
                        <div className="box-navigation">
                            <div className="navigation style-1 swiper-nav-next nav-next-category" onClick={handlePrev}><span className="icon icon-arr-l"></span></div>
                            <div className="navigation style-1 swiper-nav-prev nav-prev-category" onClick={handleNext} ><span className="icon icon-arr-r"></span></div>
                        </div>
                    )}

                </div>

                <div className="text-center">
                    <a href="/property" className="tf-btn primary size-1">View All Properties</a>
                </div>
            </div>
        </section> :
            featuredpropertiesdata && featuredpropertiesdata.length > 0 ? <>

                <section className="flat-section flat-recommended pt-0 wow fadeInUpSmall" data-wow-delay=".4s" data-wow-duration="2000ms">
                    <div className="container">
                        <div className="box-title style-2 text-center">
                            <div className="text-subtitle text-primary">Featured Properties</div>
                            <h4 className="mt-4">Discover Proopify's Finest Properties for Your Dream Home</h4>
                        </div>
                        <div>
                            <Swiper
                                spaceBetween={15}
                                slidesPerView={4}
                                autoplay={{
                                    delay: 2000,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                    },
                                    480: {
                                        slidesPerView: 1,
                                    },
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                    },
                                    1280: {
                                        slidesPerView: 4,
                                    },
                                }}
                                modules={[Autoplay, Pagination, Navigation]}
                                ref={sliderfeaturedRef}
                            >
                                {featuredpropertiesdata?.map((item, index) => {
                                    return (<>

                                        <SwiperSlide key={index}>
                                            <PropertyBox propertydetail={item} imgUrl={imgUrl}></PropertyBox>
                                        </SwiperSlide>
                                    </>)
                                })}
                            </Swiper>
                            {isMobile && featuredpropertiesdata.length > 2 && (
                                <div className="box-navigation">
                                    <div className="navigation style-1 swiper-nav-next nav-next-category" onClick={handlePrev}><span className="icon icon-arr-l"></span></div>
                                    <div className="navigation style-1 swiper-nav-prev nav-prev-category" onClick={handleNext} ><span className="icon icon-arr-r"></span></div>
                                </div>
                            )}
                            {!isMobile && featuredpropertiesdata.length > 4 && (
                                <div className="box-navigation">
                                    <div className="navigation style-1 swiper-nav-next nav-next-category" onClick={handlePrev}><span className="icon icon-arr-l"></span></div>
                                    <div className="navigation style-1 swiper-nav-prev nav-prev-category" onClick={handleNext} ><span className="icon icon-arr-r"></span></div>
                                </div>
                            )}

                        </div>

                        <div className="text-center">
                            <a href="/property" className="tf-btn primary size-1">View All Properties</a>
                        </div>
                    </div>
                </section>
            </> : ''}






    </>)
}

export default FeaturedProperties