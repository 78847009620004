import React, { useEffect, useRef, useState, useContext } from "react";
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import HomeTestimonials from '../Home/home_testimonials'
import HomeWhyChoose from '../Home/home_why_choose_us'
import FeaturedAgents from '../Home/home_featured_agents'
import { ApiService } from '../../Components/Services/apiservices'
import { validEmail, validNumber } from "../../Components/Element/Regex";
import HomeAboutUs from "../Home/home_about_us";


function AboutUs() {
    const didMountRef = useRef(true);
    const [pageData, setPageData] = useState({});
    const [pageContent, setPageContent] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [contactDetails, setContactDetails] = useState({
        contact_name: "",
        contact_email: "",
        contact_mobile: "",
        contact_subject: "",
        contact_message: "",
    });
    const [validationErrors, setValidationErrors] = useState({});
    useEffect(() => {
        if (didMountRef.current) {
            const getPageData = {
                slug: "contact-us",
            };
            ApiService.postData("page-content", getPageData).then((res) => {
                if (res.status === "success") {
                    setPageData(res.data);
                    setPageContent(res.data.page_content);
                }
            });
        }
        didMountRef.current = false;
    });

    const onTodoChange = (e) => {
        const { name, value } = e.target;
        setContactDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const resetContactForm = () => {
        setContactDetails({
            contact_name: "",
            contact_email: "",
            contact_mobile: "",
            contact_message: "",
            contact_subject:''
        });
        setValidationErrors({});
    };

   
    const validateForm = () => {
        let errors = {};
        const { contact_name, contact_email, contact_mobile, contact_message , contact_subject } = contactDetails;

        // Validate each field and set corresponding errors
        if (!contact_name) errors.contact_name = "Name is required";
        if (!contact_email) {
            errors.contact_email = "Email is required";
        } else if (!validEmail.test(contact_email)) {
            errors.contact_email = "Please enter a valid Email Id";
        }

        if (!contact_mobile) {
            errors.contact_mobile = "Phone number is required";
        }
        if (!contact_message) errors.contact_message = "Message is required";
        if (!contact_subject) errors.contact_subject = "Contact Subject is required";


        setValidationErrors(errors);

        return Object.keys(errors).length === 0;
    };
    const contactusProcess = (e) => {
        e.preventDefault();

        if (validateForm()) {
            ApiService.postData("contact-us-process", contactDetails)
                .then((res) => {
                    if (res.status === "success") {
                        setSuccessMessage(res.message);
                        resetContactForm();
                        setTimeout(() => {
                            setSuccessMessage("");
                        }, 2000);
                    } else {
                        setErrorMessage(res.message);
                        setTimeout(() => {
                            setErrorMessage("");
                        }, 2000);
                    }
                })
                .catch((error) => {
                    setErrorMessage("Failed to send message. Please try again later.");
                });
        }
    };

  return (
    <>
<Header/>
{/* <!-- Page Title --> */}
<section className="flat-title-page">
                <div className="container">
                    <h2 className="text-center">About Us</h2>

                    <ul className="breadcrumb">
                        <li><a href="/">Home</a></li>
                        <li>/ About Us</li>
                    </ul>

                </div>
            </section>
            {/* <!-- End Page Title --> */}

            {/* <!-- banner video --> */}
           <HomeAboutUs></HomeAboutUs>
            {/* <!-- end banner video --> */}
            {/* <!-- Service --> */}
          <HomeWhyChoose></HomeWhyChoose>
            {/* <!-- End Service -->
            <!-- Testimonial --> */}
            <HomeTestimonials></HomeTestimonials>
            {/* <!-- End Testimonial -->
            <!-- Contact --> */}
            <section className="flat-section-v3 flat-slider-contact">
                <div className="container">
                    <div className="row content-wrap">
                        <div className="col-lg-7">
                            <div className="content-left">
                                <div className="box-title">
                                    <div className="text-subtitle text-white">Contact Us</div>
                                    <h4 className="mt-4 fw-6 text-white">We're always eager to hear from you!</h4>
                                </div>
                                <p className="body-2 text-white">Whether you have questions about properties, want to schedule a viewing, or need more information, feel free to reach out. Our team is ready to assist you with any inquiries.</p>
                            </div>
                            
                        </div>
                        <div className="col-lg-5">
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                {successMessage && <div className="alert alert-success">{successMessage}</div>}



                                <form className="box-contact-v2" onSubmit={contactusProcess}>
                                <div className="box">
                                    <label for="name" className="label">Name:</label>
                                    <input type="text"  className={`form-control  ${validationErrors.contact_name ? "is-invalid" : ""}`}
                                                placeholder="Name"
                                                name="contact_name"
                                                id="contact_name"
                                                value={contactDetails.contact_name}
                                                onChange={onTodoChange}
                                                required/>
                                                 {validationErrors.contact_name && (
                                                <div className="invalid-feedback">{validationErrors.contact_name}</div>
                                            )}
                                </div>
                                <div className="box">
                                    <label for="email" className="label">Email:</label>
                                    <input type="text"   className={`form-control  ${validationErrors.contact_email ? "is-invalid" : ""}`}
                                                placeholder="Email"
                                                name="contact_email"
                                                id="contact_email"
                                                value={contactDetails.contact_email}
                                                onChange={onTodoChange}
                                                required/>
                                                 {validationErrors.contact_email && (
                                                <div className="invalid-feedback">{validationErrors.contact_email}</div>
                                            )}
                                </div>
                                <div className="box">
                                    <label for="phone" className="label">Phone Numbers:</label>
                                    <input type="text"   className={`form-control  ${validationErrors.contact_email ? "is-invalid" : ""}`}
                                                placeholder="ex 012345678"
                                                name="contact_mobile"
                                                id="contact_mobile"
                                                value={contactDetails.contact_mobile}
                                                onChange={onTodoChange}
                                                required/>
                                                 {validationErrors.contact_mobile && (
                                                <div className="invalid-feedback">{validationErrors.contact_mobile}</div>
                                            )}
                                </div>
                                <div className="box">
                                    <label for="subject" className="label">Subject:</label>
                                    <input type="text"   className={`form-control  ${validationErrors.contact_subject ? "is-invalid" : ""}`}
                                                placeholder="subject"
                                                name="contact_subject"
                                                id="contact_subject"
                                                value={contactDetails.contact_subject}
                                                onChange={onTodoChange}
                                                required/>
                                                 {validationErrors.contact_subject && (
                                                <div className="invalid-feedback">{validationErrors.contact_subject}</div>
                                            )}
                                </div>
                                <div className="box">
                                    <label for="message" className="label">Message:</label>
                                    <textarea name="contact_message"
                                            className={`form-control ${validationErrors.contact_message ? "is-invalid" : ""}`}
                                            cols="30"
                                            rows="10"
                                            placeholder="Message"
                                            value={contactDetails.contact_message}
                                            onChange={onTodoChange}
                                            required
                                        ></textarea>
                                    
                                              {validationErrors.contact_message && (
                                            <div className="invalid-feedback">{validationErrors.contact_message}</div>
                                        )}
                                </div>
                                <div className="box">
                                    <button className="tf-btn primary"  onClick={contactusProcess}>Contact US</button>
                                </div>
                            </form>
                              
                        </div>
                    </div>

                </div>
                <div className="overlay"></div>

            </section>
            {/* <!-- End Contact -->
            <!-- Agents --> */}
          <FeaturedAgents></FeaturedAgents>
            {/* <!-- End Agents -->
            <!-- banner --> */}
            {/* <section className="flat-section pt-0 flat-banner">
                <div className="container">
                    <div className="wrap-banner bg-surface">
                        <div className="box-left">
                            <div className="box-title">
                                <div className="text-subtitle text-primary">Become Partners</div>
                                <h4 className="mt-4">List your Properties on Proopify, join Us Now!</h4>
                            </div>
                            <a href="#" className="tf-btn primary size-1">Become A Hosting</a>
                        </div>
                        <div className="box-right">
                            <img src="images/banner/banner.png" alt="image"/>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- end banner --> */}
<Footer/>
    </>

  )
}

export default AboutUs