import React, { useEffect, useRef, useState, useCallback } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { validEmail, validNumber } from "../../../Components/Element/Regex";
import { useParams } from "react-router-dom";
import { ApiService } from "../../../Components/Services/apiservices";

import ProjectSlider from "./ProjectSlider";
import ProjectAbout from "./ProjectAbout";
import ProjectLocation from "./ProjectLocation";
import ProjectFloorplan from "./ProjectFloorplan";
import ProjectSpecifications from "./ProjectSpecifications";
import ProjectAmenities from "./ProjectAmenities";
import ProjectEBrochure from "./ProjectEBrochure";
import ProjectContact from "./ProjectContact";
import ProjectOverview from "./ProjectOverview";
import ProjectNearBy from "./ProjectNearBy";
import ProjectUnits from "./ProjectUnits";
import ProjectVideo from "./ProjectVideo";
import ProjectSlider2 from "./ProjectSlider2";


const ProjectFooter = ({projectDetail, browserSectionData, mobileSectionData, gallaryImages, floorplanimgUrl, amenityimgurl, brochureurl, projectBaseUrl}) => {

    const { slug } = useParams()
    const [errorMessage, setErrorMessage] = useState("");    
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const [enquiryUserdetail, setenquiryUserdetail] = useState({
        enquired_user_name: "",
        enquired_user_email: "",
        enquired_user_mobile: "",
        enquired_message: "",
    })

    const convertStyleStringToObject = (styleString) => {
        if (!styleString) return {}; // Return an empty object if styleString is null or undefined
        return styleString.split(';').reduce((styleObject, styleProperty) => {
          if (styleProperty) {
            const [property, value] = styleProperty.split(':');
            if (property && value) {
              const camelCasedProperty = property.trim().replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
              styleObject[camelCasedProperty] = value.trim();
            }
          }
          return styleObject;
        }, {});
      };

    const onInputChange = (e) => {
        setErrorMessage("");
        setSuccessMessage("");
        const { name, value } = e.target;
      
        setenquiryUserdetail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value !== "") {
            e.target.style.border = "";
        }
    };

    const submitEnquire = (e) => {
        e.preventDefault()
        let counter = 0;
        const myElements = document.getElementsByClassName("headerformrequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === "") {

                myElements[i].style.border = "1px solid red";
                counter++;
            } else {
                myElements[i].style.border = "";
            }
        }
       
        if (counter == 0) {
            if (!validEmail.test(enquiryUserdetail.enquired_user_email)) {
                setErrorMessage("Please Enter Valid Email");
                return false;
            }
            if (!validNumber.test(enquiryUserdetail.enquired_user_mobile)) {
                setErrorMessage("Please Enter Valid Number");
                return false;
            }
            setisLoading(true)
            const dataString = {
                enquiry_name: enquiryUserdetail.enquired_user_name,
                enquiry_email: enquiryUserdetail.enquired_user_email,
                enquiry_mobile: enquiryUserdetail.enquired_user_mobile,
                enquiry_project_id: slug,
                enquired_message: "E-Brochure Download"
            };
   
            ApiService.postData("projectenquiry", dataString).then((res) => {
                if (res.status == "success") {
                    setSuccessMessage("Thank you! Brochure is being downloading!");
                    document.getElementById("downlaodheadernow").click();                    
                    document.getElementById("closeheaderModel").click();
                    setenquiryUserdetail({enquired_user_name: "",
                        enquired_user_email: "",})
                    setTimeout(() => {
                        setSuccessMessage("")
                    }, 1000);
                    setisLoading(false)
                } else {
                    setErrorMessage(res.message);
                    setisLoading(false)
                }
            }).catch(() => {
                setisLoading(false)
            });
        }
    };



    return <>
    <footer className="project_footer">
        <BrowserView>
        {browserSectionData.length > 0 && (
            browserSectionData.map((valueSection, indexSection) => {
            const inlineStyle = convertStyleStringToObject(valueSection.home_inline_style);
            if (valueSection.home_layout_image) {
                inlineStyle.background = `url(${valueSection.home_layout_image}) ${valueSection.home_layout_background_repeat}`;
            }
            if (valueSection.home_layout_image_url) {
                inlineStyle.background = `url(${valueSection.home_layout_image_url}) ${valueSection.home_layout_background_repeat}`;
            }

            return (
                <section id={(valueSection.home_extra_id != 'null') ? valueSection.home_extra_id : ""} className={`${valueSection.home_top_spaced} ${valueSection.home_bottom_spaced} ${valueSection.home_extra_class}`} key={valueSection.home_id} style={inlineStyle}>
                <div className={`${valueSection.home_layout} ${valueSection.home_grid === 'g-0' ? 'px-0' : ''}`}>
                    <div className={`row ${valueSection.home_grid} ${valueSection.home_vertical_align} ${valueSection.home_horizontal_align}`}>
                    {valueSection.has_many_home_inner.length > 0 &&
                        valueSection.has_many_home_inner.map((valueRowInner, indexRowInner, valueSection) => (
                        <><div className={"col-lg-" + valueRowInner.home_inn_structure_type + " " + valueRowInner.home_inn_text_align} key={indexRowInner}>
                            {valueRowInner.has_many_home_details.length > 0 &&
                            valueRowInner.has_many_home_details.map((valueRow, indexRow) => {
                                let dataObj;
                                if (valueRow.home_type === 1) { //Slider
                                if (valueRow.home_slider_layout == 1) {
                                    return (
                                    <ProjectSlider
                                        key={indexRow}
                                        projectDetail={projectDetail}
                                        projectBaseUrl={projectBaseUrl}
                                        gallaryImages={gallaryImages}
                                    />
                                    );
                                } else if (valueRow.home_slider_layout == 2) {
                                    return (
                                    <ProjectSlider2
                                        key={indexRow}
                                        projectDetail={projectDetail}
                                        projectBaseUrl={projectBaseUrl}
                                        gallaryImages={gallaryImages}
                                    />
                                    );
                                }

                                } else if (valueRow.home_type === 2) { //About
                                return (
                                    <ProjectAbout
                                    projectDetail={projectDetail}
                                    />
                                );
                                } else if (valueRow.home_type === 3) { //Location
                                return (
                                    <ProjectLocation
                                    projectDetail={projectDetail}
                                    />
                                );
                                } else if (valueRow.home_type === 4) { //Floor Plan
                                return (
                                    <ProjectFloorplan
                                    projectDetail={projectDetail}
                                    floorplanimgUrl={floorplanimgUrl}

                                    />
                                );
                                } else if (valueRow.home_type === 5) { // Amenities
                                return (
                                    <ProjectAmenities
                                    projectDetail={projectDetail}
                                    amenityimgurl={amenityimgurl}
                                    />
                                );
                                } else if (valueRow.home_type === 6) { //E-Brochure
                                return (
                                    <ProjectEBrochure
                                    projectDetail={projectDetail}
                                    brochureurl={brochureurl}
                                    />
                                );
                                } else if (valueRow.home_type === 7) {  //Contact

                                return (
                                    <ProjectContact
                                    projectDetail={projectDetail}
                                    />
                                );
                                } else if (valueRow.home_type === 8) {  //Image
                                const imageInlineStyle = convertStyleStringToObject(valueRow.home_custom_size);
                                return (
                                    <a href={valueRow.home_image_link} target={valueRow.home_target}>
                                    <img src={valueRow.home_image} key={indexRow} alt={valueRow.home_caption_text} className={`${valueRow.home_size}`} style={imageInlineStyle} />
                                    </a>
                                );

                                } else if (valueRow.home_type === 9) {  //Text
                                return (
                                    <div key={indexRow}>
                                    {valueRow.home_text_title && (<p>{valueRow.home_text_title}</p>)}
                                    <div dangerouslySetInnerHTML={{ __html: valueRow.home_text_description }}></div>
                                    </div>
                                );
                                } else if (valueRow.home_type === 10) {  //Title

                                const teamInlineStyle = convertStyleStringToObject(valueRow.home_brij_inline_style);
                                return (
                                    <div className={`section-title ${valueRow.home_brij_align} ${valueRow.home_brij_extra_class}`} style={teamInlineStyle} key={indexRow}>
                                    {valueRow.home_brij_heading && React.createElement(
                                        valueRow.home_brij_heading_size || 'h1',
                                        null,
                                        valueRow.home_brij_heading
                                    )}
                                    {valueRow.home_brij_subheading && (<p>{valueRow.home_brij_subheading}</p>)}
                                    </div>
                                );
                                } else if (valueRow.home_type === 11) {  //Overview

                                return (
                                    <ProjectOverview
                                    projectDetail={projectDetail}
                                    />
                                );
                                } else if (valueRow.home_type === 12) {  //Units

                                return (
                                    <ProjectUnits
                                    projectDetail={projectDetail}
                                    projectBaseUrl={projectBaseUrl}
                                    />
                                );
                                } else if (valueRow.home_type === 13) {  //Video

                                return (
                                    <ProjectVideo
                                    projectDetail={projectDetail}
                                    />
                                );
                                } else if (valueRow.home_type === 14) {  //NearBy

                                return (
                                    <ProjectNearBy
                                    projectDetail={projectDetail}
                                    />
                                );
                                } else if (valueRow.home_type === 15) {  //Specification

                                return (
                                    <ProjectSpecifications
                                    projectDetail={projectDetail}
                                    amenityimgurl={amenityimgurl}
                                    />
                                );
                                }
                            })}
                        </div></>
                        ))}
                    </div>
                </div>
                </section>
            );
            })
        )}
        </BrowserView>
        <MobileView>
        {mobileSectionData.length > 0 && (
            mobileSectionData.map((valueSection, indexSection) => {
            const inlineStyle = convertStyleStringToObject(valueSection.home_inline_style);
            if (valueSection.home_layout_image) {
                inlineStyle.background = `url(${valueSection.home_layout_image}) ${valueSection.home_layout_background_repeat}`;
            }
            return (
                <section id={(valueSection.home_extra_id != 'null') ? valueSection.home_extra_id : ""} className={`${valueSection.home_top_spaced} ${valueSection.home_bottom_spaced} ${valueSection.home_extra_class}`} key={valueSection.home_id} style={inlineStyle}>
                <div className={`${valueSection.home_layout} ${valueSection.home_grid === 'g-0' ? 'px-0' : ''}`}>
                    <div className={`row ${valueSection.home_grid} ${valueSection.home_vertical_align} ${valueSection.home_horizontal_align}`}>
                    {valueSection.has_many_home_inner.length > 0 &&
                        valueSection.has_many_home_inner.map((valueRowInner, indexRowInner) => (
                        <><div className={"col-lg-" + valueRowInner.home_inn_structure_type + " " + valueRowInner.home_inn_text_align} key={indexRowInner}>
                            {valueRowInner.has_many_home_details.length > 0 &&
                            valueRowInner.has_many_home_details.map((valueRow, indexRow) => {
                                let dataObj;
                                if (valueRow.home_type === 1) { //Slider
                                if (valueRow.home_slider_layout == 1) {
                                    return (
                                    <ProjectSlider
                                        key={indexRow}
                                        projectDetail={projectDetail}
                                        projectBaseUrl={projectBaseUrl}
                                        gallaryImages={gallaryImages}
                                    />
                                    );
                                } else if (valueRow.home_slider_layout == 2) {
                                    return (
                                    <ProjectSlider2
                                        key={indexRow}
                                        projectDetail={projectDetail}
                                        projectBaseUrl={projectBaseUrl}
                                        gallaryImages={gallaryImages}
                                    />
                                    );
                                }
                                /* return (
                                    <ProjectSlider
                                    key={indexRow} 
                                    projectDetail={projectDetail}
                                    projectBaseUrl={projectBaseUrl}
                                    gallaryImages={gallaryImages}
                                    />
                                ); */
                                } else if (valueRow.home_type === 2) { //About
                                return (
                                    <ProjectAbout
                                    projectDetail={projectDetail}
                                    />
                                );
                                } else if (valueRow.home_type === 3) { //Location
                                return (
                                    <ProjectLocation
                                    projectDetail={projectDetail}
                                    />
                                );
                                } else if (valueRow.home_type === 4) { //Floor Plan
                                return (
                                    <ProjectFloorplan
                                    projectDetail={projectDetail}
                                    floorplanimgUrl={floorplanimgUrl}

                                    />
                                );
                                } else if (valueRow.home_type === 5) { //Amenities
                                return (
                                    <ProjectAmenities
                                    projectDetail={projectDetail}
                                    amenityimgurl={amenityimgurl}
                                    />
                                );
                                } else if (valueRow.home_type === 6) { //E-Brochure
                                return (
                                    <ProjectEBrochure
                                    projectDetail={projectDetail}
                                    brochureurl={brochureurl}
                                    />
                                );
                                } else if (valueRow.home_type === 7) {  //Contact
                                return (
                                    <ProjectContact
                                    projectDetail={projectDetail}
                                    />
                                );
                                } else if (valueRow.home_type === 8) {  //Image
                                const imageInlineStyle = convertStyleStringToObject(valueRow.home_custom_size);
                                return (
                                    <a href={valueRow.home_image_link} target={valueRow.home_target}>
                                    <img src={valueRow.home_image} key={indexRow} alt={valueRow.home_caption_text} className={`${valueRow.home_size}`} style={imageInlineStyle} />
                                    </a>
                                );

                                } else if (valueRow.home_type === 9) {  //Text
                                return (
                                    <div key={indexRow}>
                                    {valueRow.home_text_title && (<p>{valueRow.home_text_title}</p>)}
                                    <div dangerouslySetInnerHTML={{ __html: valueRow.home_text_description }}></div>
                                    </div>
                                );
                                } else if (valueRow.home_type === 10) {  //Title

                                const teamInlineStyle = convertStyleStringToObject(valueRow.home_brij_inline_style);
                                return (
                                    <div className={`section-title ${valueRow.home_brij_align} ${valueRow.home_brij_extra_class}`} style={teamInlineStyle} key={indexRow}>
                                    {valueRow.home_brij_heading && React.createElement(
                                        valueRow.home_brij_heading_size || 'h1',
                                        null,
                                        valueRow.home_brij_heading
                                    )}
                                    {valueRow.home_brij_subheading && (<p>{valueRow.home_brij_subheading}</p>)}
                                    </div>
                                );
                                } else if (valueRow.home_type === 11) {  //Overview

                                return (
                                    <ProjectOverview
                                    projectDetail={projectDetail}
                                    />
                                );
                                } else if (valueRow.home_type === 12) {  //Units

                                return (
                                    <ProjectUnits
                                    projectDetail={projectDetail}
                                    projectBaseUrl={projectBaseUrl}
                                    />
                                );
                                } else if (valueRow.home_type === 13) {  //Video

                                return (
                                    <ProjectVideo
                                    projectDetail={projectDetail}
                                    />
                                );
                                } else if (valueRow.home_type === 14) {  //NearBy

                                return (
                                    <ProjectNearBy
                                    projectDetail={projectDetail}
                                    />
                                );
                                } else if (valueRow.home_type === 15) {  //Specification

                                return (
                                    <ProjectSpecifications
                                    projectDetail={projectDetail}
                                    amenityimgurl={amenityimgurl}
                                    />
                                );
                                }
                            })}
                        </div></>
                        ))}
                    </div>
                </div>
                </section>
            );
            })
        )}

        </MobileView>
    </footer>

    <div className="modal fade" id="modalheaderContact" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="row">
                        <div className="col-lg-12">
                            
                            <a href={brochureurl + projectDetail?.project_e_brochure} download  target="_blank" id="downlaodheadernow" className="d-none">Download</a>
                            <form action="#" className="box-contact-v2 flat-account">
                            <span className="close-modal icon-close2" id="closeheaderModel" data-bs-dismiss="modal"></span>
                                {errorMessage && (
                                    <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div>
                                )}
                                {successMessage && (
                                    <div className="alert alert-success" role="alert">
                                        {successMessage}
                                    </div>
                                )}
                                <div className="box">
                                    <label htmlFor="name" className="label">Name:</label>
                                    <input type="text" className="form-control headerformrequired" value={enquiryUserdetail?.enquired_user_name} name="enquired_user_name" onChange={(e) => { onInputChange(e) }} />
                                </div>
                                <div className="box">
                                    <label htmlFor="mobile" className="label">Mobile Number:</label>
                                    <input type="text" className="form-control headerformrequired" name="enquired_user_mobile" value={enquiryUserdetail?.enquired_user_mobile} onChange={(e) => { onInputChange(e) }}  />
                                </div>
                                <div className="box">
                                    <label htmlFor="email" className="label">Email:</label>
                                    <input type="email" className="form-control headerformrequired" name="enquired_user_email" value={enquiryUserdetail?.enquired_user_email} onChange={(e) => { onInputChange(e) }} />
                                </div>
                                <div className="box">
                                    <button className="tf-btn primary" onClick={(e) => { submitEnquire(e) }}>
                                    {isLoading ? (
                                                        <img src="/images/loder01.gif" width="60px" height="11px" />
                                                    ) : (
                                                        "Submit"
                                                    )}
                                        
                                        </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </>
}

export default ProjectFooter;