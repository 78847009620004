
const ProjectSlider2 = ({ projectDetail, projectBaseUrl, gallaryImages }) => {
    return (
        <section className="flat-gallery-single">
            {projectDetail.project_image !== '' && (
                <div className="item1 box-img">
                    <img src={projectBaseUrl + projectDetail.project_image} alt="img-gallery" />
                    <div className="box-btn">
                        {gallaryImages && gallaryImages.length > 0 && (
                            <a href={gallaryImages[0].pg_image} data-fancybox="gallery" className="tf-btn primary">
                                View All Photos
                            </a>
                        )}
                    </div>
                </div>
            )}
            {gallaryImages.slice(0, 4).map((value, index) => (
                <a
                    key={index}
                    href={value.pg_image}
                    className={`item${index + 2} box-img`}
                    data-fancybox="gallery"
                >
                    <img src={value.pg_image} alt="img-gallery" />
                </a>
            ))}
            {gallaryImages.slice(4).map((value, index) => (
                <a
                    key={`hidden-${index}`}
                    href={value.pg_image}
                    data-fancybox="gallery"
                    style={{ display: 'none' }}
                >
                </a>
            ))}
        </section>
    );
};

export default ProjectSlider2;
